import React, {useState} from "react";
import {Prompt} from "../../interface/IPrompts";
import {useDeletePromptItemMutation, useDeletePromptMutation} from "../../hook/HPrompt";
import {
    Box,
    IconButton,
    Text,
    Wrap,
    WrapItem,
    Badge,
    useColorModeValue,
    Tooltip,
    Collapse,
    VStack,
    Flex,
    Button,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    HStack, Input,
} from "@chakra-ui/react";
import {ChevronDownIcon, ChevronUpIcon, CloseIcon, DeleteIcon, AddIcon} from "@chakra-ui/icons";
import useCustomToast from "../../hook/HToast";
import {useAddPromptToSequence, useAddToolToSequence, usePromptToSequence} from "../../hook/HSequence";
import {useThemeColors} from "../../lib/Color";

interface PromptCardProps {
    prompt: Prompt;
    onSettingsClick: () => void;
    sequence_id: string;  // Changed from sequenceId to sequence_id
}

export const ProjectPromptCard: React.FC<PromptCardProps> = ({prompt, onSettingsClick, sequence_id}: any) => {
    const [isOpen, setIsOpen] = useState(false);
    const deletePromptMutation = useDeletePromptMutation();
    const deletePromptItemMutation = useDeletePromptItemMutation();
    const {showToast} = useCustomToast();
    const addPromptToSequenceMutation = useAddPromptToSequence();
    const promptToSequenceMutation = usePromptToSequence();
    const [newPrompt, setNewPrompt] = useState('');

    const handleDeletePromptItem = (promptIndex: number) => {
        deletePromptItemMutation.mutate({categoryId: prompt.id, promptIndex, sequenceId: sequence_id});
    };

    const handleDeleteAllPrompts = () => {
        deletePromptMutation.mutate(prompt.id);
    };
    const {bgColor, cardBgColor, textColor, borderColor, accentColor} = useThemeColors();

    // const bgColor = useColorModeValue("white", "gray.700");
    // const borderColor = useColorModeValue("gray.200", "gray.600");


    const handleAddToStorage = () => {
        addPromptToSequenceMutation.mutate(
            {sequence_id, prompt_id: prompt.id},
            {
                onSuccess: () => {
                    showToast("Project added to sequence storage", "success");

                },
                onError: (error) => {
                    showToast("Failed to add project to sequence", "error");
                }
            }
        );
    };

    const handleAddPrompt = () => {
        if (!newPrompt.trim()) return;

        // 입력된 newPrompt를 쉼표로 구분하여 배열로 변환
        const newWords = newPrompt.split(',').map(word => word.trim()).filter(word => word !== '');

        let updatedContent: string[];
        if (Array.isArray(prompt.content)) {
            updatedContent = [...prompt.content, ...newWords];
        } else if (typeof prompt.content === 'string') {
            updatedContent = prompt.content
                ? [...prompt.content.split(',').map((item : any) => item.trim()), ...newWords]
                : newWords;
        } else {
            updatedContent = newWords;
        }

        promptToSequenceMutation.mutate(
            {
                sequence_id: sequence_id,
                prompt_id: prompt.id,
                prompt_content: updatedContent
            },
            {
                onSuccess: () => {
                    setNewPrompt('');
                    showToast(newWords.length > 1
                        ? "Words added to prompt successfully"
                        : "Word added to prompt successfully", "success");
                },
                onError: () => {
                    showToast("Failed to add word(s) to prompt", "error");
                }
            }
        );
    };
    return (
        <Box
            borderWidth={1}
            borderRadius="lg"
            p={4}
            position="relative"
            bg={bgColor}
            borderColor={borderColor}
            boxShadow="md"
        >
            <Flex justifyContent="space-between" alignItems="center" mb={2}>
                <Button
                    variant="ghost"
                    rightIcon={isOpen ? <ChevronUpIcon/> : <ChevronDownIcon/>}
                    onClick={() => setIsOpen(!isOpen)}
                    fontWeight="bold"
                >
                    {prompt.category?.customName || "Unnamed Category"}
                </Button>
                <Menu>
                    <MenuButton
                        as={IconButton}
                        aria-label="Options"
                        icon={<ChevronDownIcon/>}
                        variant="outline"
                    />
                    <MenuList>
                        <MenuItem icon={<DeleteIcon/>} onClick={handleDeleteAllPrompts}>
                            Delete all prompts
                        </MenuItem>
                        <MenuItem onClick={handleAddToStorage}>Input Storage</MenuItem>

                    </MenuList>
                </Menu>
            </Flex>
            <Collapse in={isOpen} animateOpacity>
                <VStack align="stretch" spacing={2} mt={4}>
                    <Wrap spacing={2} mt={2}>
                        {prompt.content.map((item: string, index: number) => (
                            <WrapItem key={index}>
                                <Badge
                                    borderRadius="full"
                                    px={2}
                                    py={1}
                                    colorScheme="blue"
                                    display="flex"
                                    alignItems="center"
                                >
                                    <Text mr={2}>{item}</Text>
                                    <Tooltip label="Delete prompt" placement="top">
                                        <IconButton
                                            aria-label="Delete prompt item"
                                            icon={<CloseIcon/>}
                                            size="xs"
                                            onClick={() => handleDeletePromptItem(index)}
                                            variant="ghost"
                                            colorScheme="gray"
                                            ml={1}
                                        />
                                    </Tooltip>
                                </Badge>
                            </WrapItem>
                        ))}
                    </Wrap>
                    <HStack mt={4}>
                        <Input
                            value={newPrompt}
                            onChange={(e: any) => setNewPrompt(e.target.value)}
                            placeholder="Add a new prompt..."
                        />
                        <IconButton
                            aria-label="Add prompt"
                            icon={<AddIcon/>}
                            onClick={handleAddPrompt}
                            isLoading={promptToSequenceMutation.isPending}
                        />
                    </HStack>
                </VStack>
            </Collapse>
        </Box>
    );
};