import axios from "axios";
import Cookie from "js-cookie";

export const instance = axios.create({
    baseURL: process.env.NODE_ENV === "development"
        ? "http://127.0.0.1:8000/api/v1/"
        : "https://backend.motive.beauty/api/v1",
    withCredentials: true,
});

export const getCSRFHeader = () => ({
    "X-CSRFToken": Cookie.get("csrftoken") || "",
});
