import useUser from "../../../hook/useUser";
import {useCollections} from "../../../hook/HCollection";
import React, {useState} from "react";
import {useThemeColors} from "../../../lib/Color";
import {Box, Heading, SimpleGrid, Text, VStack} from "@chakra-ui/react";
import {CollectionFolder} from "../../Collection/CollectionFolder";
import {ProjectBookMarkProjectCard} from "../ProjectBookMarkProjectCard";

export const BookmarkPanel = ({data, onSettingsClick, sequence_id}: any) => {
    const {user} = useUser();
    const {data: collections, refetch} = useCollections(user?.id);
    const [selectedCollection, setSelectedCollection] = useState(null);
    const {bgColor, textColor, accentColor} = useThemeColors();

    const handleCollectionClick = (collectionId: any) => {
        setSelectedCollection(collectionId === selectedCollection ? null : collectionId);
    };

    const selectedProjects = React.useMemo(() => {
        if (!selectedCollection) return [];
        const collection = collections?.find(c => c.id === selectedCollection);
        return collection ? collection.projects : [];
    }, [collections, selectedCollection]);

    return (
        <VStack align="stretch" spacing={4}>
            <Heading size="md">Bookmark</Heading>
            <VStack align="stretch" spacing={3}>
                {collections?.map((collection) => (
                    <CollectionFolder
                        key={collection.id}
                        collection={collection}
                        isSelected={collection.id === selectedCollection}
                        onClick={() => handleCollectionClick(collection.id)}
                        onRefetch={() => refetch()}
                    />
                ))}
            </VStack>

            {selectedCollection && (
                <Box>
                    <Heading as="h3" size="md" mb={2} color={textColor}>
                        {collections?.find(c => c.id === selectedCollection)?.name}
                    </Heading>
                    {selectedProjects.length > 0 ? (
                        <SimpleGrid columns={2} spacing={4}>
                            {selectedProjects.map((project) => (
                                <ProjectBookMarkProjectCard
                                    key={project.id}
                                    project={project}
                                    collectionId={selectedCollection}
                                    onRefetch={() => refetch()}
                                    onSettingsClick={onSettingsClick}
                                    sequence_id={sequence_id}
                                />
                            ))}
                        </SimpleGrid>
                    ) : (
                        <Text color={textColor}>This collection has no projects.</Text>
                    )}
                </Box>
            )}
        </VStack>
    );
};

