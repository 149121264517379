import React from 'react';
import {useMutation, QueryClient, QueryClientProvider, useQueryClient, useQuery} from '@tanstack/react-query';
import {
    Box, Container, Heading,
    Text,
    Button,
    List,
    ListItem,
    Divider, VStack, SimpleGrid, FormControl,
    FormLabel,
    Input,
    Select,
    Textarea,
    FormErrorMessage,
    IconButton, Tooltip, HStack, Wrap, WrapItem, Flex, Grid, GridItem, useBreakpointValue

} from "@chakra-ui/react";
import {Prompt, Category, PromptFormData, PromptResponse} from '../interface/IPrompts';
import {createPrompt, deletePrompt, deletePromptItem, getPrompts, updatePrompt} from '../api/APrompts';
import {CloseIcon} from '@chakra-ui/icons';
import {useThemeColors} from "../lib/Color";
import {
    containerMaxWidth,
    containerPadding,
    gridColumns,
    gridSpacing,
    headingSize, SidebarWidth, SmallSidebarWidth,
    titleSize
} from "../lib/ResponsiveValue";
import {PromptCard} from '../elements/Prompt/PromptCard';
import {PromptForm} from '../elements/Prompt/PromptForm';

export default function PromptPage() {
    const {bgColor, textColor, accentColor, borderColor} = useThemeColors();
    const {data: prompts, isLoading, error} = useQuery<Prompt[]>({queryKey: ['prompts'], queryFn: getPrompts});

    const mainContentWidth = useBreakpointValue({base: "calc(100% - 250px)", md: "calc(100% - 350px)"});

    return (
        <Box bg={bgColor} minHeight="100vh" py={6} mt={12}>
            <Flex>
                {/* Main Content */}
                <Box width={mainContentWidth} p={0}>
                    <Container maxW={containerMaxWidth} px={containerPadding}>
                        <VStack spacing={8} align="stretch">
                            <Heading textAlign="left" fontSize={titleSize} color={textColor}>
                                Prompts 🌍
                            </Heading>
                            <Text color={textColor} fontSize="md" mb={2}>
                                Connect, share, and learn with fellow AI enthusiasts! 🚀
                            </Text>
                            {isLoading ? (
                                <Text color={textColor}>Loading prompts...</Text>
                            ) : error ? (
                                <Text color="red.500">Error loading prompts: {(error as Error).message}</Text>
                            ) : (
                                <Flex direction="column" gap={4}>
                                    {prompts?.map((prompt: Prompt) => (
                                        <PromptCard key={prompt.id} prompt={prompt}/>
                                    ))}
                                </Flex>
                            )}
                        </VStack>
                    </Container>
                </Box>

                {/* Sidebar */}
                <Box
                    position="fixed"
                    right={0}
                    top={0}
                    height="100vh"
                    width={SmallSidebarWidth}
                    overflowY="auto"
                    p={6}
                    borderLeft="1px solid"
                    borderColor={borderColor}
                    bg={bgColor}
                    zIndex={1}
                    display={{base: "block", md: "block"}}
                >
                    <Heading fontSize="2xl" color={textColor} mt={10}
                    >
                        Create New Prompt
                    </Heading>
                    {/*<Heading as="h2" size="xl" mb={4} color={textColor}></Heading>*/}
                    <PromptForm/>
                </Box>
            </Flex>
        </Box>
    );
}

