import React from 'react';
import { Box, Skeleton } from "@chakra-ui/react";
import Masonry from 'react-masonry-css';

const masonryStyles = `
.my-masonry-grid {
  display: flex;
  margin-left: -16px;
  width: auto;
}
.my-masonry-grid_column {
  padding-left: 16px;
  background-clip: padding-box;
}
.my-masonry-grid_column > div {
  margin-bottom: 16px;
}
`;

const PinImageSkeleton: React.FC = () => {
    const breakpointColumnsObj = {
        default: 5,
        1100: 4,
        700: 3,
        500: 2
    };

    const skeletonCount = 20; // 표시할 스켈레톤 아이템의 수

    return (
        <Box>
            <style>{masonryStyles}</style>
            <Masonry
                breakpointCols={breakpointColumnsObj}
                className="my-masonry-grid"
                columnClassName="my-masonry-grid_column"
            >
                {[...Array(skeletonCount)].map((_, index) => (
                    <Box
                        key={index}
                        borderRadius="md"
                        overflow="hidden"
                        boxShadow="md"
                        height={`${Math.floor(Math.random() * (400 - 150 + 1) + 150)}px`}
                    >
                        <Skeleton
                            height="100%"
                            width="100%"
                            startColor="gray.100"
                            endColor="gray.300"
                        />
                    </Box>
                ))}
            </Masonry>
        </Box>
    );
}

export default PinImageSkeleton;