import React, {useState, useEffect, useMemo} from 'react';
import {useDisclosure, Box, Image} from "@chakra-ui/react";
import {useQuery} from "@tanstack/react-query";
import Masonry from 'react-masonry-css';
import PinImageSkeleton from "./PinImageSkeleton";
import {getAllProject} from "../api/AProject";
import ProjectModal from "./ProjectModal";
import {project, Projects} from "../interface/Iproject";
import {useALLProjects} from "../hook/HProject";

const masonryStyles = `
.my-masonry-grid {
  display: flex;
  margin-left: -16px;
  width: auto;
}
.my-masonry-grid_column {
  padding-left: 16px;
  background-clip: padding-box;
}
.my-masonry-grid_column > div {
  margin-bottom: 16px;
}
`;

interface PinImageProps {
    searchTerm?: string;
    selectedTag?: string;
}


const PinImage: React.FC<PinImageProps> = ({searchTerm = "", selectedTag = "All"}) => {
    const [selectedProject, setSelectedProject] = useState<Projects | null>(null);
    const [imageHeights, setImageHeights] = useState<{ [key: number]: number }>({});
    const {
        isOpen: isProjectOpen,
        onClose: onProjectClose,
        onOpen: onProjectOpen,
    } = useDisclosure();

    const {data: data, error: error, isLoading: isLoading} = useALLProjects();

    useEffect(() => {
        if (data && Array.isArray(data)) {
            const heights = data.reduce((acc, _, index) => {
                acc[index] = Math.floor(Math.random() * (400 - 150 + 1) + 150);
                return acc;
            }, {} as { [key: number]: number });
            setImageHeights(heights);
        }
    }, [data]);


    const filteredProjects = useMemo(() => {
        // Ensure data is an array before filtering
        if (!data || !Array.isArray(data)) return [];

        return data.filter(project => {
            // Tag filtering
            const tagFilter =
                selectedTag === "All" ||
                (project.request.tags &&
                    (Array.isArray(project.request.tags.tags)
                            ? project.request.tags.tags.some((tag: any) => tag.toLowerCase().includes(selectedTag.toLowerCase()))
                            : typeof project.request.tags.tags === 'string' && project.request.tags.tags.toLowerCase().includes(selectedTag.toLowerCase())
                    )) ||
                project.request.prompt.toLowerCase().includes(selectedTag.toLowerCase());

            // Search term filtering
            const searchFilter =
                searchTerm === "" ||
                project.request.prompt.toLowerCase().includes(searchTerm.toLowerCase()) ||
                (project.request.tags &&
                    (Array.isArray(project.request.tags.tags)
                            ? project.request.tags.tags.some((tag: any) => tag.toLowerCase().includes(searchTerm.toLowerCase()))
                            : typeof project.request.tags.tags === 'string' && project.request.tags.tags.toLowerCase().includes(searchTerm.toLowerCase())
                    ));

            return tagFilter && searchFilter;
        });
    }, [data, searchTerm, selectedTag]);
    const handleProjectClick = (project: Projects) => {
        setSelectedProject(project);
        onProjectOpen();
    };

    const breakpointColumnsObj = {
        default: 5,
        1100: 4,
        700: 3,
        500: 2
    };

    return (
        <Box>
            <style>{masonryStyles}</style>
            {isLoading ? (
                <PinImageSkeleton/>
            ) : (
                <Masonry
                    breakpointCols={breakpointColumnsObj}
                    className="my-masonry-grid"
                    columnClassName="my-masonry-grid_column"
                >
                    {(filteredProjects).map((project, index) => (
                        <Box
                            key={index}
                            onClick={() => handleProjectClick(project)}
                            cursor="pointer"
                            borderRadius="md"
                            overflow="hidden"
                            boxShadow="md"
                            transition="transform 0.2s"
                            _hover={{transform: 'scale(1.02)'}}
                            height={`${imageHeights[index]}px`}
                        >
                            {project.response?.output_images && project.response.output_images.length > 0 && (
                                <Image
                                    src={project.response.output_images[0]}
                                    alt={`Project ${index}`}
                                    width="100%"
                                    height="100%"
                                    objectFit="cover"
                                />
                            )}
                        </Box>
                    ))}
                </Masonry>
            )}
            {selectedProject && (
                <ProjectModal isOpen={isProjectOpen} onClose={onProjectClose} projectData={selectedProject}/>
            )}
        </Box>
    );
}

export default PinImage;