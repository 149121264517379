import React, {useEffect} from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import useUser from "../hook/useUser";
import {useDisclosure} from "@chakra-ui/react";
import LoginModal from "./LoginModal";


export const useProtectedNavigation = () => {
  const { isLoggedIn } = useUser();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();

  const goTo = (path: any) => {
    if (isLoggedIn) {
      navigate(path);
    } else {
      onOpen();
    }
  };

  return { goTo, isOpen, onClose };
};