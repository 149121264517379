import {QueryFunctionContext} from "@tanstack/react-query";
import {ICreatePhotoVariables} from "../api";
import {instance, getCSRFHeader} from "./Abase";
import Cookie from "js-cookie";
import { ICreateProject } from "../interface/Iproject";


export const getAllProject = () =>
    instance.get(`project/allproject`).then((response) => response.data);


export const getUserProjects = () =>
    instance.get("project/UserProject").then((response) => response.data);

export const deleteUserProject = async (projectId: string | number) =>
  instance.delete<void>(`project/UserProject`, {
    headers: getCSRFHeader(),
    data: { project_id: projectId }
  }).then((response) => response.data);


export const getProject = (projectID: string | number) =>
    instance.get(`project/${projectID}/`).then((response) => response.data);


export const getLikedProject = () =>
    instance.get(`project/liked-projects`).then((response) => response.data);



export const postProjectLike = (projectID: string) =>
    instance.post(
        `project/${projectID}/like/`,
        {}, // body는 비워둡니다
        {
            headers: getCSRFHeader()
        }
    ).then((response) => response.data);

export const updateProjectShared = (projectId: string | number, shared: boolean) =>
  instance.patch<void>(
    `project/${projectId}/`,
    { shared },
    { headers: getCSRFHeader() }
  ).then((response) => response.data);

export const createProject = ({
                                  title,
                                  description,
                                  tool_id,
                                  request_id,
                                  response_id,
                                  shared,
                              }: ICreateProject) =>
    instance
        .post(
            `project/UserProject`,
            {title, description, tool_id, request_id, response_id, shared},
            {
                headers: {
                    "X-CSRFToken": Cookie.get("csrftoken") || "",
                    "Content-Type": "application/json",
                },
            }
        )
        .then((response) => response.data)
        .catch((error) => {
            console.error("There was an error creating the project!", error);
            throw error;
        });