// hooks/useImageUpload.ts
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {useToast} from "@chakra-ui/react";
import {v4 as uuidv4} from "uuid";
import {getUploadURL, uploadImage, ImageUploadOnlyRequest, deleteAIInputImage} from "../api/AMedias";

export const useImageUpload = () => {
    const toast = useToast();
    const queryClient = useQueryClient();

    const uploadURLMutation = useMutation({
        mutationFn: getUploadURL,
        onSuccess: (data: any, variables: any, context: any) => {
            if (variables.file) {
                console.log("Initiating image upload to Cloudflare");
                uploadImageMutation.mutate({
                    file: variables.file,
                    uploadURL: data.uploadURL,
                });
            } else {
                console.log("No file to upload");
            }
        },
        onError: (error) => {
            toast({
                status: "error",
                title: "Failed to get upload URL",
                description: error.message,
                isClosable: true,
            });
        },
    });

    const uploadImageMutation = useMutation({
        mutationFn: uploadImage,
        onSuccess: ({result}) => {
            const generatedId = uuidv4();

            UploadPhotoMutation.mutate({
                file: `https://imagedelivery.net/6f5NCckxdQAg0banLEqXWw/${result.id}/public`,
                id: generatedId,
                image_type: 0,
                CF_id: result.id,  // 추가: Cloudflare ID 저장

            });
        },
        onError: (error) => {
            toast({
                status: "error",
                title: "Failed to upload image",
                description: error.message,
                isClosable: true,
            });
        },
    });

    const UploadPhotoMutation = useMutation({
        mutationFn: ImageUploadOnlyRequest,
        onSuccess: () => {
            toast({
                status: "success",
                title: "Image uploaded!",
                isClosable: true,
                description: "Feel free to upload more images.",
            });
            queryClient.refetchQueries({queryKey: ['aiInputImages']});
        },
        onError: (error) => {
            toast({
                status: "error",
                title: "Failed to save image details",
                description: error.message,
                isClosable: true,
            });
        },
    });

    const onSaveImage = (file: File) => {
        uploadURLMutation.mutate({file});
    };

    return {
        onSaveImage,
        isUploading: uploadURLMutation.isPending || uploadImageMutation.isPending || UploadPhotoMutation.isPending,
    };
};



export const useDeleteAIInputImage = () => {
    const queryClient = useQueryClient();
    const toast = useToast();

    return useMutation({
        mutationFn: (imageId: string) => deleteAIInputImage(imageId),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['aiInputImages'] });
            toast({
                title: "Image deleted successfully",
                status: "success",
                duration: 3000,
                isClosable: true,
            });
        },
        onError: (error) => {
            toast({
                title: "Failed to delete image",
                description: error instanceof Error ? error.message : "An unknown error occurred",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        },
    });
};