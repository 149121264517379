import React, {useState} from 'react';
import {Box, Flex, Text, IconButton, Wrap, WrapItem, Input, Button} from '@chakra-ui/react';
import {CloseIcon, AddIcon} from '@chakra-ui/icons';
import {Prompt} from '../../interface/IPrompts';
import {useThemeColors} from "../../lib/Color";
import {useRemovePromptFromSequence, useAddPromptToSequence, usePromptToSequence} from "../../hook/HSequence";
import useCustomToast from "../../hook/HToast";

interface SequencePromptProps {
    prompt: Prompt;
    sequenceId: string;
    onRemove: () => void;
    displayMode: 'horizontal' | 'grid' | 'list';
}

export const SequencePrompt: React.FC<SequencePromptProps> = ({prompt, sequenceId, onRemove, displayMode}) => {
    const {cardBgColor, textColor, borderColor, accentColor} = useThemeColors();
    const removePromptMutation = useRemovePromptFromSequence();
    const promptToSequenceMutation = usePromptToSequence();

    const [newPrompt, setNewPrompt] = useState('');
    const {showToast} = useCustomToast();
    const [editedContent, setEditedContent] = useState(prompt.content);
    const [isEditing, setIsEditing] = useState(false);

    const handleRemove = async () => {
        if (!sequenceId) {
            console.error("Sequence ID is missing");
            return;
        }

        try {
            await removePromptMutation.mutateAsync({sequence_id: sequenceId, prompt_id: prompt.id});
            onRemove();
        } catch (error) {
            console.error("Failed to remove prompt", error);
        }
    };


    const handleAddPrompt = () => {
        if (!newPrompt.trim()) return;

        // 입력된 newPrompt를 쉼표로 구분하여 배열로 변환
        const newWords = newPrompt.split(',').map(word => word.trim()).filter(word => word !== '');

        let updatedContent: string[];
        if (Array.isArray(prompt.content)) {
            updatedContent = [...prompt.content, ...newWords];
        } else if (typeof prompt.content === 'string') {
            updatedContent = prompt.content
                ? [...prompt.content.split(',').map(item => item.trim()), ...newWords]
                : newWords;
        } else {
            updatedContent = newWords;
        }

        promptToSequenceMutation.mutate(
            {
                sequence_id: sequenceId,
                prompt_id: prompt.id,
                prompt_content: updatedContent
            },
            {
                onSuccess: () => {
                    setNewPrompt('');
                    showToast(newWords.length > 1
                        ? "Words added to prompt successfully"
                        : "Word added to prompt successfully", "success");
                },
                onError: () => {
                    showToast("Failed to add word(s) to prompt", "error");
                }
            }
        );
    };

    const renderContent = () => {
        let contentArray: string[] = [];
        if (Array.isArray(prompt.content)) {
            contentArray = prompt.content;
        } else if (typeof prompt.content === 'string') {
            contentArray = prompt.content.split(',').map(item => item.trim());
        }

        return (
            <Wrap spacing={2}>
                {contentArray.length > 0 ? (
                    contentArray.map((item: string, index: number) => (
                        <WrapItem key={index}>
                            <Box
                                borderWidth={1}
                                borderColor={borderColor}
                                borderRadius="full"
                                px={3}
                                py={1}
                                fontSize="sm"
                                color={textColor}
                            >
                                {item}
                            </Box>
                        </WrapItem>
                    ))
                ) : (
                    <Text fontSize="sm" color={textColor}>No content available</Text>
                )}
            </Wrap>
        );
    };
    const renderPromptInput = () => (
        <Flex mt={4}>
            <Input
                value={newPrompt}
                onChange={(e) => setNewPrompt(e.target.value)}
                placeholder="Add a new word..."
                mr={2}
            />
            <IconButton
                aria-label="Add word"
                icon={<AddIcon/>}
                onClick={handleAddPrompt}
                isLoading={promptToSequenceMutation.isPending}
            />
        </Flex>
    );

    const commonStyles = {
        borderWidth: 1,
        borderRadius: "lg",
        bg: cardBgColor,
        borderColor: borderColor,
        boxShadow: "md",
        position: "relative" as const,
        width: "100%",
        overflow: "hidden",
    };

    const renderCloseButton = () => (
        <IconButton
            aria-label="Remove prompt"
            icon={<CloseIcon/>}
            size="sm"
            position="absolute"
            top={2}
            right={2}
            onClick={handleRemove}
            zIndex={1}
            variant="ghost"
        />
    );

    switch (displayMode) {
        case 'horizontal':
            return (
                <Box {...commonStyles} p={6}>
                    {renderCloseButton()}
                    <Flex direction="row" justifyContent="space-between" alignItems="flex-start" height="100%">
                        <Box width="30%">
                            <Text fontWeight="bold" fontSize="xl" mb={4} color={accentColor}>
                                {prompt.category?.customName || "Unnamed Category"}
                            </Text>
                        </Box>
                        <Box width="70%" overflowY="auto">
                            {renderContent()}
                        </Box>
                    </Flex>
                    {renderPromptInput()}
                </Box>
            );

        case 'grid':
            return (
                <Box {...commonStyles} p={4} height="300px">
                    {renderCloseButton()}
                    <Flex direction="column">
                        <Text fontWeight="bold" mb={3} color={accentColor}>
                            {prompt.category?.customName || "Unnamed Category"}
                        </Text>
                        <Box flex={1} overflowY="auto">
                            {renderContent()}
                        </Box>
                    </Flex>
                    {renderPromptInput()}
                </Box>
            );

        case 'list':
        default:
            return (
                <Box {...commonStyles} p={4}>
                    {renderCloseButton()}
                    <Flex direction="column">
                        <Text fontWeight="bold" mb={2} color={accentColor}>
                            {prompt.category?.customName || "Unnamed Category"}
                        </Text>
                        {renderContent()}
                    </Flex>
                    {renderPromptInput()}
                </Box>
            );
    }
};