import {useQuery, useMutation, useQueryClient} from '@tanstack/react-query';
import {useToast} from '@chakra-ui/react';
import {getPrompts, createPrompt, updatePrompt, deletePrompt, deletePromptItem} from '../api/APrompts';
import {Prompt, PromptFormData} from '../interface/IPrompts';


// export const promptsQuery = useQuery({
//     queryKey: ['prompts'],
//     queryFn: getPrompts,
// });
//
// export const createPromptMutation = useMutation({
//     mutationFn: (promptData: PromptFormData) => createPrompt(promptData),
//     onSuccess: () => {
//         queryClient.invalidateQueries({queryKey: ['prompts']});
//         toast({
//             title: "Prompt created successfully",
//             status: "success",
//             duration: 3000,
//             isClosable: true,
//         });
//     },
//     onError: (error) => {
//         toast({
//             title: "Failed to create prompt",
//             description: error instanceof Error ? error.message : "An unknown error occurred",
//             status: "error",
//             duration: 3000,
//             isClosable: true,
//         });
//     },
// });
//
//
// export const deletePromptMutation = useMutation({
//     mutationFn: (id: number) => deletePrompt(id),
//     onSuccess: () => {
//         queryClient.invalidateQueries({queryKey: ['prompts']});
//         toast({
//             title: "Prompt deleted successfully",
//             status: "success",
//             duration: 3000,
//             isClosable: true,
//         });
//     },
//     onError: (error) => {
//         toast({
//             title: "Failed to delete prompt",
//             description: error instanceof Error ? error.message : "An unknown error occurred",
//             status: "error",
//             duration: 3000,
//             isClosable: true,
//         });
//     },
// });


export const useDeletePromptMutation = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: deletePrompt,
        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: ['prompts']});

        },
    });
};

interface DeletePromptItemVariables {
  categoryId: number;
  promptIndex: number;
  sequenceId?: string; // sequenceId를 선택적 매개변수로 추가
}

export const useDeletePromptItemMutation = () => {
    const queryClient = useQueryClient();

    return useMutation<Prompt, Error, DeletePromptItemVariables>({
        mutationFn: ({ categoryId, promptIndex }) => deletePromptItem(categoryId, promptIndex),
        onSuccess: (_, variables) => {
            // 전체 프롬프트 목록 무효화
            queryClient.invalidateQueries({ queryKey: ['prompts'] });

            // sequenceId가 제공된 경우 해당 시퀀스의 상세 정보 무효화
            if (variables.sequenceId) {
                queryClient.invalidateQueries({
                    queryKey: ['getSequenceDetail', variables.sequenceId]
                });
            }
        },
    });
};

export const useCreatePromptMutation = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: createPrompt,
        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: ['prompts']});
        },
    });
};

export const useUpdatePromptMutation = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: ({id, promptData}: { id: number; promptData: PromptFormData }) =>
            updatePrompt({id, promptData}),
        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: ['prompts']});
        },
    });
};

export const usePrompts = () => {
    return useQuery<Prompt[]>({queryKey: ['prompts'], queryFn: getPrompts});
};