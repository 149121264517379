import React, {useState, useRef, useEffect} from 'react';
import {
    FaAirbnb,
    FaMoon,
    FaSun,
    FaThumbtack,
    FaUser,
    FaCog,
    FaSignOutAlt,
    FaChevronRight,
    FaCoins,
    FaGlobe, // 언어 변경 아이콘
    FaCheck,

} from "react-icons/fa";
import {
    Box,
    Flex,
    Stack,
    Avatar,
    Button,
    IconButton,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    useColorMode,
    useColorModeValue,
    useDisclosure,
    useToast,
    ToastId,
    Text,
    VStack,
    HStack,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    ModalFooter,
    useTheme, Divider, Image, Select, Icon, FormControl,
    FormLabel, Input, Switch, Tabs,
    TabList,
    TabPanels,
    Tab,
    TabPanel, Heading,
} from "@chakra-ui/react";
import {useNavigate, Link} from "react-router-dom";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import LoginModal from "./LoginModal";
import SignUpModal from "./SignUpModal";
import useUser from "../hook/useUser";
import {logOut} from "../api/AUser";
import IntegratedSidebar from "./IntegratedSidebar";
import {useThemeColors} from "../lib/Color";
import LogoWhite from "../styles/logo/LOGO_512_512_White.svg"
import LogoBlack from "../styles/logo/LOGO_512_512_Black.svg"
import {useLanguage} from '../hook/HLanguage';
import {useIsMobile} from "../lib/ResponsiveValue";


interface LanguageModalProps {
    isOpen: boolean;
    onClose: () => void;
    changeLanguage: (lang: string) => void;
    currentLanguage: string;
    t: (key: string) => string;
}


const LanguageModal: React.FC<LanguageModalProps> = ({isOpen, onClose, changeLanguage, currentLanguage, t}) => {
    const [fullName, setFullName] = useState('DongHyeon');
    const [preferredName, setPreferredName] = useState('DongHyeon');
    const [workDescription, setWorkDescription] = useState('Engineering');
    const [showPromptSuggestions, setShowPromptSuggestions] = useState(true);
    const [enableArtifacts, setEnableArtifacts] = useState(true);
    const [selectedLanguage, setSelectedLanguage] = useState(currentLanguage);
    const {bgColor, textColor, accentColor, cardBgColor, borderColor} = useThemeColors();

    const hoverBgColor = useColorModeValue("pink.100", "pink.500");

    const languages = [
        {code: 'en', name: 'English'},
        {code: 'ko', name: '한국어'},
    ];

    const handleUpdateName = () => {
        console.log('Name updated');
    };

    const handleLanguageSelect = (lang: string) => {
        setSelectedLanguage(lang);
        changeLanguage(lang);
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose} size="xl">
            <ModalOverlay/>
            <ModalContent maxW="900px" h="600px" bg={bgColor} borderColor={borderColor} borderWidth={1}>
                <ModalHeader>Settings</ModalHeader>
                <ModalCloseButton/>
                <ModalBody>
                    <Tabs orientation="vertical" display="flex" h="100%">
                        <TabList width="200px" borderColor="gray">
                            <Tab justifyContent="flex-start" pl={4}>Profile</Tab>

                            <Tab justifyContent="flex-start" pl={4}>
                                <Flex align="center">
                                    <Icon as={FaGlobe} mr={2}/>
                                    <Text>{t('languageSettings')}</Text>
                                </Flex>
                            </Tab>
                            <Tab justifyContent="flex-start" pl={4}>Invoice</Tab>

                        </TabList>

                        <TabPanels flex={1}>
                            <TabPanel>
                                <VStack spacing={4} align="stretch">
                                    <FormControl>
                                        <FormLabel>Full name</FormLabel>
                                        <Input
                                            value={fullName}
                                            onChange={(e) => setFullName(e.target.value)}
                                        />
                                    </FormControl>

                                    <FormControl>
                                        <FormLabel>What should we call you?</FormLabel>
                                        <Input
                                            value={preferredName}
                                            onChange={(e) => setPreferredName(e.target.value)}
                                        />
                                    </FormControl>

                                    <Button onClick={handleUpdateName} colorScheme="pink" width="fit-content">
                                        Update Name
                                    </Button>


                                    <FormControl display="flex" alignItems="center">
                                        <FormLabel mb="0" flex="1">Show prompt suggestions</FormLabel>
                                        <Switch
                                            isChecked={showPromptSuggestions}
                                            onChange={(e) => setShowPromptSuggestions(e.target.checked)}
                                        />
                                    </FormControl>

                                    <FormControl display="flex" alignItems="center">
                                        <FormLabel mb="0" flex="1">Enable artifacts</FormLabel>
                                        <Switch
                                            isChecked={enableArtifacts}
                                            onChange={(e) => setEnableArtifacts(e.target.checked)}
                                        />
                                    </FormControl>
                                </VStack>
                            </TabPanel>

                            <TabPanel>
                                <VStack spacing={4} align="stretch">
                                    <Box>
                                        <Text fontWeight="bold" mb={2}>{t('selectLanguage')}</Text>
                                        <VStack spacing={2} align="stretch">
                                            {languages.map((lang) => (
                                                <Button
                                                    key={lang.code}
                                                    onClick={() => handleLanguageSelect(lang.code)}
                                                    variant="outline"
                                                    justifyContent="space-between"
                                                    bg={selectedLanguage === lang.code ? hoverBgColor : 'transparent'}
                                                    _hover={{bg: hoverBgColor}}
                                                >
                                                    <Text>{lang.name}</Text>
                                                    {selectedLanguage === lang.code && <Icon as={FaCheck}/>}
                                                </Button>
                                            ))}
                                        </VStack>
                                    </Box>
                                </VStack>
                            </TabPanel>
                        </TabPanels>
                    </Tabs>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

interface HeaderProps {
    onPinChange: (isPinned: boolean) => void;
}

export default function Header({onPinChange}: HeaderProps) {
    const {t, changeLanguage, currentLanguage} = useLanguage();

    const navigate = useNavigate();

    const [isExpanded, setIsExpanded] = useState(false);
    const [isPinned, setIsPinned] = useState(false);
    const {
        isOpen: isLoginOpen,
        onClose: onLoginClose,
        onOpen: onLoginOpen,
    } = useDisclosure();
    const {
        isOpen: isSignUpOpen,
        onClose: onSignUpClose,
        onOpen: onSignUpOpen,
    } = useDisclosure();
    const logoColor = useColorModeValue("red.500", "red.200");
    const {colorMode, toggleColorMode} = useColorMode();
    const Icon = useColorModeValue(FaMoon, FaSun);
    const avatarBg = useColorModeValue("gray.100", "gray.700");
    const toast = useToast();
    const queryClient = useQueryClient();
    const toastId = useRef<ToastId>();
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    // const {userLoading, isLoggedIn, user} = useUser();
    const {bgColor, textColor, borderColor, accentColor} = useThemeColors();
    const [isLanguageMenuOpen, setIsLanguageMenuOpen] = useState(false);
    const [isLanguageModalOpen, setIsLanguageModalOpen] = useState(false);

    const {userLoading, isLoggedIn, user, tokenBalance} = useUser();

    const mutation = useMutation({
        mutationFn: logOut,
        onMutate: () => {
            toastId.current = toast({
                title: "Logging out...",
                description: "Sad to see you go...",
                status: "loading",
                position: "bottom-right",
            });
        },
        onSuccess: () => {
            if (toastId.current) {
                queryClient.refetchQueries({queryKey: ["me"]});
                toast.update(toastId.current, {
                    status: "success",
                    title: "Done!",
                    description: "See you later!",
                });
            }
        },
    });

    const onLogOut = async () => {
        mutation.mutate();
    };

    const togglePin = () => {
        const newPinnedState = !isPinned;
        setIsPinned(newPinnedState);
        onPinChange(newPinnedState);
    };

    const handleMouseEnter = () => {
        if (!isPinned) {
            setIsExpanded(true);
        }
    };

    const handleMouseLeave = () => {
        if (!isPinned) {
            setIsExpanded(false);
            setIsMenuOpen(false); // Close the menu when leaving the sidebar

        }
    };

    const sidebarWidth = isPinned || isExpanded ? "250px" : "80px";
    const theme = useTheme();
    // const {toggleColorMode} = useColorMode();
    const ColorModeIcon = useColorModeValue(FaMoon, FaSun);
    const LogoIcon = useColorModeValue(LogoBlack, LogoWhite);
    const menuHoverBgColor = useColorModeValue("rgba(255, 255, 255, 0.45)", "rgba(255, 255, 255, 0.16)");
    const isMobile = useIsMobile(); // 커스텀 훅 사용

    const handleBillingClick = () => {
        navigate("/Billing");
        setIsMenuOpen(false);
    };

    if (isMobile) {
        return (
            <>
                <IntegratedSidebar
                    isExpanded={true}
                    isLoggedIn={isLoggedIn}
                    onLoginOpen={onLoginOpen}  // 여기를 수정
                />
                <LoginModal isOpen={isLoginOpen} onClose={onLoginClose} />

                {/*<SignUpModal isOpen={isSignUpOpen} onClose={onSignUpClose} />*/}
                <LanguageModal
                    isOpen={isLanguageModalOpen}
                    onClose={() => setIsLanguageModalOpen(false)}
                    changeLanguage={changeLanguage}
                    currentLanguage={currentLanguage}
                    t={t}
                />
            </>
        );
    }

    return (
        <Box
            w={sidebarWidth}
            transition="width 0.3s"
            bg={bgColor}
            borderRight="1px"
            borderColor={borderColor}
            h="100vh"
            position="fixed"
            left="0"
            top="0"
            zIndex="sticky"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <Flex direction="column" h="100%" justifyContent="space-between">
                <Box>
                    <Flex
                        justify="space-between"
                        align="center"
                        h="60px"
                        px={4}
                        ml={2}
                    >
                        <Box width="32px" flexShrink={0}>
                            <Link to="/">
                                {/*<FaAirbnb size={32} color={logoColor}/>*/}
                                <Image src={LogoIcon}
                                       alt={""}
                                       objectFit="cover"
                                    // height="200px"
                                       width="100%"
                                />
                            </Link>
                        </Box>
                        {(isExpanded || isPinned) && (
                            <IconButton
                                aria-label="Pin sidebar"
                                icon={<FaThumbtack/>}
                                size="sm"
                                onClick={togglePin}
                                color={isPinned ? logoColor : "gray.500"}
                            />
                        )}
                    </Flex>
                    <Divider my={0}/>

                    <Box>
                        <IntegratedSidebar isExpanded={isPinned || isExpanded}/>
                    </Box>
                </Box>
                <Box mt="auto">
                    {!userLoading && isLoggedIn ? (
                        <Menu isOpen={isMenuOpen} onOpen={() => setIsMenuOpen(true)}
                              onClose={() => setIsMenuOpen(false)}>
                            <MenuButton
                                as={Button}
                                w="100%"
                                h="auto"
                                p={6}
                                bg="transparent"
                                _hover={{bg: menuHoverBgColor}}
                                _active={{bg: menuHoverBgColor}}
                                borderRadius="0"
                            >
                                <Flex justify="space-between" align="center" w="100%">
                                    <HStack spacing={3}>
                                        <Avatar name={user?.name} src={user?.avatar}
                                                size={isPinned || isExpanded ? "md" : "sm"}/>
                                        {(isPinned || isExpanded) && (
                                            <VStack align="start" spacing={0}>
                                                <HStack>
                                                    <FaCoins size={12} color={theme.colors.pink[500]}/>
                                                    <Text fontSize="sm"
                                                          color="gray.500">{tokenBalance || 0} Token</Text>
                                                </HStack>
                                                <Text fontWeight="bold">{user?.name || "User"}</Text>
                                                <Text fontSize="sm"
                                                      color="gray.500">{user?.email || "Professional plan"}</Text>
                                            </VStack>
                                        )}
                                    </HStack>
                                    {(isPinned || isExpanded) && (
                                        <Box w="12px" h="12px" minW="12px" minH="12px">
                                            <FaChevronRight size="100%"/>
                                        </Box>
                                    )}

                                </Flex>
                            </MenuButton>
                            <MenuList bg={bgColor}
                                      borderColor={menuHoverBgColor}
                                      boxShadow="md"
                                      sx={{
                                          '.chakra-menu__menuitem': {
                                              bg: bgColor,
                                              _hover: {bg: menuHoverBgColor}
                                          }
                                      }}>
                                <MenuItem icon={<FaUser/>} onClick={handleBillingClick}>Billing</MenuItem>
                                <MenuItem icon={<ColorModeIcon/>} onClick={toggleColorMode}>
                                    Toggle {colorMode === "light" ? "Dark" : "Light"} Mode
                                </MenuItem>
                                <MenuItem icon={<FaCog/>} onClick={() => setIsLanguageModalOpen(true)}>
                                    {t('settings')}
                                </MenuItem>

                                <MenuItem icon={<FaSignOutAlt/>} onClick={onLogOut}>Log out</MenuItem>

                            </MenuList>
                        </Menu>
                    ) : (
                        <Button size="sm" onClick={onLoginOpen} w="100%">Log in</Button>
                    )}
                </Box>
            </Flex>
            <LoginModal isOpen={isLoginOpen} onClose={onLoginClose}/>
            {/*<SignUpModal isOpen={isSignUpOpen} onClose={onSignUpClose}/>*/}
            <LanguageModal
                isOpen={isLanguageModalOpen}
                onClose={() => setIsLanguageModalOpen(false)}
                changeLanguage={changeLanguage}
                currentLanguage={currentLanguage}
                t={t}
            />
        </Box>
    );
}