import React, {useState, useEffect} from 'react';
import {
    Box,
    Tabs,
    TabList,
    TabPanels,
    Tab,
    TabPanel,
    SimpleGrid,
    Image,
    Text,
    Spinner,
    Container,
    VStack,
    Heading,
} from '@chakra-ui/react';
import {useQuery, useMutation, useQueryClient} from '@tanstack/react-query';
import {useGetStorageImage} from '../hook/HGenerateAI';
import {getResponseByRequest} from '../api/ARequest';
import User from '../interface/IUser';
import {useThemeColors} from "../lib/Color";
import {containerMaxWidth, containerPadding, headingSize, titleSize} from "../lib/ResponsiveValue";
import Model_Card from "../elements/Model_Card";
import {AITool} from "../interface/IAITool";
import useUser from "../hook/useUser";
import {useUserProjects} from "../hook/HProject";
import {Projects} from "../interface/Iproject";
import {AIInputImage} from '../interface/IMedias';
import {AIInputImageCard} from "../elements/Storage/Storage_AiInputImageCard";
import {GenerateProjectCard} from '../elements/Storage/Storage_GenerateProjectCard';



const Storage = () => {
    const {user} = useUser();
    const {bgColor, textColor, accentColor} = useThemeColors();
    const {data: userProjects, isLoading: projectsLoading, error: projectsError} = useUserProjects(user) as {
        data: Projects[] | undefined;
        isLoading: boolean;
        error: Error | null;
    };
    const {data: initImages, isLoading: initImagesLoading, error: initImagesError} = useGetStorageImage(user) as {
        data: AIInputImage[] | undefined;
        isLoading: boolean;
        error: Error | null;
    };


    return (
        <Box bg={bgColor} minHeight="100vh" py={6} mt={12}>
            <Container maxW={containerMaxWidth} px={containerPadding}>
                <VStack spacing={6} align="stretch">
                    <Heading textAlign="left" fontSize={titleSize} color={textColor}>
                        Storage 🗂️
                    </Heading>
                    <Text color={textColor} fontSize="lg" mb={0}>
                        Welcome to my Storage!
                    </Text>
                    <VStack align="start" spacing={2} pl={0}>
                        <Text>📁 <strong>Storage:</strong> Keep all your project files and assets in one place</Text>
                        <Text>💡 <strong>Prompts:</strong> Craft and store your AI prompts for easy access</Text>
                    </VStack>
                </VStack>
                <Tabs mt={6}>
                    <TabList>
                        <Tab>AI 생성 이미지</Tab>

                        <Tab>저장한 사진</Tab>
                    </TabList>

                    <TabPanels>
                        <TabPanel>
                            <VStack align="stretch" spacing={8}>
                                <Box>
                                    {projectsLoading ? (
                                        <Spinner size="xl"/>
                                    ) : projectsError ? (
                                        <Text color="red.500">Error loading user
                                            projects: {projectsError.message}</Text>
                                    ) : userProjects && userProjects.length > 0 ? (
                                        <SimpleGrid columns={[2, 3, 4, 5]} spacing={6}>
                                            {userProjects.map((project: Projects) => (
                                                <GenerateProjectCard key={project.id} project={project}/>
                                            ))}
                                        </SimpleGrid>
                                    ) : (
                                        <Text>No user projects found.</Text>
                                    )}
                                </Box>
                            </VStack>
                        </TabPanel>
                        <TabPanel>
                            <VStack align="stretch" spacing={8}>
                                <Box>
                                    {initImagesLoading ? (
                                        <Spinner size="xl"/>
                                    ) : initImagesError ? (
                                        <Text color="red.500">Error loading AI generated
                                            images: {initImagesError.message}</Text>
                                    ) : initImages && initImages.length > 0 ? (
                                        <SimpleGrid columns={[2, 3, 4, 5]} spacing={6}>
                                            {initImages.map((image: AIInputImage) => (
                                                <AIInputImageCard key={image.id} image={image}/>
                                            ))}
                                        </SimpleGrid>
                                    ) : (
                                        <Text>No AI generated images found.</Text>
                                    )}
                                </Box>
                            </VStack>
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            </Container>
        </Box>
    );
};

export default Storage;