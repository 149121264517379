import React from 'react';
import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    Button,
} from '@chakra-ui/react';
import {useThemeColors} from "../lib/Color";

interface CustomAlertDialogProps {
    isOpen: boolean;
    onClose: () => void;
    onConfirm: () => void;
    title: string;
    children: React.ReactNode;
}

const CustomAlertDialog: React.FC<CustomAlertDialogProps> = ({
                                                                 isOpen,
                                                                 onClose,
                                                                 onConfirm,
                                                                 title,
                                                                 children,
                                                             }) => {
    const cancelRef = React.useRef<HTMLButtonElement>(null);
    const {bgColor, textColor} = useThemeColors();

    return (
        <AlertDialog
            isOpen={isOpen}
            leastDestructiveRef={cancelRef}
            onClose={onClose}
        >
            <AlertDialogOverlay>
                <AlertDialogContent backgroundColor={bgColor}> {/* 배경색 적용 */}
                    <AlertDialogHeader fontSize="lg" fontWeight="bold" color={textColor}>
                        {title}
                    </AlertDialogHeader>

                    <AlertDialogBody>
                        {children}
                    </AlertDialogBody>

                    <AlertDialogFooter>
                        <Button ref={cancelRef} onClick={onClose}>
                            Cancel
                        </Button>
                        <Button colorScheme="red" onClick={onConfirm} ml={3}>
                            Confirm
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
    );
};

export default CustomAlertDialog;