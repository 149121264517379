import React, {useState, useMemo, useEffect, useCallback} from 'react';
import {
    Box,
    VStack,
    HStack,
    Text,
    Icon,
    Flex,
    Grid,
    Image,
    Container,
    Heading,
    Button,
    useDisclosure,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    IconButton,
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay, useToast, Input, Divider,
} from '@chakra-ui/react';
import {FaFolder, FaFolderOpen, FaPlus, FaHeart, FaChevronDown, FaEdit, FaTrash, FaTimes} from 'react-icons/fa';

import {
    useCollections,
    useRemoveCollection,
    useRemoveProjectFromCollection,
    useRenameCollection
} from '../hook/HCollection';
import useUser from '../hook/useUser';
import {useThemeColors} from "../lib/Color";
import {containerMaxWidth, containerPadding, headingSize, titleSize} from "../lib/ResponsiveValue";
import {CreateCollectionSidebar} from "../elements/Collection/CreateCollectionSidebar";
import {useRemoveProjectFromSequence} from "../hook/HSequence";
import {CollectionFolder} from '../elements/Collection/CollectionFolder';
import {Projects} from "../interface/Iproject";
import useCustomToast from '../hook/HToast';
import {BookMarkProjectCard} from '../elements/Collection/BookMarkProjectCard';

export default function CollectionsPage() {
    const {user} = useUser();
    const {bgColor, textColor, accentColor} = useThemeColors();

    const {data: collections, refetch} = useCollections(user?.id);
    const [selectedCollection, setSelectedCollection] = useState<string | null>(null);
    const {
        isOpen: isCollectionModalOpen,
        onOpen: onCollectionModalOpen,
        onClose: onCollectionModalClose
    } = useDisclosure();

    useEffect(() => {
        if (collections && collections.length > 0 && !selectedCollection) {
            setSelectedCollection(collections[0].id);
        } else if (collections && collections.length === 0) {
            setSelectedCollection(null);
        }
    }, [collections, selectedCollection]);
    const handleCollectionClick = (collectionId: any) => {
        setSelectedCollection(collectionId === selectedCollection ? null : collectionId);
    };

    const selectedProjects = useMemo(() => {
        if (!selectedCollection) return [];
        const collection = collections?.find(c => c.id === selectedCollection);
        return collection ? collection.projects : [];
    }, [collections, selectedCollection]);



    return (
        <Box bg={bgColor} minH="100vh" py={6} mt={12}>
            <Container maxW={containerMaxWidth} px={containerPadding}>
                <VStack spacing={6} align="stretch">
                    <HStack justify="space-between">

                        <Heading fontSize={titleSize} color={textColor}>
                            My Collection 🗃️
                        </Heading>

                        <Button
                            leftIcon={<FaPlus/>}
                            bg={accentColor}
                            color="white"
                            _hover={{bg: `${accentColor}80`}}
                            onClick={onCollectionModalOpen}
                        >
                            Create Collection
                        </Button>
                    </HStack>
                    <Text color={textColor} fontSize="md" mb={2}>
                        Welcome to your AI-Generated Image Repository! 🖼️🤖🗃️ Here you can store and manage all the
                        amazing images created by AI models. Whether you're showcasing your latest creations or
                        organizing inspiration for future projects, this is your personal gallery of AI artistry.
                        Explore, categorize, and rediscover your AI-generated masterpieces!
                    </Text>
                    <Flex>
                        <VStack align="stretch" width="250px" mr={5} spacing={3}>
                            {collections?.map((collection) => (
                                <CollectionFolder
                                    key={collection.id}
                                    collection={collection}
                                    isSelected={collection.id === selectedCollection}
                                    onClick={() => handleCollectionClick(collection.id)}
                                    onRefetch={() => refetch()}
                                />
                            ))}
                        </VStack>
                        <Box flex={1}>
                            {selectedCollection && (
                                <Box>
                                    <Heading as="h2" size="lg" mb={2} color={textColor}>
                                        {collections?.find(c => c.id === selectedCollection)?.name}
                                    </Heading>
                                    <Divider borderColor="gray.200" mb={4}/>

                                    {selectedProjects.length > 0 ? (
                                        <Grid templateColumns="repeat(auto-fill, minmax(150px, 1fr))" gap={4}>
                                            {selectedProjects.map((project) => (
                                                <BookMarkProjectCard
                                                    key={project.id}
                                                    project={project}
                                                    collectionId={selectedCollection}
                                                    onRefetch={() => refetch()}
                                                />
                                            ))}
                                        </Grid>
                                    ) : (
                                        <Text color={textColor}>이 컬렉션에는 프로젝트가 없습니다.</Text>
                                    )}
                                </Box>
                            )}
                        </Box>
                    </Flex>
                </VStack>
            </Container>
            <CreateCollectionSidebar isOpen={isCollectionModalOpen} onClose={onCollectionModalClose}/>

            {/* 여기에 새 컬렉션 생성을 위한 모달을 추가할 수 있습니다 */}
        </Box>
    );
}