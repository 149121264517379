import React from 'react';
import { Box, Flex, Text, IconButton, Image, useColorModeValue } from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons';
import { AIInputImage } from '../../interface/IMedias';
import { useThemeColors } from "../../lib/Color";
import { useRemoveInputImageFromSequence } from "../../hook/HSequence";

interface SequenceInputImageProps {
  image: AIInputImage;
  sequenceId: string;
  onRemove: () => void;
}

export const SequenceInputImage: React.FC<SequenceInputImageProps> = ({ image, sequenceId, onRemove }) => {
  const { bgColor, cardBgColor, textColor, borderColor } = useThemeColors();
  const removeImageMutation = useRemoveInputImageFromSequence();

  const handleRemove = async () => {
    if (!sequenceId) {
      console.error("Sequence ID is missing");
      return;
    }

    try {
      await removeImageMutation.mutateAsync({ sequence_id: sequenceId, inputImage_id: image.id});
      onRemove();
    } catch (error) {
      console.error("Failed to remove image", error);
    }
  };

  return (
    <Box
      borderWidth={1}
      borderRadius="lg"
      p={4}
      bg={bgColor}
      borderColor={borderColor}
      boxShadow="md"
      position="relative"
      width="100%"
      maxWidth="400px"
    >
      <IconButton
        aria-label="Remove image"
        icon={<CloseIcon />}
        size="sm"
        position="absolute"
        top={2}
        right={2}
        onClick={handleRemove}
        variant="ghost"
      />
      <Flex direction="column">
        <Image
          src={image.file}
          alt={`Input image ${image.id}`}
          objectFit="cover"
          height="200px"
          width="100%"
          borderRadius="md"
          mb={2}
        />
        <Text fontWeight="bold" mb={1} color={textColor}>
          Type: {image.image_type.replace(/[()'']/g, '')}
        </Text>
        <Text fontSize="sm" color={textColor}>
          Created: {new Date(image.created_at).toLocaleDateString()}
        </Text>
      </Flex>
    </Box>
  );
};