import React, { useState, useEffect } from 'react';
import { useDisclosure, Box, Image } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import Masonry from 'react-masonry-css';
// import { getAllProject } from "../api/AProject";
import ProjectModal from "../../components/ProjectModal";
import {project, Projects} from "../../interface/Iproject";
import {useALLProjects} from "../../hook/HProject";
import RoomSkeleton from '../../components/PinImageSkeleton';

const masonryStyles = `
.my-masonry-grid {
  display: flex;
  margin-left: -16px;
  width: auto;
}
.my-masonry-grid_column {
  padding-left: 16px;
  background-clip: padding-box;
}
.my-masonry-grid_column > div {
  margin-bottom: 16px;
}
`;

const ProjectPinImage: React.FC = () => {
    const [selectedProject, setSelectedProject] = useState<Projects | null>(null);
    const [imageHeights, setImageHeights] = useState<{ [key: number]: number }>({});
    const {
        isOpen: isProjectOpen,
        onClose: onProjectClose,
        onOpen: onProjectOpen,
    } = useDisclosure();

    const {data: data, error: error, isLoading: isLoading} = useALLProjects();

    // useEffect(() => {
    //     if (data) {
    //         const heights = data.reduce((acc, _, index) => {
    //             acc[index] = Math.floor(Math.random() * (400 - 150 + 1) + 150); // 200px to 400px
    //             return acc;
    //         }, {} as { [key: number]: number });
    //         setImageHeights(heights);
    //     }
    // }, [data]);

   const handleProjectClick = (project: Projects) => {
        setSelectedProject(project);
        onProjectOpen();
    };

    const breakpointColumnsObj = {
        default: 3,
        700: 3,
        500: 2
    };

    return (
        <Box>
            <style>{masonryStyles}</style>
            {isLoading ? (
                <RoomSkeleton />
            ) : (
                <Masonry
                    breakpointCols={breakpointColumnsObj}
                    className="my-masonry-grid"
                    columnClassName="my-masonry-grid_column"
                >
                    {data && data.map((project, index) => (
                        <Box
                            key={index}
                            onClick={() => handleProjectClick(project)}
                            cursor="pointer"
                            borderRadius="md"
                            overflow="hidden"
                            boxShadow="md"
                            transition="transform 0.2s"
                            _hover={{ transform: 'scale(1.02)' }}
                            height={`${imageHeights[index]}px`}
                        >
                            {project.response?.output_images && project.response.output_images.length > 0 && (
                                <Image
                                    src={project.response.output_images[0]}
                                    alt={`Project ${index}`}
                                    width="100%"
                                    height="100%"
                                    objectFit="cover"
                                />
                            )}
                        </Box>
                    ))}
                </Masonry>
            )}
            {selectedProject && (
                <ProjectModal isOpen={isProjectOpen} onClose={onProjectClose} projectData={selectedProject} />
            )}
        </Box>
    );
}

export default ProjectPinImage;