import {useThemeColors} from "../../../lib/Color";
import {useLikeProject} from "../../../hook/HProject";
import {useLikeTool} from "../../../hook/HAITool";
import React from "react";
import {SimpleGrid, Spinner, Tab, TabList, TabPanel, TabPanels, Tabs, Text, VStack} from "@chakra-ui/react";
import {Projects} from "../../../interface/Iproject";
import {ProjectCard} from "../ProjectCard";
import {ToolCard} from "../ToolCard";

export const LikePanel = ({data, onSettingsClick, sequence_id}: any) => {
    const {textColor} = useThemeColors();

    const {data: likedProjects, isLoading: isProjectLoading, error: projectError} = useLikeProject();
    const {data: likedModels, isLoading: isModelLoading, error: modelError} = useLikeTool();

    const renderContent = (items: any[], isLoading: boolean, error: any, emptyMessage: string, renderItem: (item: any) => React.ReactNode) => {
        if (isLoading) return <Spinner size="xl"/>;
        if (error) return <Text color="red.500">Error loading data: {error.message}</Text>;
        if (!items || items.length === 0) return <Text color={textColor}>{emptyMessage}</Text>;

        return (
            <SimpleGrid
                columns={{base: 1, sm: 1, md: 3}}
                spacing={{base: 2, sm: 3, md: 4}}
                width="100%"
            >
                {items.map(renderItem)}
            </SimpleGrid>
        );
    };

    return (
        <VStack align="stretch" spacing={0}>
            <Tabs colorScheme="pink" isFitted>
                <TabList mb={4}>
                    <Tab>Liked Projects</Tab>
                    <Tab>Liked Models</Tab>
                </TabList>
                <TabPanels>
                    <TabPanel>
                        {renderContent(
                            likedProjects || [],
                            isProjectLoading,
                            projectError,
                            "No liked projects found.",
                            (project: Projects) => (
                                <ProjectCard
                                    key={project.id}
                                    project={project}
                                    onSettingsClick={onSettingsClick}
                                    sequence_id={sequence_id}
                                />
                            )
                        )}
                    </TabPanel>
                    <TabPanel>
                        {renderContent(
                            likedModels || [],
                            isModelLoading,
                            modelError,
                            "No liked models found.",
                            (model: any) => (
                                // <Box></Box>
                                <ToolCard
                                    key={model.id}
                                    tool={model}
                                    onSettingsClick={onSettingsClick}
                                    sequence_id={sequence_id}
                                />
                            )
                        )}
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </VStack>
    );
};