import React, {useState} from "react";
import {Prompt} from "../../interface/IPrompts";
import {useDeletePromptItemMutation, useDeletePromptMutation, useUpdatePromptMutation} from "../../hook/HPrompt";
import {
    Box, Divider, Flex, Heading, IconButton, Text, Wrap, WrapItem, Tooltip, Badge, HStack, Input,
} from "@chakra-ui/react";
import {CloseIcon, AddIcon} from "@chakra-ui/icons";
import useCustomToast from "../../hook/HToast";
import {usePromptToSequence} from "../../hook/HSequence";

export const PromptCard: React.FC<{ prompt: Prompt }> = ({prompt}) => {
    const deletePromptMutation = useDeletePromptMutation();
    const deletePromptItemMutation = useDeletePromptItemMutation();
    const [newPrompt, setNewPrompt] = useState('');
    const {showToast} = useCustomToast();
    const updatePromptMutation = useUpdatePromptMutation();

    const handleDeletePromptItem = (promptIndex: number) => {
        deletePromptItemMutation.mutate({categoryId: prompt.id, promptIndex});
    };

    const handleDeleteAllPrompts = () => {
        deletePromptMutation.mutate(prompt.id);
    };
    const handleAddPrompt = () => {
        if (!newPrompt.trim()) return;

        updatePromptMutation.mutate(
            {
                id: prompt.id,
                promptData: {
                    category_type: prompt.category?.category_type || 'CUSTOM',
                    customName: prompt.category?.customName || undefined,
                    content: newPrompt.trim(),  // 새로운 프롬프트만 전송
                }
            },
            {
                onSuccess: () => {
                    setNewPrompt('');
                    showToast("새 프롬프트가 추가되었습니다", "success");
                },
                onError: () => showToast("프롬프트 추가에 실패했습니다", "error")
            }
        );
    };

    return (
        <Box borderWidth={1} borderRadius="lg" p={4} position="relative">
            <Tooltip label="Delete all prompts" placement="top">
                <IconButton
                    aria-label="Delete all prompts"
                    icon={<CloseIcon/>}
                    size="sm"
                    position="absolute"
                    top={2}
                    right={2}
                    onClick={handleDeleteAllPrompts}
                    variant="ghost"
                    colorScheme="gray"
                />
            </Tooltip>
            <Flex>
                <Flex width="30%" alignItems="center" justifyContent="start">
                    <Heading size="md" textAlign="left">
                        {prompt.category?.customName || "Unnamed Category"}
                    </Heading>
                </Flex>
                <Box width="70%">
                    <Wrap spacing={2}>
                        {prompt.content.map((item: string, index: number) => (
                            <WrapItem key={index}>
                                <Box
                                    borderWidth={1}
                                    borderColor="grey"
                                    borderRadius="24px"
                                    px={3}
                                    py={1}
                                    display="flex"
                                    alignItems="center"
                                >
                                    <Text mr={2}>{item}</Text>
                                    <Tooltip label="Delete prompt" placement="top">
                                        <IconButton
                                            aria-label="Delete prompt item"
                                            icon={<CloseIcon/>}
                                            size="xs"
                                            onClick={() => handleDeletePromptItem(index)}
                                            variant="ghost"
                                            colorScheme="gray"
                                            ml={1}
                                        />
                                    </Tooltip>
                                </Box>
                            </WrapItem>
                        ))}
                    </Wrap>

                    <HStack mt={4}>
                        <Input
                            value={newPrompt}
                            onChange={(e) => setNewPrompt(e.target.value)}
                            placeholder="Add a new prompt..."
                        />
                        <IconButton
                            aria-label="Add prompt"
                            icon={<AddIcon/>}
                            onClick={handleAddPrompt}
                            isLoading={updatePromptMutation.isPending}
                        />
                    </HStack>
                </Box>
            </Flex>
        </Box>
    );
};