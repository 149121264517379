import React, {useEffect, useState} from "react";
import {
    Drawer,
    DrawerBody,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    Box,
    Flex,
    Text,
    Image,
    Button,
    VStack,
    HStack,
    Tooltip,
    useColorModeValue,
    Icon,
    Badge,
    AspectRatio,
    SimpleGrid, useDisclosure
} from "@chakra-ui/react";
import {FaHeart, FaDownload, FaRocket, FaShareAlt} from "react-icons/fa";
import {useAllTool, useTool, useToolLike} from "../hook/HAITool";
import {AITool} from "../interface/IAITool";
import {useThemeColors} from "../lib/Color";
import {SidebarWidth} from "../lib/ResponsiveValue";
import {useNavigate} from "react-router-dom";
import {useALLProjects} from "../hook/HProject";
import {Projects} from "../interface/Iproject";
import ProjectModal from "./ProjectModal";

interface ModelDrawerProps {
    isOpen: boolean;
    onClose: () => void;
    modelData: AITool;
}

export default function ModelDrawer({isOpen, onClose, modelData}: ModelDrawerProps) {
    const navigate = useNavigate();
    const borderColor = useColorModeValue("gray.200", "gray.700");
    const {bgColor, textColor, accentColor, cardBgColor} = useThemeColors();

    const {data: getModel, refetch: refetchModel} = useTool(modelData?.id);
    // const {data: getAllModel, refetch: refetchAllModel} = useAllTool();
    const {mutate: likeModel, data: likeData} = useToolLike(modelData?.id);
    // const [isLiked, setIsLiked] = useState(false);
    const {data: projectData, error: projecterror, isLoading: projectisLoading} = useALLProjects();
    const [relatedProjects, setRelatedProjects] = useState<Projects[]>([]);
    const {isOpen: isProjectOpen, onClose: onProjectClose, onOpen: onProjectOpen} = useDisclosure();
    const [selectedProject, setSelectedProject] = useState<Projects | null>(null);

    useEffect(() => {
        if (projectData && modelData) {
            const filteredProjects = projectData.filter(
                (project: Projects) => project.tool.id === modelData.id
            );
            setRelatedProjects(filteredProjects);
        }
    }, [projectData, modelData]);


    const handleLike = () => {
        if (modelData?.id) {
            likeModel(undefined, {
                onSuccess: () => {
                    refetchModel();
                },
            });
        }
    };
    const handleRemix = () => {
        const modelID = modelData?.id; // 프로젝트 ID 가져오기
        navigate(`/generateai?ToolId=${modelID}`);
    };
    const handleProjectClick = (project: Projects) => {
        setSelectedProject(project);
        onProjectOpen();
    };
    // useEffect(() => {
    //     if (modelData?.id) {
    //         refetchModel();
    //     }
    // }, [modelData?.id, refetchModel]);

    const getBadgeColor = (modelType: string) => {
        switch (modelType.toLowerCase()) {
            case 'checkpoint':
                return 'pink';
            case 'lora':
                return 'blue';
            case 'textual inversion':
                return 'purple';
            case 'controlnet':
                return 'orange';
            default:
                return 'gray';
        }
    };

    // const currentModelData = getModel || modelData;


    return (
        <Drawer onClose={onClose} isOpen={isOpen} size="md">
            <DrawerOverlay/>
            <DrawerContent maxW={SidebarWidth} bg={bgColor} color={textColor}>
                <DrawerCloseButton/>
                <DrawerHeader borderBottomWidth="1px" borderColor={borderColor} mt={8}>
                    <Flex justify="space-between" align="center">
                        <Text fontSize="xl"
                              fontWeight="bold">{getModel?.ai_model || modelData?.ai_model || "Model Details"}</Text>
                        <Badge
                            colorScheme={getBadgeColor(getModel?.ai_app || modelData?.ai_app)}
                            fontSize="0.8em"
                            px={4}
                        >
                            {getModel?.ai_app || modelData?.ai_app}
                        </Badge>
                    </Flex>
                </DrawerHeader>
                <DrawerBody>
                    <VStack spacing={4} align="stretch">
                        <AspectRatio ratio={16 / 9} maxH="200px">
                            <Image
                                src={getModel?.file?.file || modelData?.file?.file || "https://via.placeholder.com/400x300"}
                                alt={getModel?.ai_model || modelData?.ai_model}
                                objectFit="cover"
                                borderRadius="md"
                            />
                        </AspectRatio>
                        <HStack justify="space-between">
                            <Button
                                leftIcon={<Icon as={FaHeart} color={getModel?.liked ? "red.500" : "gray.400"}/>}
                                variant="ghost"
                                onClick={handleLike}
                                size="sm"
                            >
                                {getModel?.like || 0}
                            </Button>
                        </HStack>
                        <Button
                            leftIcon={<FaRocket/>}
                            variant="outline"
                            size="md"
                            color={accentColor}
                            borderColor={accentColor}
                            _hover={{bg: `${accentColor}60`}}
                            onClick={handleRemix}
                        >
                            Remix
                        </Button>
                        {/* Project Gallery */}
                        <Box mt={6}>
                            <Text fontSize="md" fontWeight="semibold" mb={2}>Related Projects</Text>

                            <SimpleGrid columns={2} spacing={4}>
                                {relatedProjects.map((project: any) => (
                                    <Box
                                        key={project.id}
                                        bg={cardBgColor}
                                        borderRadius="md"
                                        overflow="hidden"
                                        onClick={() => handleProjectClick(project)}
                                        cursor="pointer"
                                    >
                                        <Image
                                            src={project?.response?.output_images[0]}
                                            alt={project.title}
                                            objectFit="cover"
                                            width="100%"
                                            height="100px"
                                        />
                                    </Box>
                                ))}
                            </SimpleGrid>
                        </Box>
                    </VStack>
                </DrawerBody>
                {selectedProject && (
                    <ProjectModal
                        isOpen={isProjectOpen}
                        onClose={onProjectClose}
                        projectData={selectedProject}
                    />
                )}
            </DrawerContent>
        </Drawer>
    );
}