import React from 'react';
import {FaComment, FaGithub, FaGoogle} from "react-icons/fa";
import {Box, Button, Divider, HStack, Text, VStack} from "@chakra-ui/react";

const BASE_FRONTEND_URL = process.env.NODE_ENV === "development"
        ? "http://127.0.0.1:3000"
        : "https://motive.beauty";

export default function SocialLogin() {


    const kakaoParams = {
        client_id: "7dad815f199ff9c3dfa613ff088c0684",
        redirect_uri: `${BASE_FRONTEND_URL}/social/kakao`,
        response_type: "code",
    };

    const githubParams = {
        client_id: "Ov23li5ijWX9cZrra7JS",
        scope: "read:user,user:email",
    };
    // redirect_uri: "http://127.0.0.1:3000/social/google",

    const googleParams = {
        client_id: "138356221416-s9jvk0e9o3m0u0cn1echf8u7svj014pp.apps.googleusercontent.com",
        redirect_uri: `${BASE_FRONTEND_URL}/social/google`,
        response_type: "code",
        scope: "https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile"
    };

    const kakaoUrl = `https://kauth.kakao.com/oauth/authorize?${new URLSearchParams(kakaoParams).toString()}`;
    const githubUrl = `https://github.com/login/oauth/authorize?${new URLSearchParams(githubParams).toString()}`;
    const googleUrl = `https://accounts.google.com/o/oauth2/v2/auth?${new URLSearchParams(googleParams).toString()}`;

    const handleSocialLogin = (url: string) => (e: React.MouseEvent) => {
        e.preventDefault();
        // 소셜 로그인 URL로 리다이렉트
        window.location.href = url;
        // 참고: 실제 로그인 성공은 리다이렉트 후 처리되므로, 여기서 onLoginSuccess를 직접 호출하지 않습니다.
        // onLoginSuccess 호출은 리다이렉트 후 처리 로직에서 수행해야 합니다.
    };

    return (
        <Box mb={4}>
            <Divider mb={6}/>

            <VStack>
                <Button
                    as="a"
                    href={githubUrl}
                    w="100%"
                    leftIcon={<FaGithub/>}
                    onClick={handleSocialLogin(githubUrl)}
                >
                    Continue with Github
                </Button>
                <Button
                    mt={1}
                    as="a"
                    href={kakaoUrl}
                    w="100%"
                    leftIcon={<FaComment/>}
                    colorScheme={"yellow"}
                    onClick={handleSocialLogin(kakaoUrl)}
                >
                    Continue with Kakao
                </Button>
                <Button
                    mt={1}
                    as="a"
                    href={googleUrl}
                    w="100%"
                    leftIcon={<FaGoogle/>}
                    bg="white"
                    color="rgba(0,0,0,0.54)"
                    borderWidth={1}
                    borderColor="gray.200"
                    _hover={{bg: "gray.50"}}
                    onClick={handleSocialLogin(googleUrl)}
                >
                    Continue with Google
                </Button>
            </VStack>
        </Box>
    );
}