import {useQuery} from "@tanstack/react-query";
import {
    addMemoToSequence, editMemoInSequence,
    getAllSequence,
    getSequenceDetail, postAddInputImageToSequence,
    postAddProjectToSequence, postAddPromptToSequence,
    postAddToolToSequence, promptToSequence, removeInputImageFromSequence, removeMemoFromSequence,
    removeProjectFromSequence, removePromptFromSequence, removeToolFromSequence, reorderItems
} from "../api/ASequence";
import {useMutation, useQueryClient} from '@tanstack/react-query';
import {postCreateSequence} from '../api/ASequence';
import {Sequence} from '../interface/ISequence';
import {getProject} from "../api/AProject";


interface CreateSequenceData {
    name: string;
    description: string;
}

interface UseCreateSequenceMutationProps {
    onSuccess?: () => void;
}

export const useAllSequence = (userData: any) => {
    return useQuery({
        queryKey: ['getAllSequence'],
        queryFn: getAllSequence,
        enabled: !!userData,

    });
};


export const useSequenceDetail = (SequenceId: string | any) => {
    return useQuery({
        queryKey: ['getSequenceDetail', SequenceId],
        queryFn: () => getSequenceDetail(SequenceId),
        enabled: !!SequenceId,
    });
};


export const useCreateSequenceMutation = ({onSuccess}: UseCreateSequenceMutationProps = {}) => {
    const queryClient = useQueryClient();

    return useMutation<Sequence, Error, CreateSequenceData>({
        mutationFn: postCreateSequence,
        onSuccess: (data) => {
            queryClient.invalidateQueries({queryKey: ['getAllSequence']});
            if (onSuccess) {
                onSuccess();
            }
        },
    });
};


export const useAddProjectToSequence = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: postAddProjectToSequence,
        onSuccess: (data, variables) => {
            queryClient.invalidateQueries({queryKey: ['getSequenceDetail', variables.sequence_id]});
        },
    });
};

export const useAddToolToSequence = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: postAddToolToSequence,
        onSuccess: (data, variables) => {
            queryClient.invalidateQueries({queryKey: ['getSequenceDetail', variables.sequence_id]});
        },
    });
};


export const useAddPromptToSequence = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: postAddPromptToSequence,
        onSuccess: (data, variables) => {
            queryClient.invalidateQueries({queryKey: ['getSequenceDetail', variables.sequence_id]});
            queryClient.invalidateQueries({queryKey: ['prompts']});
        },
    });
};

export const useAddInputImageToSequence = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: postAddInputImageToSequence,
        onSuccess: (data, variables) => {
            queryClient.invalidateQueries({queryKey: ['getSequenceDetail', variables.sequence_id]});
        },
    });
};

export const useRemoveProjectFromSequence = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: removeProjectFromSequence,
        onSuccess: (data, variables) => {
            // 성공 시 관련 쿼리 무효화
            queryClient.invalidateQueries({queryKey: ['getSequenceDetail', variables.sequence_id]});
        },
    });
};


export const useRemoveToolFromSequence = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: removeToolFromSequence,
        onSuccess: (data, variables) => {
            // 성공 시 관련 쿼리 무효화
            queryClient.invalidateQueries({queryKey: ['getSequenceDetail', variables.sequence_id]});
        },
    });
};


export const useRemovePromptFromSequence = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: removePromptFromSequence,
        onSuccess: (data, variables) => {
            // 성공 시 관련 쿼리 무효화
            queryClient.invalidateQueries({queryKey: ['getSequenceDetail', variables.sequence_id]});
        },
    });
};


export const useRemoveInputImageFromSequence = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: removeInputImageFromSequence,
        onSuccess: (data, variables) => {
            // 성공 시 관련 쿼리 무효화
            queryClient.invalidateQueries({queryKey: ['getSequenceDetail', variables.sequence_id]});
        },
    });
};


export const useAddMemoToSequence = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: addMemoToSequence,
        onSuccess: (data, variables) => {
            queryClient.invalidateQueries({queryKey: ['getSequenceDetail', variables.sequence_id]});
        },
    });
};

export const useRemoveMemoFromSequence = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: removeMemoFromSequence,
        onSuccess: (data, variables) => {
            queryClient.invalidateQueries({queryKey: ['getSequenceDetail', variables.sequence_id]});
        },
    });
};

export const useEditMemo = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: editMemoInSequence,
        onSuccess: (data, variables) => {
            queryClient.invalidateQueries({queryKey: ['getSequenceDetail', variables.sequence_id]});
        },
    });
};


export const useReorderItems = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: reorderItems,
        onSuccess: (data, variables) => {
            queryClient.invalidateQueries({queryKey: ['getSequenceDetail', variables.sequence_id]});
        },
    });
};

// 커스텀 Hook으로 변경
export const usePromptToSequence = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: promptToSequence,
        onSuccess: (data, variables) => {
            queryClient.invalidateQueries({queryKey: ['getSequenceDetail', variables.sequence_id]});
            queryClient.invalidateQueries({queryKey: ['prompts']});

        },
    });
};
