import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import {ChakraProvider, ColorModeScript} from "@chakra-ui/react";
import {RouterProvider} from "react-router-dom";
import router from './router';
import theme from "./theme";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import AppLayout from './AppLayout';
import {I18nextProvider} from 'react-i18next';
import i18n from './i18n';  // i18n 설정 파일을 import

const client = new QueryClient();  // AppLayout 컴포넌트를 여기로 이동


const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);


root.render(
    <React.StrictMode>
        <Suspense fallback="loading">
            <I18nextProvider i18n={i18n}>
                <QueryClientProvider client={client}>
                    <ChakraProvider theme={theme}>
                        <ColorModeScript initialColorMode={theme.config.initialColorMode}/>
                        {/*<RouterProvider router={router} />*/}
                        {/*<AppLayout>*/}
                        <RouterProvider router={router}/>
                        {/*</AppLayout>*/}
                    </ChakraProvider>
                </QueryClientProvider>
            </I18nextProvider>
        </Suspense>
    </React.StrictMode>
);

