import Cookie from "js-cookie";
import {instance, getCSRFHeader} from "./Abase";
import {QueryFunctionContext} from "@tanstack/react-query";
import axios from 'axios';


export const getAIInput_images = ({queryKey}: QueryFunctionContext) => {
    const [_, username] = queryKey;
    // console.log(username)
    return instance
        .get(`medias/photos/${username}/input-images`)
        .then((response) => response.data);
};


export const getUploadURL = () =>
    instance
        .post(`medias/photos/get-url`, null, {
            headers: getCSRFHeader()

        })
        .then((response) => {
            console.log("Upload URL received:", response.data);
            return response.data;
        })
        .catch((error) => {
            console.error("Error getting upload URL:", error);
            throw error;
        });

export const ImageUploadOnlyRequest = ({
                                           file,
                                           id,
                                           image_type,
                                           CF_id,
                                       }: ImageUploadOnlyVariables) =>
    instance
        .post(
            `medias/photos/${id}`,
            {file, id, image_type, CF_id},
            {
                headers: getCSRFHeader()
            }
        )
        .then((response) => response.data)
        .catch((error) => {
            console.error('Error saving image details:', error);
            throw error;
        });

export const uploadImage = async ({file, uploadURL}: { file: File, uploadURL: string }) => {
    console.log("Uploading image to Cloudflare", {fileName: file.name, fileSize: file.size, fileType: file.type});
    const form = new FormData();
    form.append("file", file);

    try {
        const response = await axios.post(uploadURL, form, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        console.log("Image uploaded to Cloudflare successfully:", response.data);
        return response.data;
    } catch (error) {
        console.error("Error uploading image to Cloudflare:", error);
        throw error;
    }
};
// export const uploadImage = ({file, uploadURL}: IUploadImageVarialbes) => {
//     const form = new FormData();
//     form.append("file", file[0]);
//     return instance
//         .post(uploadURL, form, {
//             headers: getCSRFHeader()
//
//         })
//         .then((response) => {
//             console.log("Upload URL received:", response.data);
//             return response.data;
//         })
//         .catch((error) => {
//             console.error("Error uploading image to Cloudflare:", error);
//             throw error;
//         });
//
// };

export interface ImageUploadOnlyVariables {
    file: string;
    id: string;
    image_type: number;
    CF_id: any;
}

export interface IUploadImageVarialbes {
    file: FileList;
    uploadURL: string;
}

// export const deleteAIInputImage = (imageId: string) =>
//     instance.delete<void>(`medias/photos/${imageId}`, {
//         headers: getCSRFHeader(),
//     }).then((response) => response.data)
//         .catch((error) => {
//             // You can add more specific error handling here if needed
//             console.error('Error deleting AI input image:', error);
//             throw error;
//         });



export const deleteAIInputImage = ( imageId: string) =>
    instance.delete<void>(`medias/photos/input-images/${imageId}`, {
        headers: getCSRFHeader(),
    }).then((response) => response.data)
        .catch((error) => {
            console.error('Error deleting AI input image:', error);
            throw error;
        });