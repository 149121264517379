import React, {useState, useEffect, useMemo} from "react";
import {
    Box,
    SimpleGrid,
    Text,
    Image,
    Heading,
    AspectRatio,
    useBreakpointValue,
    Skeleton,
    SkeletonText,
    Container,
    Input,
    InputGroup,
    InputLeftElement,
    VStack,
    Wrap,
    WrapItem,
    Tag,
    useColorModeValue,
} from '@chakra-ui/react';
import {FaSearch} from "react-icons/fa";
import PinImage from "../components/PinImage";
import {useThemeColors} from '../lib/Color';
import {containerMaxWidth, containerPadding, titleSize} from "../lib/ResponsiveValue";

// Mock data for community posts
const communityPosts = [
    {id: 1, title: "Amazing AI Art 🎨", description: "Check out this stunning AI-generated artwork!"},
    {id: 2, title: "New Language Model 🤖", description: "Discussing the latest breakthroughs in NLP"},
    {id: 3, title: "AI Ethics Debate 🧠", description: "Join our discussion on the ethical implications of AI"},
    // Add more mock posts as needed
];

const tags = [
    {name: "All", emoji: "🌟"},
    {name: "Architecture", emoji: "🎨"},
    {name: "Technology", emoji: "💻"},
    {name: "Modern", emoji: "🤔"},
    {name: "Research", emoji: "🔬"},
];

export default function Community() {
    const [searchTerm, setSearchTerm] = useState("");
    const [selectedTag, setSelectedTag] = useState("All");
    const {bgColor, textColor, accentColor} = useThemeColors();
    const columns = useBreakpointValue({base: 1, md: 2, lg: 3});


    return (
        <Box bg={bgColor} minHeight="100vh" py={6} mt={12}>
            <Container maxW={containerMaxWidth} px={containerPadding}>
                <VStack spacing={6} align="stretch">
                    <Heading textAlign="left" fontSize={titleSize} color={textColor}>
                        Community Hub 🌍
                    </Heading>
                    <InputGroup>
                        <InputLeftElement pointerEvents="none">
                            <FaSearch color={textColor}/>
                        </InputLeftElement>
                        <Input
                            placeholder="Search community posts..."
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            color={textColor}
                            colorScheme="pink"
                        />
                    </InputGroup>

                    <Wrap spacing={2} mb={4}>
                        {tags.map((tag) => (
                            <WrapItem key={tag.name}>
                                <Tag
                                    size="md"
                                    variant={selectedTag === tag.name ? "white" : accentColor}
                                    bg={selectedTag === tag.name ? accentColor : "transparent"}

                                    cursor="pointer"
                                    onClick={() => setSelectedTag(tag.name)}
                                    sx={{
                                        borderWidth: "1px",
                                        borderStyle: "solid",
                                        borderColor: accentColor,
                                        transition: "all 0.2s",
                                        _hover: {
                                            bg: selectedTag === tag.name ? accentColor : `${accentColor}30`,
                                        },
                                        _focus: {
                                            boxShadow: "outline",
                                        },
                                    }}
                                >
                                    {tag.emoji} {tag.name}
                                </Tag>
                            </WrapItem>
                        ))}
                    </Wrap>


                    <Box width="100%">
                        {/*<Heading as="h2" size="lg" mb={4} color={textColor}>*/}
                        {/*    Featured Content 🌟*/}
                        {/*</Heading>*/}
                        {/*<PinImage/>*/}
                        <PinImage searchTerm={searchTerm} selectedTag={selectedTag}/>

                    </Box>
                </VStack>
            </Container>
        </Box>
    );
}