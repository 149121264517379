import React from "react";
import { Box, Text, IconButton, VStack, HStack, Badge } from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons";
import { useThemeColors } from "../../lib/Color";
import { useRemoveToolFromSequence } from "../../hook/HSequence";
import {AITool} from "../../interface/IAITool"

interface SequenceToolProps {
    tool: AITool;
    sequenceId: string | undefined;
    onRemove: () => void;
}

export const SequenceTool: React.FC<SequenceToolProps> = ({ tool, sequenceId, onRemove }) => {
    const { cardBgColor, textColor } = useThemeColors();
    const removeToolMutation = useRemoveToolFromSequence();

    const handleRemove = async () => {
        if (!sequenceId) {
            console.error("Sequence ID is missing");
            return;
        }

        try {
            await removeToolMutation.mutateAsync({ sequence_id: sequenceId, tool_id: tool.id });
            onRemove();
        } catch (error) {
            console.error("Failed to remove tool", error);
        }
    };

    return (
        <Box
            bg={cardBgColor}
            borderRadius="lg"
            overflow="hidden"
            boxShadow="md"
            p={4}
            width="250px"
            height="200px"
            position="relative"
        >
            <IconButton
                aria-label="Remove tool"
                icon={<CloseIcon />}
                size="sm"
                position="absolute"
                top={2}
                right={2}
                onClick={handleRemove}
            />
            <VStack align="start" spacing={3}>
                <Text fontWeight="bold" color={textColor}>{tool.ai_model}</Text>
                {/*<Text color={textColor} fontSize="sm" noOfLines={3}>{tool.description}</Text>*/}
                <HStack>
                    <Badge colorScheme="purple">AI Tool</Badge>
                    {/* Add more badges if needed */}
                </HStack>
            </VStack>
        </Box>
    );
};