// import React from 'react';
// import { Box, Flex } from "@chakra-ui/react";
// import { Outlet } from "react-router-dom";
// import Header from "./Header";
// import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
//
// export default function Root() {
//   const [isPinned, setIsPinned] = React.useState(false);
//
//   return (
//     <Flex height="100vh">
//       <Header onPinChange={setIsPinned} />
//       <Box flex={1} ml={isPinned ? "250px" : "60px"} transition="margin-left 0.3s">
//         <Box pt="60px" height="calc(100vh - 60px)" overflow="auto">
//           <Outlet />
//         </Box>
//       </Box>
//       <ReactQueryDevtools initialIsOpen={false} />
//     </Flex>
//   );
// }

import React from 'react';
import { Box, Flex } from "@chakra-ui/react";
import { Outlet } from "react-router-dom";
import Header from "./Header";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { useIsMobile } from "../lib/ResponsiveValue";

export default function Root() {
  const [isPinned, setIsPinned] = React.useState(false);
  const isMobile = useIsMobile();

  if (isMobile) {
    return (
      <Flex direction="column" height="100vh">
        <Box flex={1} overflow="auto">
          <Outlet />
        </Box>
        <Header onPinChange={setIsPinned} />
      </Flex>
    );
  }

  return (
    <Flex height="100vh">
      <Header onPinChange={setIsPinned} />
      <Box
        flex={1}
        ml={isPinned ? "250px" : "60px"}
        transition="margin-left 0.3s"
        overflow="auto"
      >
        <Outlet />
      </Box>
      <ReactQueryDevtools initialIsOpen={false} />
    </Flex>
  );
}