import { useQuery } from '@tanstack/react-query';
import {useMutation, useQueryClient} from '@tanstack/react-query';
import {getAIStyle_Controlnet} from "../api/AStyle";


export const useGetAIStyle_Controlnet = () => {
  return useQuery({
    queryKey: ['getAiStyle_Controlnet'],
    queryFn: getAIStyle_Controlnet,
  });
};


