import React, {useEffect, useState, useRef} from "react";
import {
    Drawer,
    DrawerBody,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    Box,
    Flex,
    Text,
    Image,
    Button,
    VStack,
    HStack,
    Switch,
    Tooltip,
    Tabs,
    TabList,
    TabPanels,
    Tab,
    TabPanel,
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverBody,
    PopoverArrow,
    useDisclosure,
    Badge, SimpleGrid

} from "@chakra-ui/react";
import {FaHeart, FaShareAlt, FaPlus} from "react-icons/fa";
import {useProject, useProjectLike, useUpdateProjectShared} from "../hook/HProject";
import {useCollections, useAddProjectToCollection} from "../hook/HCollection";
import useUser from "../hook/useUser";
import {useThemeColors} from '../lib/Color';
import {ProjectModalSidebarWidth, SidebarWidth} from "../lib/ResponsiveValue";
import CollectionComponent from "./CollectionModal";
import {ReactCompareSlider, ReactCompareSliderImage} from 'react-compare-slider';
import {useQueryClient} from '@tanstack/react-query';
import {useNavigate} from "react-router-dom";


const ProjectContent = ({projectData}: any) => {
    const navigate = useNavigate();
    const hasRequestImage = projectData?.request?.file;
    const responseImages = projectData?.response?.output_images || [];
    const hasResponseImage = responseImages.length > 0;
    const {bgColor, textColor, borderColor, cardBgColor, accentColor} = useThemeColors();
    const [selectedImageIndex, setSelectedImageIndex] = useState(0);

    useEffect(() => {
        console.log(projectData)
    }, []);
    const handleRemix = () => {
        const projectId = projectData?.id;
        navigate(`/generateai?Id=${projectId}`);
    };

    const handleImageClick = (index: number) => {
        setSelectedImageIndex(index);
    };

    const renderMainImage = () => {
        if (hasRequestImage && hasResponseImage && selectedImageIndex === 0) {
            return (
                <ReactCompareSlider
                    itemOne={
                        <ReactCompareSliderImage
                            src={projectData.request.file.file}
                            alt="Request Image"
                        />
                    }
                    itemTwo={
                        <ReactCompareSliderImage
                            src={responseImages[0]}
                            alt="Response Image"
                        />
                    }
                    position={50}
                    style={{
                        height: '100%',
                        borderRadius: '8px',
                        overflow: 'hidden',
                    }}
                />
            );
        } else if (hasResponseImage) {
            return (
                <Image
                    src={responseImages[selectedImageIndex]}
                    alt={`Response Image ${selectedImageIndex + 1}`}
                    objectFit="cover"
                    width="100%"
                    height="100%"
                    borderRadius="md"
                />
            );
        } else {
            return (
                <Flex height="100%" align="center" justify="center" bg={cardBgColor} borderRadius="md">
                    <Text color="gray.500">No images available</Text>
                </Flex>
            );
        }
    };

    const renderGallery = () => {
        if (responseImages.length > 1) {
            return (
                <HStack spacing={2} overflowX="auto" py={2}>
                    {responseImages.map((image: any, index: number) => (
                        <Image
                            key={index}
                            src={image}
                            alt={`Response Image ${index + 1}`}
                            objectFit="cover"
                            width="100px"
                            height="100px"
                            borderRadius="md"
                            cursor="pointer"
                            onClick={() => handleImageClick(index)}
                            border={index === selectedImageIndex ? `2px solid ${accentColor}` : 'none'}
                        />
                    ))}
                </HStack>
            );
        }
        return null;
    };

    return (
        <VStack spacing={6} align="stretch">
            <Box width="100%" height="350px">
                {renderMainImage()}
            </Box>
            {renderGallery()}
            <VStack spacing={6} align="stretch">
                <Box>
                    <Flex justify="space-between" align="center" mb={2}>
                        <Text fontSize="sm">Prompt</Text>
                        <Button
                            leftIcon={<FaShareAlt/>}
                            variant="outline"
                            size="sm"
                            color={accentColor}
                            borderColor={accentColor}
                            _hover={{bg: `${accentColor}60`}}
                            onClick={handleRemix}
                        >
                            Remix
                        </Button>
                    </Flex>
                    <Box bg={cardBgColor} p={4} borderRadius="md" boxShadow="sm">
                        <Text>{projectData?.request?.prompt || "No prompt available"}</Text>
                    </Box>
                </Box>
                <Box>
                    <Text fontSize="sm" mb={2}>Negative Prompt</Text>
                    <Box bg={cardBgColor} p={4} borderRadius="md" boxShadow="sm">
                        <Text>{projectData?.request?.negativePrompt || "No negative prompt"}</Text>
                    </Box>
                </Box>

                {projectData?.request?.controlnet && projectData.request.controlnet.length > 0 && (
                    <VStack align="stretch" spacing={2} pb={0}>
                        <Text fontSize="sm" fontWeight="medium">ControlNet:</Text>
                        {projectData.request.controlnet.map((control: any, index: any) => (
                            <Badge key={index} variant="outline" p={2} borderRadius="md">
                                <HStack justify="space-between">
                                    <Text fontSize="sm">{control.type}</Text>
                                </HStack>
                            </Badge>
                        ))}
                    </VStack>
                )}
                <SimpleGrid columns={2} spacing={4} pb={6}>
                    <Badge variant="outline" p={1} borderRadius="md" textAlign="center" colorScheme="red">
                        <Text fontSize="sm" fontWeight="medium">CFG: {projectData?.request?.cfg}</Text>
                    </Badge>
                    <Badge variant="outline" p={1} borderRadius="md" textAlign="center" colorScheme="blue">
                        <Text fontSize="sm" fontWeight="medium">
                            Size: {projectData?.request?.width}x{projectData?.request?.height}
                        </Text>
                    </Badge>
                    <Badge variant="outline" p={1} borderRadius="md" textAlign="center" colorScheme="green">
                        <Text fontSize="sm" fontWeight="medium">Steps: {projectData?.request?.steps}</Text>
                    </Badge>
                    <Badge variant="outline" p={1} borderRadius="md" textAlign="center" colorScheme="yellow">
                        <Text fontSize="sm" fontWeight="medium">{projectData?.tool?.ai_model}</Text>
                    </Badge>
                </SimpleGrid>


            </VStack>
        </VStack>
    );
};


export default function ProjectDrawer({isOpen, onClose, projectData}: any) {
    const {user} = useUser();
    const currentUserId = user?.id;
    const {bgColor, textColor, borderColor, cardBgColor, accentColor} = useThemeColors();

    const {data: getproject, refetch: refetchProject} = useProject(projectData?.id);
    const isOwner = projectData?.owner === currentUserId;
    const {mutate: likeProject} = useProjectLike();
    const {mutate: addProjectToCollection} = useAddProjectToCollection();
    const {mutate: updateProject} = useUpdateProjectShared();

    const {data: collections} = useCollections(user?.id);
    const queryClient = useQueryClient();

    const handleLike = () => {
        if (getproject?.id) {
            likeProject(getproject.id, {
                onSuccess: () => {
                    // 프로젝트 데이터 갱신
                    refetchProject();
                }
            });
        }
    };
    const handleShareToggle = () => {
        if (isOwner && getproject?.id) {
            const newSharedStatus = !getproject.shared;
            updateProject(
                {id: getproject.id, shared: newSharedStatus},
                {
                    onSuccess: () => {
                        refetchProject();
                        projectData.shared = newSharedStatus;

                    }
                }
            );
        }
    };

    useEffect(() => {
        if (projectData?.id) {
            refetchProject();
            queryClient.invalidateQueries({queryKey: ['userProjects']});
            queryClient.invalidateQueries({queryKey: ['allprojects']});
            queryClient.invalidateQueries({queryKey: ['Likeproject']});
        }
    }, [projectData?.id, refetchProject, queryClient]);

    useEffect(() => {
        if (projectData?.id) {
            refetchProject();
        }
    }, [projectData?.id, refetchProject]);

    const [isCollectionOpen, setIsCollectionOpen] = useState(false);
    const collectionRef = useRef<HTMLDivElement>(null);


    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if (collectionRef.current && !collectionRef.current.contains(event.target as Node)) {
                setIsCollectionOpen(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);


    return (
        <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="md">
            <DrawerOverlay/>
            <DrawerContent maxW={ProjectModalSidebarWidth} bg={bgColor} color={textColor}>
                <DrawerCloseButton/>
                <DrawerHeader borderBottomWidth="1px" borderColor={borderColor}>
                    <Flex justify="space-between" align="center" mt={6}>
                        <Text
                            fontSize="2xl"
                            fontWeight="bold"
                            overflow="hidden"
                            textOverflow="ellipsis"
                            whiteSpace="nowrap"
                            width="100%"
                        >
                            {projectData?.title || "Project Details"}
                        </Text>
                        <HStack spacing={2}>

                            <Box position="relative" ref={collectionRef}>
                                <Button
                                    leftIcon={<FaPlus/>}
                                    variant="ghost"
                                    size="sm"
                                    onClick={() => setIsCollectionOpen(!isCollectionOpen)}
                                />
                                {isCollectionOpen && (
                                    <Box
                                        position="absolute"
                                        top="100%"
                                        right="0"
                                        mt={2}
                                        zIndex={1}
                                        bg={bgColor}
                                        borderColor={borderColor}
                                        borderWidth="1px"
                                        borderRadius="md"
                                        boxShadow="md"
                                        p={4}
                                    >
                                        <CollectionComponent
                                            projectId={projectData?.id}
                                            onClose={() => setIsCollectionOpen(false)}
                                        />
                                    </Box>
                                )}
                            </Box>


                            <Button
                                leftIcon={<FaHeart/>}
                                variant="ghost"
                                size="sm"
                                color={accentColor}
                                onClick={handleLike}
                            >
                                {getproject?.like || 0}
                            </Button>
                            <Switch
                                isChecked={projectData?.shared}
                                onChange={handleShareToggle}
                                isDisabled={!isOwner}
                                size="md"
                                colorScheme="pink"
                            />
                        </HStack>
                    </Flex>
                </DrawerHeader>

                <DrawerBody>
                    <ProjectContent projectData={projectData}/>
                </DrawerBody>
            </DrawerContent>
        </Drawer>
    );
}

