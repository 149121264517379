import {Projects} from "../../interface/Iproject";
import React, { useEffect } from "react";
import {useThemeColors} from "../../lib/Color";
import {
    AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader,
    AlertDialogOverlay,
    Box, Button,
    HStack,
    Icon,
    IconButton,
    Image,
    Text,
    useDisclosure
} from "@chakra-ui/react";
import {useRemoveProjectFromCollection} from "../../hook/HCollection";
import useCustomToast from "../../hook/HToast";
import {FaHeart, FaTimes, FaRegHeart} from "react-icons/fa";
import ProjectDrawer from "../../components/ProjectModal";
import {useQuery, useQueryClient} from '@tanstack/react-query';

interface ProjectCardProps {
    project: Partial<Projects>;  // Projects 인터페이스의 일부만 포함할 수 있도록 Partial 사용
    collectionId: string;
    onRefetch: () => void;
}

export const BookMarkProjectCard: React.FC<ProjectCardProps> = ({project, collectionId, onRefetch}) => {
    const {textColor, borderColor, bgColor, accentColor} = useThemeColors();
    const {isOpen: isAlertOpen, onOpen: onAlertOpen, onClose: onAlertClose} = useDisclosure();
    const {isOpen: isDrawerOpen, onOpen: onDrawerOpen, onClose: onDrawerClose} = useDisclosure();
    const cancelRef = React.useRef<HTMLButtonElement>(null);
    const removeProjectMutation = useRemoveProjectFromCollection();
    const {showToast} = useCustomToast();
    const queryClient = useQueryClient();



    const handleDelete = async () => {
        try {
            await removeProjectMutation.mutateAsync({collectionId, projectId: project.id});
            onRefetch();
            onAlertClose();
            showToast("작업이 프로젝트가 삭제되었습니다.", "success");
        } catch (error) {
            console.error("Failed to remove project:", error);
            showToast("프로젝트 삭제에 실패했습니다.", "error");
        }
    };

    const handleCardClick = (e: React.MouseEvent) => {
        // Prevent opening the drawer when clicking on the delete button
        if (!(e.target as HTMLElement).closest('.delete-button')) {
            onDrawerOpen();
        }
    };
    // const handleDrawerClose = () => {
    //     // 드로어가 닫힐 때 프로젝트 데이터를 갱신합니다
    //     queryClient.invalidateQueries(['project', project.id]);
    //     onDrawerClose();
    // };

    return (
        <>
            <Box
                borderWidth={1}
                borderColor={borderColor}
                borderRadius="lg"
                overflow="hidden"
                position="relative"
                onClick={handleCardClick}
                cursor="pointer"
            >
                <IconButton
                    icon={<FaTimes/>}
                    aria-label="Delete project"
                    size="sm"
                    position="absolute"
                    top={2}
                    right={2}
                    onClick={onAlertOpen}
                    _hover={{bg: 'hotpink', color: 'white'}}
                    className="delete-button"
                />
                <Image src={project.response?.output_images?.[0]} alt={project.title}/>
                <Box p={2}>
                    <HStack justifyContent="space-between" mt={0}>
                        <Text fontWeight="bold" color={textColor}>{project.title}</Text>

                        <HStack>
                            <Icon as={FaRegHeart}
                                  color={project.like && project.like > 0 ? {accentColor} : "gray.300"}/>
                            {/*<Icon*/}
                            {/*    as={project?.like && project?.like > 0 ? FaHeart : FaRegHeart}*/}
                            {/*    color={project?.like && project?.like > 0 ? accentColor : "gray.300"}*/}
                            {/*/>*/}
                            <Text fontSize="sm" color={textColor}>{project.like ?? 0}</Text>
                        </HStack>
                    </HStack>
                </Box>
            </Box>

            <AlertDialog
                isOpen={isAlertOpen}
                leastDestructiveRef={cancelRef}
                onClose={onAlertClose}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent bg={bgColor}>
                        <AlertDialogHeader fontSize="lg" fontWeight="bold">
                            프로젝트 삭제
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            정말로 이 프로젝트를 삭제하시겠습니까? 이 작업은 되돌릴 수 없습니다.
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={onAlertClose}>
                                취소
                            </Button>
                            <Button colorScheme="red" onClick={handleDelete} ml={3}>
                                삭제
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>

            <ProjectDrawer
                isOpen={isDrawerOpen}
                onClose={onDrawerClose}
                projectData={project}
            />
        </>
    );
};