import {useThemeColors} from "../../../lib/Color";
import {useQuery} from "@tanstack/react-query";
import {Prompt} from "../../../interface/IPrompts";
import {getPrompts} from "../../../api/APrompts";
import React, {useMemo, useState} from "react";
import {Heading, Select, Text, VStack} from "@chakra-ui/react";
import {ProjectPromptCard} from "../ProjectPromptCard";
import {PromptForm} from "../../Prompt/PromptForm";

export const PromptsPanel = ({data, onSettingsClick, sequence_id}: any) => {
    const {bgColor, textColor} = useThemeColors();
    const {data: prompts, isLoading, error} = useQuery<Prompt[]>({queryKey: ['prompts'], queryFn: getPrompts});
    const [selectedCategory, setSelectedCategory] = useState<string | null>(null);

    const categories = useMemo(() => {
        if (!prompts) return [];
        return Array.from(new Set(prompts.map(prompt => prompt.category?.customName || "Unnamed Category")));
    }, [prompts]);

    const selectedPrompt = useMemo(() => {
        if (!selectedCategory || !prompts) return null;
        return prompts.find(prompt => prompt.category?.customName === selectedCategory);
    }, [selectedCategory, prompts]);

    if (isLoading) return <Text>Loading prompts...</Text>;
    if (error) return <Text>Error loading prompts: {error.message}</Text>;

    return (
        <VStack align="stretch" spacing={4}>
            <Heading size="md" color={textColor}>Prompts</Heading>
            <Select
                placeholder="Select a category"
                onChange={(e) => setSelectedCategory(e.target.value)}
                value={selectedCategory || ""}
            >
                {categories.map((category, index) => (
                    <option key={index} value={category}>{category}</option>
                ))}
            </Select>


            {selectedPrompt && (
                <ProjectPromptCard prompt={selectedPrompt} onSettingsClick={onSettingsClick}
                                   sequence_id={sequence_id}/>
            )}
            <PromptForm/>
        </VStack>
    );
};