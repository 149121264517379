import {useColorModeValue} from '@chakra-ui/react';

export const useThemeColors = () => {
    const bgColor = useColorModeValue("customBg.light", "customBg.dark");
    const textColor = useColorModeValue("customText.light", "customText.dark");
    const borderColor = useColorModeValue("customBorder.light", "customBorder.dark");
    const cardBgColor = useColorModeValue("customCard.light", "customCard.dark");
    const accentColor = useColorModeValue("customAccent.light", "customAccent.dark");
    const folderColor = useColorModeValue("customFolder.light", "customFolder.dark");

    return {
        bgColor,
        textColor,
        borderColor,
        cardBgColor,
        accentColor,
        folderColor
    };
};