import {useQuery} from '@tanstack/react-query';
import {getAItool} from '../api/AITool';
import {getLikedTool, getTool, postToolLike} from "../api/AITool";
import {useMutation, useQueryClient} from '@tanstack/react-query';
import {AITool} from '../interface/IAITool';

export const useAllTool = () => {
    return useQuery({
        queryKey: ['getAiTool'],
        queryFn: getAItool,
    });
};


export const useTool = (ToolId: string | number) => {
    return useQuery({
        queryKey: ['Tool', ToolId],
        queryFn: () => getTool(ToolId),
        enabled: !!ToolId,
    });
};

export const useLikeTool = () => {
    return useQuery({
        queryKey: ['LikeTool'],
        queryFn: () => getLikedTool(),
    });
};


export const useToolLike = (toolId: number) => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: () => postToolLike(toolId.toString()), // Convert to string if API expects string
        onMutate: async () => {
            await queryClient.cancelQueries({queryKey: ['Tool', toolId]});
            const previousTool = queryClient.getQueryData<AITool>(['Tool', toolId]);
        },
        onError: (err, _, context: any) => {
            queryClient.setQueryData(['Tool', toolId], context.previousTool);
        },
        onSuccess: (updatedTool, toolId) => {

            queryClient.setQueryData(['Tool', toolId], updatedTool);
        },
        onSettled: () => {
            queryClient.invalidateQueries({queryKey: ['getAiTool']});
            queryClient.invalidateQueries({queryKey: ['LikeTool']});
            queryClient.invalidateQueries({queryKey: ['Tool', toolId]});
        },
    });
};


// export const useToolLike = (toolId: number) => {
//   const queryClient = useQueryClient();
//   const toast = useToast();
//
//   return useMutation({
//     mutationFn: () => postToolLike(toolId.toString()),
//     onMutate: async () => {
//       await queryClient.cancelQueries({ queryKey: ['Tool', toolId] });
//       const previousTool = queryClient.getQueryData<AITool>(['Tool', toolId]);
//
//       queryClient.setQueryData<AITool>(['Tool', toolId], (old) => {
//         if (old) {
//           return { ...old, like: old.like + 1, liked: true };
//         }
//         return old;
//       });
//
//       return { previousTool };
//     },
//     onError: (err, _, context: any) => {
//       queryClient.setQueryData(['Tool', toolId], context.previousTool);
//       toast({
//         title: "Error liking the tool",
//         status: "error",
//         duration: 3000,
//         isClosable: true,
//       });
//     },
//     onSuccess: () => {
//       toast({
//         title: "Tool liked successfully",
//         status: "success",
//         duration: 2000,
//         isClosable: true,
//       });
//     },
//     onSettled: () => {
//       queryClient.invalidateQueries({ queryKey: ['Tool', toolId] });
//     },
//   });
// };