import React, {useEffect, useState, useCallback, useMemo} from "react";
import {useQueryClient} from "@tanstack/react-query";
import {
    Box,
    Heading,
    Text,
    SimpleGrid,
    Image,
    Skeleton,
    useBreakpointValue,
    VStack,
    Container,
    useColorModeValue,
    Flex,
    Icon,
    AspectRatio,
    Tag,
    HStack,
    Wrap,
    WrapItem,
    Button, useTheme, Tooltip, useDisclosure, Fade, Spinner, Center,


} from "@chakra-ui/react";
import {FaDownload, FaHeart, FaBolt, FaLink} from "react-icons/fa";
import {useAllTool} from "../hook/HAITool";
import {AITool} from "../interface/IAITool";
import ModelModal from "../components/ModelModal";  // ModelModal 컴포넌트 import
import {useThemeColors} from '../lib/Color';
import TagSelector from '../elements/Model_TagSelector';
import Model_Card from '../elements/Model_Card';
import {FixedSizeGrid as Grid, GridChildComponentProps} from 'react-window';
import {containerMaxWidth, containerPadding, headingSize, titleSize} from "../lib/ResponsiveValue";

interface TagItem {
    name: "All" | "CheckPoint" | "Unet" | "Lora" | "Adapter" | "ControlNet" | "Taesed";
    emoji: string;
    description: string;
}

const tags: TagItem[] = [
    {name: "All", emoji: "🌟", description: "View all AI models"},
    {name: "CheckPoint", emoji: "🔖", description: "Saved model states for resuming training or inference"},
    {name: "Unet", emoji: "🧠", description: "U-shaped neural network architecture for image segmentation"},
    {name: "Lora", emoji: "🔍", description: "Low-Rank Adaptation technique for fine-tuning large language models"},
    {name: "Adapter", emoji: "🔌", description: "Efficient fine-tuning method for pre-trained models"},
    {name: "ControlNet", emoji: "🕹️", description: "Neural network architecture for controlling generative models"},
    {name: "Taesed", emoji: "🎵", description: "Text-to-Audio and Speech Enhancement Decoder"},
];




const Models: React.FC = () => {
    const columns = useBreakpointValue({ base: 2, sm: 3, md: 4, lg: 5 }) || 4;
    const showDescriptions = useBreakpointValue({ base: false, md: true });

    const { bgColor, textColor, accentColor } = useThemeColors();

    const { data: aiTools, isLoading: isToolsLoading } = useAllTool();

    const [selectedTag, setSelectedTag] = useState<TagItem['name']>("All");
    const [selectedModel, setSelectedModel] = useState<AITool | null>(null);
    const { isOpen, onOpen, onClose } = useDisclosure();

    const filteredTools = useMemo(() =>
        aiTools?.filter((tool: AITool) =>
            selectedTag === "All" || tool.ai_app === selectedTag
        ) || [],
    [aiTools, selectedTag]);

    const handleModelClick = useCallback((model: AITool) => {
        setSelectedModel(model);
        onOpen();
    }, [onOpen]);

    const renderTagDescriptions = useMemo(() => (
        <VStack spacing={4} align="stretch">
            {tags.map((tag) => (
                <Box key={tag.name}>
                    <Text fontSize="sm" color={textColor} mb={0.2}>
                        {tag.emoji} {tag.description}
                    </Text>
                </Box>
            ))}
        </VStack>
    ), [textColor]);

    const renderTags = useMemo(() => (
        <Wrap spacing={2} mb={4}>
            {tags.map((tag) => (
                <WrapItem key={tag.name}>
                    <Tooltip label={showDescriptions ? undefined : tag.description} placement="top">
                        <Tag
                            size="md"
                            color={selectedTag === tag.name ? "white" : accentColor}
                            bg={selectedTag === tag.name ? accentColor : "transparent"}
                            cursor="pointer"
                            onClick={() => setSelectedTag(tag.name)}
                            sx={{
                                borderWidth: "1px",
                                borderStyle: "solid",
                                borderColor: accentColor,
                                transition: "all 0.2s",
                                _hover: {
                                    bg: selectedTag === tag.name ? accentColor : `${accentColor}30`,
                                },
                                _focus: {
                                    boxShadow: "outline",
                                },
                            }}
                        >
                            {tag.emoji} {tag.name}
                        </Tag>
                    </Tooltip>
                </WrapItem>
            ))}
        </Wrap>
    ), [selectedTag, showDescriptions, accentColor]);

    const renderModelCards = useMemo(() => {
        if (isToolsLoading) {
            return Array(columns * 2).fill(0).map((_, index) => (
                <Skeleton key={index} height="300px" />
            ));
        }
        return filteredTools.map((tool: AITool) => (
            <Model_Card
                key={tool.id}
                tool={tool}
                onClick={handleModelClick}
            />
        ));
    }, [isToolsLoading, filteredTools, columns, handleModelClick]);

    if (isToolsLoading) {
        return (
            <Center height="100vh">
                <Spinner
                    thickness="4px"
                    speed="0.65s"
                    emptyColor="gray.200"
                    color={accentColor}
                    size="xl"
                />
            </Center>
        );
    }

    return (
        <Box bg={bgColor} minHeight="100vh" py={6} mt={12}>
            <Container maxW={containerMaxWidth} px={containerPadding}>
                <VStack spacing={6} align="stretch">
                    <Heading textAlign="left" fontSize={titleSize} color={textColor}>
                        AI Models Gallery 🖼️
                    </Heading>
                    <Text color={textColor} fontSize="lg" mb={2}>
                        Welcome to our AI Models Gallery! 🚀 Here you can explore various AI models and their
                        applications. Whether you're a beginner or an expert, you'll find interesting models to experiment with.
                        Use the tags below to filter models by type.
                    </Text>
                    {showDescriptions && renderTagDescriptions}
                    {renderTags}
                    <SimpleGrid columns={columns} spacing={6}>
                        {renderModelCards}
                    </SimpleGrid>
                </VStack>
            </Container>
            {selectedModel && (
                <ModelModal isOpen={isOpen} onClose={onClose} modelData={selectedModel}/>
            )}
        </Box>
    );
};

export default Models;