import React from "react";
import {
    AspectRatio,
    Box,
    Flex,
    Image,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Text,
    useColorModeValue,
    useDisclosure,
} from "@chakra-ui/react";
import {AIInputImage} from "../../interface/IMedias";
import {useDeleteAIInputImage} from "../../hook/HMedias";
import CustomAlertDialog from "../../Utils/CustomAlertDialog";
import useCustomToast from "../../hook/HToast";
import {useAddInputImageToSequence, useAddProjectToSequence} from "../../hook/HSequence";

interface ProjectInputImageCardProps {
    image: AIInputImage;
    onSettingsClick: () => void;
    sequence_id: string;
}

export const ProjectInputImageCard: React.FC<ProjectInputImageCardProps> = ({image, onSettingsClick, sequence_id}) => {
    const overlayBg = useColorModeValue('rgba(255, 255, 255, 0.7)', 'rgba(55, 55, 55, 0.3)');
    const textColor = useColorModeValue('gray.800', 'white');
    const {isOpen: isAlertOpen, onOpen: onAlertOpen, onClose: onAlertClose} = useDisclosure();
    const {showToast} = useCustomToast();

    const deleteAIInputImageMutation = useDeleteAIInputImage();
    const addInputImageToSequenceMutation = useAddInputImageToSequence();

    const handleDelete = () => {
        onAlertOpen();
    };

    const confirmDelete = () => {
        deleteAIInputImageMutation.mutate(image.id, {
            onSuccess: () => {
                showToast("Image deleted successfully", "success");
                onAlertClose();
            },
            onError: () => {
                showToast("Failed to delete image", "error");
                onAlertClose();
            },
        });
    };


    const handleAddToStorage = () => {
        addInputImageToSequenceMutation.mutate(
            {sequence_id, inputImage_id: image.id},
            {
                onSuccess: () => {
                    showToast("Project added to sequence storage", "success");

                },
                onError: (error) => {
                    showToast("Failed to add project to sequence", "error");
                }
            }
        );
    };
    return (
        <Box position="relative">
            <Menu>
                <MenuButton as={Box} cursor="pointer" transition="all 0.3s"
                            _hover={{transform: 'scale(1.05)', boxShadow: 'lg'}}>
                    <Box
                        borderRadius="lg"
                        overflow="hidden"
                        boxShadow="md"
                        position="relative"
                    >
                        <AspectRatio ratio={1}>
                            <Image
                                src={image.file}
                                alt={`Image ${image.id}`}
                                objectFit="cover"
                            />
                        </AspectRatio>
                        <Flex
                            position="absolute"
                            bottom="0"
                            left="0"
                            right="0"
                            height="33.33%"
                            bg={overlayBg}
                            alignItems="left"
                            justifyContent="center"
                            padding="2"
                        >
                            <Text
                                fontWeight="light"
                                fontSize="xs"
                                color={textColor}
                                noOfLines={2}
                            >
                                Type: {image.image_type}
                                <br/>
                                Created: {new Date(image.created_at).toLocaleDateString()}
                            </Text>
                        </Flex>
                    </Box>
                </MenuButton>
                <MenuList>
                    <MenuItem onClick={handleAddToStorage}>Input Storage</MenuItem>

                    <MenuItem onClick={onSettingsClick}>Generate</MenuItem>
                    <MenuItem onClick={handleDelete}>Delete Image</MenuItem>
                </MenuList>
            </Menu>

            <CustomAlertDialog
                isOpen={isAlertOpen}
                onClose={onAlertClose}
                onConfirm={confirmDelete}
                title="Delete Image"
            >
                Are you sure you want to delete this image? This action cannot be undone.
            </CustomAlertDialog>
        </Box>
    );
};