import React from "react";
import {AITool} from "../../interface/IAITool";
import {Box, Button, Flex, Image, Select, Text} from "@chakra-ui/react";

export const ModelSelector: React.FC<{
    selectedModelId: number | null;
    onModelChange: (modelId: number) => void;
    modelData: AITool[];
}> = React.memo(({selectedModelId, onModelChange, modelData}) => {
    if (!modelData || modelData.length === 0) {
        return <Box>No model data available</Box>;
    }

    const selectedModel = selectedModelId !== null
        ? modelData.find((m) => m.id === selectedModelId)
        : undefined;
    return (
        <Box p={4} mb={4} width="100%">
            <Flex alignItems="center" justifyContent="space-between" mb={2}>
                <Text>Model</Text>
                <Button variant="outline" size="sm">Switch →</Button>
            </Flex>
            <Select
                // bg="gray.800"
                borderColor="gray.600"
                value={selectedModelId?.toString() || ''}
                onChange={(e) => onModelChange(Number(e.target.value))}
            >
                {modelData.map((model) => (
                    <option key={model.id} value={model.id.toString()}>{model.ai_model}</option>
                ))}
            </Select>
            {selectedModel && (
                <Box mt={4} position="relative">
                    <Image
                        src={selectedModel.file?.file || ''}
                        alt={`${selectedModel.ai_model} preview`}
                        objectFit="cover"
                        width="100%"
                        height="150px"
                        borderRadius="md"
                    />
                    <Box
                        position="absolute"
                        bottom="0"
                        left="0"
                        right="0"
                        bg="rgba(0,0,0,0.7)"
                        color="white"
                        p={2}
                        borderBottomRadius="md"
                    >
                        <Text fontSize="sm" fontWeight="bold">
                            {selectedModel.ai_model}
                        </Text>
                        <Text fontSize="xs">
                            {selectedModel.ai_app}
                        </Text>
                    </Box>
                </Box>
            )}
        </Box>
    );
});