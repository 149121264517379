import React from 'react';
import LikedProjects from '../components/LikedProject'; // 경로는 실제 파일 위치에 맞게 조정해주세요
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import {Box, Container, Heading, VStack} from "@chakra-ui/react";
import {useThemeColors} from "../lib/Color";
import {containerMaxWidth, containerPadding, titleSize} from "../lib/ResponsiveValue";

const queryClient = new QueryClient();

export default function LikedProjectsPage() {
    const {bgColor, textColor, borderColor, accentColor} = useThemeColors();


    return (
        <Box bg={bgColor} minH="100vh" py={6} mt={12}>
            <Container maxW={containerMaxWidth} px={containerPadding}>
                <VStack spacing={6} align="stretch">
                    <Heading fontSize={titleSize} color={textColor}>
                        My Liked Projects
                    </Heading>
                    <LikedProjects/>
                </VStack>
            </Container>
        </Box>
    );

};

