import React, { useState, useCallback, useEffect, useMemo } from "react";
import {UseFormRegister, UseFormSetValue} from "react-hook-form";
import { Box, Slider, SliderFilledTrack, SliderThumb, SliderTrack, Text, Input, Flex } from "@chakra-ui/react";
import { debounce } from 'lodash';

// export const CfgScaleSlider = React.memo(({
//                                        register,
//                                        watchedCfgScale,
//                                        setValue
//                                    }: {
//     register: UseFormRegister<any>;
//     watchedCfgScale: number;
//     setValue: UseFormSetValue<any>;
// }) => {
//     return (
//         <Box p={4} border="1px" borderColor="gray.600" borderRadius="md" mb={4} width="100%">
//             <Text mb={2}>Prompt Adherence (CFG Scale)</Text>
//             <Slider
//                 min={1}
//                 max={10}
//                 step={0.1}
//                 value={watchedCfgScale}
//                 onChange={(val) => setValue("_cfgScale", val)}
//             >
//                 <SliderTrack>
//                     <SliderFilledTrack/>
//                 </SliderTrack>
//                 <SliderThumb boxSize={6}>{watchedCfgScale.toFixed(1)}</SliderThumb>
//             </Slider>
//             <Text mt={2} textAlign="center">Current Value: {watchedCfgScale.toFixed(1)}</Text>
//             <input type="hidden" {...register("_cfgScale")} />
//         </Box>
//     );
// });


export const CfgScaleSlider = React.memo(({
    register,
    watchedCfgScale,
    setValue
}: {
    register: UseFormRegister<any>;
    watchedCfgScale: number;
    setValue: UseFormSetValue<any>;
}) => {
    const [localCfgScale, setLocalCfgScale] = useState(watchedCfgScale);
    const [inputCfgScale, setInputCfgScale] = useState(watchedCfgScale.toFixed(1));

    const debouncedSetValue = useMemo(
        () => debounce((value: number) => {
            setValue("_cfgScale", value);
        }, 200),
        [setValue]
    );

    useEffect(() => {
        setLocalCfgScale(watchedCfgScale);
        setInputCfgScale(watchedCfgScale.toFixed(1));
    }, [watchedCfgScale]);

    const handleSliderChange = useCallback((value: number) => {
        setLocalCfgScale(value);
        setInputCfgScale(value.toFixed(1));
        debouncedSetValue(value);
    }, [debouncedSetValue]);

    const handleInputChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setInputCfgScale(value);

        const numValue = parseFloat(value);
        if (!isNaN(numValue) && numValue >= 1 && numValue <= 10) {
            setLocalCfgScale(numValue);
            debouncedSetValue(numValue);
        }
    }, [debouncedSetValue]);

    const handleInputBlur = useCallback(() => {
        let numValue = parseFloat(inputCfgScale);
        if (isNaN(numValue) || numValue < 1) {
            numValue = 1;
        } else if (numValue > 10) {
            numValue = 10;
        }
        setLocalCfgScale(numValue);
        setInputCfgScale(numValue.toFixed(1));
        debouncedSetValue(numValue);
    }, [inputCfgScale, debouncedSetValue]);

    return (
        <Box p={4} border="1px" borderColor="gray.600" borderRadius="md" mb={4} width="100%">
            <Text mb={2}>Prompt Adherence (CFG Scale)</Text>
            <Slider
                min={1}
                max={10}
                step={0.1}
                value={localCfgScale}
                onChange={handleSliderChange}
                colorScheme="pink"
            >
                <SliderTrack>
                    <SliderFilledTrack />
                </SliderTrack>
                <SliderThumb boxSize={6} />
            </Slider>
            <Flex justify="center" align="center" mt={2}>
                <Text mr={2}>Current Value:</Text>
                <Input
                    value={inputCfgScale}
                    onChange={handleInputChange}
                    onBlur={handleInputBlur}
                    textAlign="center"
                    width="60px"
                />
            </Flex>
            <input type="hidden" {...register("_cfgScale")} />
        </Box>
    );
});

export default CfgScaleSlider;
