import {Projects} from "../../interface/Iproject";
import React, {useState, useEffect} from "react";
import {AspectRatio, Box, Flex, IconButton, Image, Text, useColorModeValue, useDisclosure} from "@chakra-ui/react";
import {useDeleteUserProject} from "../../hook/HProject";
import {CloseIcon} from "@chakra-ui/icons";
import CustomAlertDialog from "../../Utils/CustomAlertDialog";
import ProjectModal from "../../components/ProjectModal";

interface ProjectCardProps {
    project: Projects;
}

export const GenerateProjectCard: React.FC<ProjectCardProps> = ({ project }) => {
    const overlayBg = useColorModeValue('rgba(255, 255, 255, 1)', 'rgba(55, 55, 55, 0.5)');
    const textColor = useColorModeValue('gray.800', 'white');
    const fallbackImageUrl = "https://via.placeholder.com/400x400?text=No+Image";

    const deleteUserProjectMutation = useDeleteUserProject();
    const [isAlertOpen, setIsAlertOpen] = useState(false);
    const { isOpen: isProjectOpen, onClose: onProjectClose, onOpen: onProjectOpen } = useDisclosure();



    const handleDelete = (e: React.MouseEvent) => {
        e.stopPropagation(); // Prevent the click event from bubbling up
        setIsAlertOpen(true);
    };

    const confirmDelete = () => {
        deleteUserProjectMutation.mutate(project.id, {
            onSettled: () => setIsAlertOpen(false),
        });
    };

    const handleProjectClick = () => {
        onProjectOpen();
    };

    return (
        <Box
            borderRadius="lg"
            overflow="hidden"
            boxShadow="md"
            position="relative"
            transition="transform 0.2s"
            onClick={handleProjectClick}
            cursor="pointer"
        >
            <AspectRatio ratio={1}>
                <Image
                    src={project.response?.output_images?.[0] ?? fallbackImageUrl}
                    alt={project.title}
                    objectFit="cover"
                />
            </AspectRatio>
            <IconButton
                aria-label="Delete project"
                icon={<CloseIcon />}
                size="sm"
                position="absolute"
                top={2}
                right={2}
                onClick={handleDelete}
            />
            {/*<Flex*/}
            {/*    position="absolute"*/}
            {/*    bottom="0"*/}
            {/*    left="0"*/}
            {/*    right="0"*/}
            {/*    height="33.33%"*/}
            {/*    bg={overlayBg}*/}
            {/*    alignItems="left"*/}
            {/*    justifyContent="center"*/}
            {/*    padding="2"*/}
            {/*>*/}
            {/*    <Text*/}
            {/*        fontWeight="light"*/}
            {/*        fontSize="small"*/}
            {/*        color={textColor}*/}
            {/*        textAlign="center"*/}
            {/*        noOfLines={1}*/}
            {/*    >*/}
            {/*        {project.title}*/}
            {/*    </Text>*/}
            {/*</Flex>*/}

            <CustomAlertDialog
                isOpen={isAlertOpen}
                onClose={() => setIsAlertOpen(false)}
                onConfirm={confirmDelete}
                title="Delete Project"
            >
                Are you sure you want to delete this project? This action cannot be undone.
            </CustomAlertDialog>

            <ProjectModal
                isOpen={isProjectOpen}
                onClose={onProjectClose}
                projectData={project}
            />
        </Box>
    );
};