import React, {useState, useEffect} from 'react';
import {
    Box, Heading, Input, Button, SimpleGrid, Text, VStack, HStack,
    Container, InputGroup, InputLeftElement,
    Drawer, DrawerBody, DrawerHeader, DrawerOverlay, DrawerContent, DrawerCloseButton,
    FormControl, FormLabel,useBreakpointValue,
    Spinner, useToast, useDisclosure
} from '@chakra-ui/react';
import {FaSearch, FaPlus, FaFolder, FaFile, FaImage} from 'react-icons/fa';
import {useThemeColors} from "../lib/Color";
import {containerPadding, gridColumns, gridSpacing, headingSize, titleSize,  containerMaxWidth} from '../lib/ResponsiveValue';
import {Sequence} from '../interface/ISequence';
import {useAllTool} from "../hook/HAITool";
import {useAllSequence, useCreateSequenceMutation} from '../hook/HSequence';
import {useMutation, useQueryClient} from '@tanstack/react-query';
import LoginModal from '../components/LoginModal';
import useUser from '../hook/useUser';
import {useQuery} from "@tanstack/react-query";
import User from "../interface/IUser";
import {useNavigate} from 'react-router-dom';
import {CreateSequenceSidebar} from '../elements/Projects/CreateSequenceSidebar';
import {ProjectCard} from '../elements/Projects/ProjectCard';
import useCustomToast from "../hook/HToast";


const Projects: React.FC = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const {isOpen: isCreateModalOpen, onOpen: onCreateModalOpen, onClose: onCreateModalClose} = useDisclosure();
    const {bgColor, textColor, borderColor, accentColor} = useThemeColors();
    const {showToast} = useCustomToast();

    const {data: userData, isLoading: userLoading, error: userError} = useQuery<User>({
        queryKey: ['me'],
        refetchOnWindowFocus: false,
        refetchOnMount: true,
    });

    const {data: projects, isLoading: isProjectsLoading, error: projectsError, refetch} = useAllSequence({
        onError: (error: any) => {
            showToast("Error fetching projects", "error");
        }
    });



    const filteredProjects: Sequence[] = projects?.filter((project: Sequence) =>
        project.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        project.description.toLowerCase().includes(searchTerm.toLowerCase())
    ) || [];

    if (userLoading || (isProjectsLoading && userData)) return <Spinner/>;
    if (userError) return <Text>An error occurred while fetching user data: {(userError as Error).message}</Text>;
    if (projectsError) return <Text>An error occurred while fetching
        projects: {(projectsError as Error).message}</Text>;

    return (
        <Box bg={bgColor} minH="100vh" py={6} mt={12}>
            <Container maxW={containerMaxWidth}  px={containerPadding}>
                <VStack spacing={6} align="stretch">
                    <HStack justify="space-between">
                        <Heading fontSize={titleSize} color={textColor}>
                            Projects 🚀
                        </Heading>
                        <Button leftIcon={<FaPlus/>} bg={accentColor} onClick={onCreateModalOpen}
                                _hover={{bg: `${accentColor}80`}}>
                            Create Project
                        </Button>
                    </HStack>
                    <Text color={textColor} fontSize="lg" mb={0}>
                        Welcome to your Project Hub! 🎨✨ This is your central space to organize and manage all aspects of your AI projects:
                    </Text>
                    <VStack align="start" spacing={2} pl={0}>
                        <Text>📁 <strong>Storage:</strong> Keep all your project files and assets in one place</Text>
                        <Text>💡 <strong>Prompts:</strong> Craft and store your AI prompts for easy access</Text>
                        <Text>🔧 <strong>Tools:</strong> Integrate various AI tools and models into your workflow</Text>
                        <Text>📊 <strong>Results:</strong> View and analyze your project outputs</Text>
                    </VStack>
                    <InputGroup>
                        <InputLeftElement pointerEvents="none">
                            <FaSearch color="gray.300"/>
                        </InputLeftElement>
                        <Input
                            placeholder="Search projects..."
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                    </InputGroup>

                    <SimpleGrid columns={gridColumns} spacing={gridSpacing}>
                        {filteredProjects.map(project => (
                            <ProjectCard key={project.id} project={project}/>
                        ))}
                    </SimpleGrid>
                </VStack>
            </Container>

            <CreateSequenceSidebar isOpen={isCreateModalOpen} onClose={onCreateModalClose}/>
        </Box>
    );
};

export default Projects;