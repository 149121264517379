import React, {useRef} from "react";
import {Box, Flex, Image, Text} from "@chakra-ui/react";
import {FaCamera} from "react-icons/fa";
import {useThemeColors} from "../../lib/Color";

interface ImageReferenceSectionProps {
    title: string;
    imageSrc?: string;
    onImageClick: () => void;
    isImg2imgEnabled: boolean;
    onFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    isUploading: boolean;
    mode: string;

}

export const ImageReferenceSection: React.FC<ImageReferenceSectionProps> = ({
                                                                                title,
                                                                                imageSrc,
                                                                                onImageClick,
                                                                                isImg2imgEnabled,
                                                                                onFileChange,
                                                                                isUploading,
                                                                                mode
                                                                            }) => {
    const {bgColor, textColor} = useThemeColors();
    const fileInputRef = useRef<HTMLInputElement>(null);
    const handleBoxClick = () => {
        if (imageSrc) {
            onImageClick();
        } else {
            fileInputRef.current?.click();
        }
    };
    const handleDragOver = (e: React.DragEvent) => {
        e.preventDefault();
    };

    const handleDrop = (e: React.DragEvent) => {
        e.preventDefault();
        const files = e.dataTransfer.files;
        if (files.length > 0) {
            const event = {target: {files: files}} as React.ChangeEvent<HTMLInputElement>;
            onFileChange(event);
        }
    };
    return (
        <Box p={4} mb={4} width="100%">
            <Flex justifyContent="space-between" alignItems="center" mb={2}>
                <Text>{title}</Text>
                <Text fontSize="sm" color={isImg2imgEnabled ? "pink.500" : "gray.500"}>
                    {isImg2imgEnabled ? "Img2Img Enabled" : "Img2Img Disabled"}
                </Text>
            </Flex>
            <Box
                width="100%"
                height="150px"
                bg={bgColor}
                display="flex"
                alignItems="center"
                justifyContent="center"
                borderRadius="md"
                overflow="hidden"
                onClick={handleBoxClick}
                onDragOver={handleDragOver}
                onDrop={handleDrop}
                border="2px solid"
                borderColor={isImg2imgEnabled ? "pink.500" : "transparent"}
                cursor="pointer"
                position="relative"
            >
                {isUploading ? (
                    <Text>Uploading...</Text>
                ) : imageSrc ? (
                    <Image src={imageSrc} alt={title} objectFit="cover" width="100%" height="100%"/>
                ) : (
                    <Flex direction="column" align="center" justify="center">
                        <FaCamera size="24px" color={textColor}/>
                        <Text mt={2} color={textColor} fontSize="sm">Click or drag to upload an image</Text>
                    </Flex>
                )}
                <input
                    ref={fileInputRef}
                    type="file"
                    accept="image/*"
                    onChange={onFileChange}
                    style={{display: 'none'}}
                />
            </Box>
        </Box>
        // <Box p={4} mb={4} width="100%" cursor="pointer">
        //     <Flex justifyContent="space-between" alignItems="center" mb={2}>
        //         <Text>{title}</Text>
        //         <Text fontSize="sm" color={isImg2imgEnabled ? "pink.500" : "gray.500"}>
        //             {isImg2imgEnabled ? "Img2Img Enabled" : "Img2Img Disabled"}
        //         </Text>
        //     </Flex>
        //     <Box
        //         width="100%"
        //         height="150px"
        //         bg={bgColor}
        //         display="flex"
        //         alignItems="center"
        //         justifyContent="center"
        //         borderRadius="md"
        //         overflow="hidden"
        //         // onClick={onImageClick}
        //         border="2px solid"
        //         borderColor={isImg2imgEnabled ? "pink.500" : "transparent"}
        //     >
        //         {imageSrc ? (
        //             <Image src={imageSrc} alt={title} objectFit="cover" width="100%" height="100%"/>
        //         ) : (
        //             <Flex direction="column" align="center" justify="center">
        //                 <FaCamera size="24px" color={textColor}/>
        //                 <Text mt={2} color={textColor} fontSize="sm">select an image</Text>
        //             </Flex>
        //         )}
        //     </Box>
        // </Box>
    );
};