import React, {useCallback, useState} from "react";
import {useThemeColors} from "../../lib/Color";
import {
    HStack,
    Icon,
    IconButton,
    Input,
    Menu,
    MenuButton, MenuItem,
    MenuList,
    Text,
    useDisclosure,
    useToast,
    Box

} from "@chakra-ui/react";
import {useRemoveCollection, useRenameCollection} from "../../hook/HCollection";
import {FaChevronDown, FaEdit, FaFolder, FaFolderOpen, FaTrash} from "react-icons/fa";
import useCustomToast from "../../hook/HToast";

interface CollectionFolderProps {
    collection: {
        id: string | number;
        name: string;
    };
    isSelected: boolean;
    onClick: () => void;
    onRefetch: () => void;
}

export const CollectionFolder: React.FC<CollectionFolderProps> = ({
                                                                      collection,
                                                                      isSelected,
                                                                      onClick,
                                                                      onRefetch,
                                                                  }) => {
    const [isRenaming, setIsRenaming] = useState(false);
    const [newName, setNewName] = useState(collection.name);
    const {textColor, folderColor, bgColor} = useThemeColors();
    const {isOpen, onOpen, onClose} = useDisclosure();
    const removeCollectionMutation = useRemoveCollection();
    const renameCollectionMutation = useRenameCollection();
    const {showToast} = useCustomToast();

    const handleRename = useCallback(async () => {
        if (newName.trim() === collection.name) {
            setIsRenaming(false);
            return;
        }

        try {
            await renameCollectionMutation.mutateAsync({id: collection.id, name: newName.trim()});
            onRefetch();
            setIsRenaming(false);
            showToast("Collection renamed", "success");
        } catch (error) {
            showToast("Failed to rename collection", "error");
        }
    }, [collection.id, newName, renameCollectionMutation, showToast, onRefetch]);

    const handleRemove = useCallback(async () => {
        try {
            await removeCollectionMutation.mutateAsync(collection.id);
            onRefetch();
            onClose();
            showToast("Collection removed", "success");
        } catch (error) {
            showToast("Failed to remove collection:", "error");
        }
    }, [removeCollectionMutation, collection.id, onRefetch, onClose, showToast]);

    return (
        <Box
            bg={isSelected ? 'rgba(255, 255, 255, 0.2)' : 'transparent'}
            borderRadius="md"
            cursor="pointer"
            _hover={{bg: 'rgba(255, 255, 255, 0.1)'}}
            height="40px"  // 높이를 지정하여 더 넓게 만듭니다
            position="relative"  // 상대 위치 지정
        >
            <HStack
                justify="space-between"
                height="100%"
                onClick={onClick}
                width="100%"
                position="absolute"  // 절대 위치 지정
                top="0"
                left="0"
                right="0"
                px={2}  // 좌우 패딩 추가
            >
                {isRenaming ? (
                    <Input
                        value={newName}
                        onChange={(e) => setNewName(e.target.value)}
                        onBlur={handleRename}
                        onKeyDown={(e) => e.key === 'Enter' && handleRename()}
                        autoFocus
                    />
                ) : (
                    <HStack spacing={2}>
                        <Icon
                            as={isSelected ? FaFolderOpen : FaFolder}
                            color={folderColor}
                        />
                        <Text color={textColor}>{collection.name}</Text>
                    </HStack>
                )}

                <Menu isOpen={isOpen} onClose={onClose}>
                    <MenuButton
                        aria-label="Options"
                        as={IconButton}
                        icon={<FaChevronDown/>}
                        variant="ghost"
                        size="sm"
                        onClick={(e) => {
                            e.stopPropagation();
                            onOpen();
                        }}
                        bg={bgColor}
                    />
                    <MenuList bg={bgColor}>
                        <MenuItem
                            icon={<FaEdit/>}
                            onClick={() => setIsRenaming(true)}
                            _hover={{bg: 'white', color: bgColor}}
                            bg={bgColor}
                        >
                            Rename Collection
                        </MenuItem>
                        <MenuItem
                            icon={<FaTrash/>}
                            onClick={handleRemove}
                            _hover={{bg: 'white', color: bgColor}}
                            bg={bgColor}

                        >
                            Delete Collection
                        </MenuItem>
                    </MenuList>
                </Menu>
            </HStack>
        </Box>
    );
};
