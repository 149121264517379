import {instance, getCSRFHeader} from "./Abase";
import {Prompt, PromptFormData} from "../interface/IPrompts";


export const getPrompts = () =>
    instance.get<Prompt[]>("prompt/categories/", {headers: getCSRFHeader()})
        .then(response => response.data);

export const createPrompt = (promptData: PromptFormData) =>
    instance.post<Prompt>("prompt/categories/", promptData, {headers: getCSRFHeader()})
        .then(response => response.data);

export const updatePrompt = ({ promptData, id }: { promptData: PromptFormData; id: number }) =>
    instance.put<Prompt>(`prompt/categories/`, { ...promptData, id, action: 'append' }, { headers: getCSRFHeader() })
        .then(response => response.data);

// export const deletePrompt = (id: number) =>
//     instance.delete<void>("prompt/categories/", {headers: getCSRFHeader(), data: id})
//         .then(response => response.data);

export const deletePrompt = (id: number) =>
    instance.delete<void>("prompt/categories/", {
        headers:getCSRFHeader(),
        data: id.toString()
    })
        .then(response => response.data);

export const deletePromptItem = (categoryId: number, promptIndex: number) =>
    instance.delete<Prompt>(`prompt/categories/${categoryId}/${promptIndex}/`, { headers: getCSRFHeader() })
        .then(response => response.data);