import {createBrowserRouter} from "react-router-dom";
import Root from "./components/Root";
import Home from "./routes/Home";
import NotFound from "./routes/NotFound";
import ProjectDetail from "./routes/ProjectDetail";
import GithubConfirm from "./routes/GithubConfirm";
import KakaoConfirm from "./routes/KakaoConfirm";
import GoogleConfirm from "./routes/GoogleConfirm";
import UploadRoom from "./routes/UploadRoom";
import UploadPhotos from "./routes/UploadPhotos";
import GenerateAI from "./routes/GenerateAI";
import {ImageProvider} from "./hook/useImageContext";  // ImageContext 파일의 경로에 맞게 수정해주세요
import Community from "./routes/Community";
import Models from "./routes/Models";
import LikedProjectsPage from "./routes/LikedProjectsPage"; // 새로 추가한 import
import Collections from "./routes/Collections"
import Projects from "./routes/Projects"
import Storage from "./routes/Storage"
import PromptPage from "./routes/Prompt"
import useUser from "./hook/useUser";
import {useLocation} from 'react-router-dom';
import React, {ReactElement} from 'react';
import Payment from "./routes/Payment"

const router = createBrowserRouter([
    {
        path: "/",
        element: <Root/>,
        errorElement: <NotFound/>,
        children: [
            {
                path: "",
                element: <Home/>,
            },
            {
                path: "Prompt",
                element: <PromptPage/>,

            },
            {
                path: "Billing",
                element: <Payment/>,

            },
            {
                path: "Storage",
                element: <Storage/>,
            },
            {
                path: "Projects",
                children: [
                    {
                        path: "",
                        element: <Projects/>,
                    },
                    {
                        path: ":SequenceId",
                        element: <ProjectDetail/>,
                    },

                ],

            },
            {
                path: "Collections",
                element:
                    <Collections/>

            },
            {
                path: "community-feed",
                element: <Community/>,
            },
            {
                path: "Liked",
                element: <LikedProjectsPage/>,
            },
            {
                path: "models",
                element: <Models/>,
            },


            {
                path: "generateai",
                element: (
                    <ImageProvider>
                        <GenerateAI/>
                    </ImageProvider>
                ),

            },
            {
                path: "social",
                children: [
                    {
                        path: "github",
                        element: <GithubConfirm/>,
                    },
                    {
                        path: "kakao",
                        element: <KakaoConfirm/>,
                    },
                    {
                        path: "google",
                        element: <GoogleConfirm/>,
                    },
                ],
            },
        ],
    },
]);

export default router;