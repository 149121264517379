import React from "react";
import { UseFormRegister, UseFormSetValue } from "react-hook-form";
import {
    Box,
    Text,
    FormControl,
    Textarea,
    VStack,
    useColorModeValue
} from "@chakra-ui/react";

interface PromptInputProps {
    register: UseFormRegister<any>;
    watchedPrompt: string;
    watchedNegativePrompt: string;
    setValue: UseFormSetValue<any>;
}

export const PromptInput: React.FC<PromptInputProps> = React.memo(({
    register,
    watchedPrompt,
    watchedNegativePrompt,
    setValue
}) => {
    const bgColor = useColorModeValue("gray.50", "gray.700");
    const borderColor = useColorModeValue("gray.200", "gray.600");
    const textColor = useColorModeValue("gray.800", "white");
    const negativePromptAccentColor = useColorModeValue("red.500", "red.300");

    return (
        <Box p={6}  borderRadius="lg" boxShadow="md" width="100%">
            <VStack spacing={4} align="stretch">
                <FormControl>
                    <Text mb={2} fontWeight="bold" color={textColor}>Prompt</Text>
                    <Textarea
                        {...register("_prompt")}
                        placeholder="Enter your prompt here..."
                        value={watchedPrompt}
                        onChange={(e) => setValue("_prompt", e.target.value)}
                        minHeight="150px"
                        resize="vertical"
                        borderColor={borderColor}
                        _hover={{ borderColor: "blue.500" }}
                        _focus={{ borderColor: "blue.500", boxShadow: "0 0 0 1px blue.500" }}
                    />
                </FormControl>

                <FormControl>
                    <Text mb={2} fontWeight="bold" color={textColor}>Negative Prompt</Text>
                    <Textarea
                        {...register("_negativePrompt")}
                        placeholder="Enter negative prompt here..."
                        value={watchedNegativePrompt}
                        onChange={(e) => setValue("_negativePrompt", e.target.value)}
                        minHeight="100px"
                        resize="vertical"
                        borderColor={borderColor}
                        _hover={{ borderColor: negativePromptAccentColor }}
                        // _focus={{ borderColor: negativePromptAccentColor, boxShadow: `0 0 0 1px ${negativePromptAccentColor}` }}
                    />
                </FormControl>
            </VStack>
        </Box>
    );
});