import React, {useState} from 'react';
import {useLikeProject} from '../hook/HProject';
import {useLikeTool} from "../hook/HAITool";
import {
    Box,
    Image,
    useDisclosure,
    Tabs,
    TabList,
    Tab,
    TabPanels,
    TabPanel,
    SimpleGrid,
    Text,
    AspectRatio,
    Icon,
    Flex
} from '@chakra-ui/react';
import {motion} from 'framer-motion';
import ProjectModal from "./ProjectModal";
import ModelModal from "./ModelModal";
import {project} from "../interface/Iproject";
import {FaHeart} from 'react-icons/fa';


const MotionBox = motion(Box);

const LikedItems: React.FC = () => {
    const [selectedProject, setSelectedProject] = useState<project | null>(null);
    const [selectedModel, setSelectedModel] = useState<any | null>(null);

    const {
        isOpen: isProjectOpen,
        onClose: onProjectClose,
        onOpen: onProjectOpen,
    } = useDisclosure();
    const {
        isOpen: isModelOpen,
        onClose: onModelClose,
        onOpen: onModelOpen,
    } = useDisclosure();

    const {data: likedProjects, isLoading: isProjectLoading, error: projectError} = useLikeProject();
    const {data: likedModels, isLoading: isModelLoading, error: modelError} = useLikeTool();

    const handleProjectClick = (project: project) => {
        setSelectedProject(project);
        onProjectOpen();
    };

    const handleModelClick = (model: any) => {
        setSelectedModel(model);
        onModelOpen();
    };

    const renderGrid = (items: any[], handleClick: (item: any) => void, itemType: string) => {
        // console.log(`${itemType} items:`, items);  // 여기에 console.log 추가

        return (
            <SimpleGrid columns={{base: 1, sm: 2, md: 3, lg: 4, xl: 5}} spacing={6} px={4}>
                {items && items.map((item: any, index: number) => (
                    <MotionBox
                        key={index}
                        whileHover={{scale: 1.05}}
                        whileTap={{scale: 0.95}}
                        onClick={() => handleClick(item)}
                        cursor="pointer"
                        borderRadius="lg"
                        overflow="hidden"
                        boxShadow="lg"
                        position="relative"
                    >
                        <AspectRatio ratio={1}>
                            <Image
                                src={item.response?.output_images?.[0] || item.file?.file || "https://via.placeholder.com/300"}
                                alt={`${itemType} ${index + 1}`}
                                objectFit="cover"
                            />
                        </AspectRatio>
                        <Flex
                            position="absolute"
                            bottom="0"
                            left="0"
                            right="0"
                            p={2}
                            bgGradient="linear(to-t, blackAlpha.600, blackAlpha.300, transparent)"
                            alignItems="center"
                            justifyContent="flex-end"
                        >
                            <FaHeart color="white" style={{marginRight: '4px'}}/>
                            <Text color="white" fontWeight="bold">
                                {item.like}
                            </Text>
                        </Flex>
                    </MotionBox>
                ))}
            </SimpleGrid>
        );
    };
    if (isProjectLoading || isModelLoading) return <Text>Loading...</Text>;
    if (projectError || modelError) return <Text color="red.500">An error
        occurred: {((projectError || modelError) as Error).message}</Text>;

    return (
        <Box>
            <Tabs isFitted variant="enclosed" colorScheme={"pink"}>
                <TabList mb="1em">
                    <Tab>Liked Projects</Tab>
                    <Tab>Liked Models</Tab>
                </TabList>
                <TabPanels>
                    <TabPanel>
                        {renderGrid(likedProjects || [], handleProjectClick, 'Project')}
                    </TabPanel>
                    <TabPanel>
                        {renderGrid(likedModels || [], handleModelClick, 'Model')}
                    </TabPanel>
                </TabPanels>
            </Tabs>
            {selectedProject && (
                <ProjectModal isOpen={isProjectOpen} onClose={onProjectClose} projectData={selectedProject}/>
            )}
            {selectedModel && (
                <ModelModal isOpen={isModelOpen} onClose={onModelClose} modelData={selectedModel}/>
            )}
        </Box>
    );
};

export default LikedItems;