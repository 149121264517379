import {QueryFunctionContext} from "@tanstack/react-query";
import {instance, getCSRFHeader} from "./Abase";



export const getAItool = () =>
    instance.get("tool/AllTool").then((response) => response.data);


export const getTool = (toolID: string | number) =>
    instance.get(`tool/${toolID}/`).then((response) => response.data);


export const getLikedTool = () =>
    instance.get(`tool/liked-ai-tools`).then((response) => response.data);


export const postToolLike = (toolID: string) =>
    instance.post(
        `tool/${toolID}/like/`,
        {}, // body는 비워둡니다
        {
            headers: getCSRFHeader()
        }
    ).then((response) => response.data);
