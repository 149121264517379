import { useToast, UseToastOptions } from "@chakra-ui/react";

const useCustomToast = () => {
  const toast = useToast();

  const showToast = (title: string, status: "success" | "error" | "info" | "warning", description?: string) => {
    const options: UseToastOptions = {
      title,
      status,
      duration: 3000,
      isClosable: true,
      position: "bottom-right",
    };

    if (description) {
      options.description = description;
    }

    toast(options);
  };

  return { showToast };
};

export default useCustomToast;