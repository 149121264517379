import {useQuery} from '@tanstack/react-query';
import {
    getLikedProject,
    getProject,
    getUserProjects,
    postProjectLike,
    getAllProject, deleteUserProject, updateProjectShared
} from '../api/AProject';
import {useMutation, useQueryClient} from '@tanstack/react-query';
import {project, Projects} from "../interface/Iproject";
import {useToast} from '@chakra-ui/react';


export const useALLProjects = () => {
    return useQuery<Projects[], Error>({
        queryKey: ["allprojects"],
        queryFn: getAllProject,
    });
};

export const useUserProjects = (userData: any) => {
    return useQuery({
        queryKey: ['userProjects', userData?.username],
        queryFn: getUserProjects,
        enabled: !!userData,
    });
};


export const useProject = (projectId: string | number) => {
    return useQuery({
        queryKey: ['project', projectId],
        queryFn: () => getProject(projectId),
        enabled: !!projectId,
    });
};


export const useLikeProject = () => {
    return useQuery({
        queryKey: ['Likeproject'],
        queryFn: () => getLikedProject(),
    });
};


export const useDeleteUserProject = () => {
    const queryClient = useQueryClient();
    const toast = useToast();

    return useMutation({
        mutationFn: deleteUserProject,
        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: ['userProjects']});
            toast({
                title: "Project deleted successfully",
                status: "success",
                duration: 3000,
                isClosable: true,
            });
        },
        onError: (error: Error) => {
            toast({
                title: "Failed to delete project",
                description: error.message || "An unexpected error occurred",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        },
    });
};

export const useProjectLike = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: postProjectLike,
        onMutate: async (projectId) => {
            await queryClient.cancelQueries({queryKey: ['project', projectId]});
            const previousProject = queryClient.getQueryData(['project', projectId]);
        },
        onError: (err, projectId, context: any) => {
            // 에러가 발생하면 이전 데이터로 롤백합니다.
            queryClient.setQueryData(['project', projectId], context.previousProject);
        },
        onSuccess: (updatedProject, projectId) => {
            queryClient.setQueryData(['project', projectId], updatedProject);
        },
        onSettled: (data, error, projectId) => {
            queryClient.invalidateQueries({queryKey: ['collections']});

            queryClient.invalidateQueries({queryKey: ['project']});

            queryClient.invalidateQueries({queryKey: ['userProjects']});
            queryClient.invalidateQueries({queryKey: ['allprojects']});
            queryClient.invalidateQueries({queryKey: ['Likeproject']});
        },
    });
};

export const useUpdateProjectShared = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: ({id, shared}: { id: number | string; shared: boolean }) =>
            updateProjectShared(id, shared),
        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: ['userProjects']});
            queryClient.invalidateQueries({queryKey: ['allprojects']});
            queryClient.invalidateQueries({queryKey: ['project']});
        },
    });
};