import useUser from "../../../hook/useUser";
import {useUserProjects} from "../../../hook/HProject";
import {Projects} from "../../../interface/Iproject";
import {useGetStorageImage} from "../../../hook/HGenerateAI";
import {AIInputImage} from "../../../interface/IMedias";
import {Box, Flex, Heading, SimpleGrid, Spinner, Text, VStack} from "@chakra-ui/react";
import {ProjectCard} from "../ProjectCard";
import {ProjectInputImageCard} from "../ProjectInputImageCard";
import React from "react";
import {useThemeColors} from "../../../lib/Color";
import {motion} from "framer-motion";
const MotionBox = motion(Box);

export const StoragePanel = ({data, onSettingsClick, sequence_id}: any) => {
    const {user} = useUser();
    const {bgColor, textColor, accentColor} = useThemeColors();

    const {data: userProjects, isLoading: projectsLoading, error: projectsError} = useUserProjects(user) as {
        data: Projects[] | undefined;
        isLoading: boolean;
        error: Error | null;
    };
    const {data: initImages, isLoading: initImagesLoading, error: initImagesError} = useGetStorageImage(user) as {
        data: AIInputImage[] | undefined;
        isLoading: boolean;
        error: Error | null;
    };
    return (
        <VStack align="stretch" spacing={4}>
            <Heading size="lg">Storage</Heading>
            <Box>
                {/*<Heading size="md" mb={4}>User Projects</Heading>*/}
                {projectsLoading ? (
                    <Flex justify="center" align="center" height="100px">
                        <MotionBox
                            animate={{
                                scale: [1, 1.1, 1],
                                opacity: [1, 0.5, 1],
                            }}
                            transition={{
                                duration: 1.5,
                                repeat: Infinity,
                                repeatType: "reverse"
                            }}
                        >
                            {/*<Circle size="16" bg={accentColor}/>*/}
                            <Box
                                width="64px"
                                height="64px"
                                borderRadius="50%"
                                border="3px solid"
                                borderColor={accentColor}
                            />
                        </MotionBox>
                    </Flex>
                ) : projectsError ? (
                    <Text color="red.500">Error loading user projects: {projectsError.message}</Text>
                ) : userProjects && userProjects.length > 0 ? (
                    <SimpleGrid columns={[1, 2, 3]} spacing={4}>
                        {userProjects.map((project: Projects) => (
                            <ProjectCard key={project.id} project={project} onSettingsClick={onSettingsClick}
                                         sequence_id={sequence_id}
                            />
                        ))}
                    </SimpleGrid>
                ) : (
                    <Text>No user projects found.</Text>
                )}
            </Box>
            <Heading size="lg">InputImage</Heading>

            <Box>
                {initImagesLoading ? (
                    <Spinner size="xl"/>
                ) : initImagesError ? (
                    <Text color="red.500">Error loading AI generated
                        images: {initImagesError.message}</Text>
                ) : initImages && initImages.length > 0 ? (
                    <SimpleGrid columns={[1, 2, 3]} spacing={4}>
                        {initImages.map((image: AIInputImage) => (
                            <ProjectInputImageCard key={image.id} image={image} onSettingsClick={onSettingsClick}
                                                   sequence_id={sequence_id}/>
                        ))}
                    </SimpleGrid>
                ) : (
                    <Text>No AI generated images found.</Text>
                )}
            </Box>
        </VStack>
    );
};