import { useQuery } from "@tanstack/react-query";
import { getMe } from "../api/AUser";
import { getTokenBalance } from "../api/AUser";
import { IUser } from "../types";

// export default function useUser() {
//   const { isLoading, data, isError } = useQuery<IUser>({
//     queryKey: ["me"],
//     queryFn: getMe,
//     retry: false,
//     refetchOnWindowFocus: false,
//
//   });
//   return {
//     userLoading: isLoading,
//     user: data,
//     isLoggedIn: !isError,
//   };
// }

interface ITokenBalance {
  token_balance: number;


}

export default function useUser() {
  const {
    isLoading: userLoading,
    data: userData,
    error: userError,
    isError: isUserError
  } = useQuery<IUser, Error>({
    queryKey: ["me"],
    queryFn: getMe,
    retry: false,
    refetchOnWindowFocus: false,
  });

  const isLoggedIn = !!userData && !isUserError;

  const {
    data: tokenData,
    error: tokenError,
    isError: isTokenError
  } = useQuery<ITokenBalance, Error>({
    queryKey: ["tokenBalance"],
    queryFn: getTokenBalance,
    enabled: isLoggedIn,
    retry: false,
    refetchOnWindowFocus: false,
  });

  return {
    userLoading,
    user: userData,
    isLoggedIn,
    tokenBalance: tokenData?.token_balance,
    error: userError || tokenError,
    isError: isUserError || isTokenError
  };
}

// export default function useUser() {
//   const { isLoading: userLoading, data: userData, error: isError } = useQuery<IUser>({
//     queryKey: ["me"],
//     queryFn: getMe,
//     retry: false,
//     refetchOnWindowFocus: false,
//   });
//
//   const { data: tokenData } = useQuery<ITokenBalance>({
//     queryKey: ["tokenBalance"],
//     queryFn: getTokenBalance,
//     enabled: !isError, // 사용자가 로그인한 경우에만 토큰 잔액을 가져옵니다
//     retry: false,
//     refetchOnWindowFocus: false,
//   });
//
//   return {
//     userLoading,
//     user: userData,
//     isLoggedIn: !isError,
//     tokenBalance: tokenData?.token_balance,
//   };
// }
//
