import React, { createContext, useContext, useState, ReactNode, useCallback } from 'react';


interface SelectedImage {
    id: string;
    file: string;
}

interface ImageContextType {
    selectedImages: { [key: string]: SelectedImage };
    setSelectedImages: React.Dispatch<React.SetStateAction<{ [key: string]: SelectedImage }>>;
    selectedMode: string;
    setSelectedMode: React.Dispatch<React.SetStateAction<string>>;
    handleImageClick: (image: SelectedImage | null, mode: string) => void;
}


const ImageContext = createContext<ImageContextType | undefined>(undefined);

export const useImageContext = () => {
    const context = useContext(ImageContext);
    if (!context) {
        throw new Error('useImageContext must be used within an ImageProvider');
    }
    return context;
};


export const ImageProvider: React.FC<{ children: ReactNode }> = ({children}) => {
    const [selectedImages, setSelectedImages] = useState<{ [key: string]: SelectedImage }>({});
    const [selectedMode, setSelectedMode] = useState('imgToImg');
    // const selectedImagesRef = useRef(selectedImages);

    // const handleImageClick = (image: SelectedImage | null, mode: string) => {
    //     setSelectedImages((prevSelected) => {
    //         const newSelected = {...prevSelected};
    //         if (image === null) {
    //             // 이미지 선택 해제
    //             delete newSelected[mode];
    //         } else if (newSelected[mode]?.id === image.id) {
    //             // 이미 선택된 이미지 클릭 시 선택 해제
    //             delete newSelected[mode];
    //         } else {
    //             // 새 이미지 선택
    //             newSelected[mode] = image;
    //         }
    //         return newSelected;
    //     });
    // };


    const handleImageClick = useCallback((image: SelectedImage | null, mode: string) => {
        setSelectedImages((prevSelected) => {
            const newSelected = { ...prevSelected };
            if (image === null) {
                delete newSelected[mode];
            } else if (newSelected[mode]?.id === image.id) {
                delete newSelected[mode];
            } else {
                newSelected[mode] = image;
            }
            return newSelected;
        });
    }, []);
    // const handleImageClick = (image: { id: string, file: string }, mode: string) => {
    //     setSelectedImages((prevSelected) => {
    //         const newSelected = {...prevSelected};
    //         if (newSelected[mode]?.id === image.id) {
    //             delete newSelected[mode];
    //         } else {
    //             newSelected[mode] = {id: image.id, file: image.file};
    //         }
    //         return newSelected;
    //     });
    //     console.log("Selected Images:", selectedImages);
    // };
    // useEffect(() => {
    //     selectedImagesRef.current = selectedImages;
    //     console.log("Selected Images Updated:", selectedImagesRef.current);
    // }, [selectedImages]);
    return (
        <ImageContext.Provider value={{
            selectedImages,
            setSelectedImages,
            selectedMode,
            setSelectedMode,
            handleImageClick,
        }}>
            {children}
        </ImageContext.Provider>
    );
};

