import React, {useState} from "react";
import {useThemeColors} from "../../lib/Color";
import {useCreateSequenceMutation} from "../../hook/HSequence";
import {
    Button,
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerHeader,
    DrawerOverlay, FormControl, FormLabel, Input,
    VStack
} from "@chakra-ui/react";

interface CreateSequenceSidebarProps {
    isOpen: boolean;
    onClose: () => void;
}

export const CreateSequenceSidebar: React.FC<CreateSequenceSidebarProps> = ({isOpen, onClose}) => {
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const {bgColor, textColor, borderColor, accentColor} = useThemeColors();

    const createSequenceMutation = useCreateSequenceMutation({
        onSuccess: onClose,
    });

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        createSequenceMutation.mutate({name, description});
    };

    return (
        <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
            <DrawerOverlay/>
            <DrawerContent bg={bgColor} color={textColor} borderColor={borderColor}>
                <DrawerCloseButton/>
                <DrawerHeader borderBottomWidth="1px" borderColor={borderColor}>Create New Sequence</DrawerHeader>
                <DrawerBody>
                    <VStack spacing={4} as="form" onSubmit={handleSubmit}>
                        <FormControl>
                            <FormLabel>Name</FormLabel>
                            <Input value={name} onChange={(e) => setName(e.target.value)} borderColor={borderColor}/>
                        </FormControl>
                        <FormControl>
                            <FormLabel>Description</FormLabel>
                            <Input value={description} onChange={(e) => setDescription(e.target.value)}
                                   borderColor={borderColor}/>
                        </FormControl>
                        <Button
                            type="submit"
                            bg={accentColor}
                            color={textColor}
                            _hover={{bg: `${accentColor}80`}}  // 80 adds 50% opacity, creating a lighter hover effect
                            isLoading={createSequenceMutation.isPending}
                            width="100%"
                        >
                            Create
                        </Button>
                    </VStack>
                </DrawerBody>
            </DrawerContent>
        </Drawer>
    );
};
