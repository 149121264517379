import React, {createContext, useContext} from 'react';
import {IForm} from "../interface/IForm";
import {project} from "../interface/Iproject";
import {AITool} from "../interface/IAITool";
import {ControlNet} from "../interface/IAIStyle";
// hooks/useGenerateAIState.ts
import {useState, useEffect, useCallback} from "react";
import {useForm} from "react-hook-form";
import {Box, useDisclosure} from "@chakra-ui/react";
import {v4 as uuidv4} from "uuid";
import {useQuery} from "@tanstack/react-query";
import {useProject, useUserProjects} from "../hook/HProject";
import {useAllTool, useTool} from "../hook/HAITool";
import {useGetStorageImage, useImageGeneration} from "../hook/HGenerateAI";
import {useGetAIStyle_Controlnet} from "../hook/HAIStyle";
import {useImageContext} from "../hook/useImageContext";
import User from "../interface/IUser";
import {ICreateAIRequestVariables} from "../api/ARequest";
// GenerateAI.tsx
import {Flex} from "@chakra-ui/react";
import {useLocation} from 'react-router-dom';
import ImageTabs from '../components/ImageTabs';
import GenerateSidebar from '../components/GenerateSidebar';
import ProjectModal from '../components/ProjectModal';


interface GenerateAIContextType {
    state: {
        isSidebarOpen: boolean;
        aiImages: any[];
        selectedModelId: number;
        selectedProject: project | null;
        isProjectOpen: boolean;
        userProjects: project[];
        projectsLoading: boolean;
        projectsError: any;
        responseImages: any[];
        createPhotoMutation: any;
        aiTool: AITool[];
        AIStyle_Controlnet: ControlNet[];

    };
    formMethods: any;
    handleModelChange: (modelId: number) => void;
    handleProjectClick: (project: project) => void;
    handleControlNetSelect: (controlNet: ControlNet) => void;
    onGenerateImage: (data: IForm) => Promise<void>;
    onProjectClose: any;
}

const GenerateAIContext = createContext<GenerateAIContextType | null>(null);

const useGenerateAIContext = () => {
    const context = useContext(GenerateAIContext);
    if (!context) {
        throw new Error('useGenerateAIContext must be used within a GenerateAIProvider');
    }
    return context;
};

const GenerateAIProvider: React.FC<{ value: GenerateAIContextType, children: React.ReactNode }> = ({
                                                                                                       value,
                                                                                                       children
                                                                                                   }) => (
    <GenerateAIContext.Provider value={value}>
        {children}
    </GenerateAIContext.Provider>
);


const useGenerateAIState = (projectId: string | null, toolId: string | null) => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);
    const [aiImages, setAiImages] = useState<any[]>([]);
    const [selectedModelId, setSelectedModelId] = useState<number>(1);
    const [selectedProject, setSelectedProject] = useState<project | null>(null);

    const {isOpen: isProjectOpen, onClose: onProjectClose, onOpen: onProjectOpen} = useDisclosure();

    const formMethods = useForm<IForm>({
        defaultValues: {
            _prompt: '',
            _negativePrompt: '',
            _cfgScale: 7,
            _height: 1024,
            _width: 1024,
            _steps: 30,
            _batchSize: 1,
            _img2img_bool: false,
            _controlNetIds: [],
        }
    });

    const {data: userData} = useQuery<User>({queryKey: ['me'], refetchOnWindowFocus: false, refetchOnMount: true});
    const {data: aiTool} = useAllTool();
    const {data: userProjects, isLoading: projectsLoading, error: projectsError} = useUserProjects(userData);
    const {data: initialAiImages, isLoading: initialAiLoading} = useGetStorageImage(userData);
    const {data: AIStyle_Controlnet} = useGetAIStyle_Controlnet();

    const {createPhotoMutation, responseImages} = useImageGeneration();
    const {selectedImages, setSelectedImages, handleImageClick} = useImageContext();
    const {setValue, getValues} = formMethods;

    const {data: paramProject, isLoading: isparamProjectLoading} = useProject(projectId || '');
    const {data: paramModel, isLoading: isparamModelLoading} = useTool(toolId || '');


    useEffect(() => {
        if (paramModel && !isparamModelLoading) {
            console.log(paramModel); // 로그 출력
            setSelectedModelId(paramModel.id);
        }
    }, [paramModel, isparamModelLoading, setSelectedModelId]);

    useEffect(() => {
        if (paramProject && !isparamProjectLoading) {
            // Update form with project data
            setValue('_prompt', paramProject.title || '');
            setValue('_negativePrompt', paramProject.description || '');

            if (paramProject.request) {
                setValue('_cfgScale', paramProject.request.cfg);
                setValue('_height', paramProject.request.height);
                setValue('_width', paramProject.request.width);
                setValue('_steps', paramProject.request.steps);
                setValue('_batchSize', paramProject.request.batch_size);
                setValue('_img2img_bool', paramProject.request.img2img_bool);

                if (paramProject.request.controlnet && paramProject.request.controlnet.length > 0) {
                    const controlNetIds = paramProject.request.controlnet.map((cn: any) => cn.id);
                    setValue('_controlNetIds', controlNetIds);
                    controlNetIds.forEach((id: number) => {
                        const controlNet = AIStyle_Controlnet?.find((cn: any) => cn.id === id);
                        if (controlNet) {
                            handleControlNetSelect(controlNet);
                        }
                    });
                }

                // ControlNet 처리


                if (paramProject.request.file) {
                    const imgToImgFile = {
                        id: paramProject.request.file.id,
                        file: paramProject.request.file.file
                    };
                    handleImageClick(imgToImgFile, 'imgToImg');
                    setSelectedImages(prev => ({...prev, imgToImg: imgToImgFile}));
                }

                if (paramProject.request.styleReffile) {
                    handleImageClick({
                        id: paramProject.request.styleReffile.id,
                        file: paramProject.request.styleReffile.file
                    }, 'styleReference');
                }
            }

            if (paramProject.tool) {
                setSelectedModelId(paramProject.tool.id);
            }
        }
    }, [paramProject, isparamProjectLoading, setValue, setSelectedModelId, handleImageClick, setSelectedImages, AIStyle_Controlnet]);

    useEffect(() => {
        setValue('_img2img_bool', !!selectedImages['imgToImg']);
    }, [selectedImages, setValue]);

    useEffect(() => {
        if (!initialAiLoading && initialAiImages) {
            setAiImages(initialAiImages);
        }
        if (aiTool && aiTool.length > 0 && selectedModelId === null) {
            setSelectedModelId(aiTool[0].id);
        }
    }, [initialAiImages, initialAiLoading, aiTool, selectedModelId]);

    const handleModelChange = useCallback((modelId: number) => {
        setSelectedModelId(modelId);
    }, []);

    const handleProjectClick = useCallback((project: project) => {
        setSelectedProject(project);
        onProjectOpen();
    }, [onProjectOpen]);


    const handleControlNetSelect = useCallback((controlNet: ControlNet | null) => {
        const currentIds = getValues('_controlNetIds') || [];
        let newIds: number[];

        if (controlNet === null) {
            // controlNet이 null인 경우, 모든 선택을 해제
            newIds = [];
        } else {
            const index = currentIds.indexOf(controlNet.id);
            if (index > -1) {
                // 이미 선택된 경우, 해제
                newIds = currentIds.filter(id => id !== controlNet.id);
            } else {
                // 선택되지 않은 경우, 추가
                newIds = [...currentIds, controlNet.id];
            }
        }

        setValue('_controlNetIds', newIds, {shouldValidate: true});
    }, [setValue, getValues]);


    const onGenerateImage = useCallback(async (data: IForm) => {
        const requestData = {
            prompt: data._prompt,
            id: uuidv4(),
            cfg: data._cfgScale,
            negativePrompt: data._negativePrompt,
            height: data._height,
            width: data._width,
            steps: data._steps,
            batch_size: data._batchSize,
            tool_id: selectedModelId,
            fileID: selectedImages['imgToImg']?.id || null,
            img2img_bool: data._img2img_bool,
            styleReffile: selectedImages['styleReference']?.id || null,
            controlnet: data._controlNetIds,
        };

        await createPhotoMutation.mutateAsync(requestData as ICreateAIRequestVariables);
    }, [createPhotoMutation, selectedModelId, selectedImages]);

    return {
        state: {
            isSidebarOpen,
            aiImages,
            selectedModelId,
            selectedProject,
            isProjectOpen,
            userProjects,
            projectsLoading,
            projectsError,
            responseImages,
            createPhotoMutation,
            aiTool,
            AIStyle_Controlnet
        },
        formMethods,
        handleModelChange,
        handleProjectClick,
        handleControlNetSelect,
        onGenerateImage,
        onProjectClose
    };
};


const MainContent = () => {
    const {state, handleProjectClick, formMethods} = useGenerateAIContext();
    const {isSidebarOpen, responseImages, userProjects, projectsLoading, projectsError, createPhotoMutation} = state;

    return (
        <Box flex={isSidebarOpen ? "0.65" : "1"} transition="flex 0.3s ease" pl={10}>
            <ImageTabs
                responseImages={responseImages}
                userProjects={userProjects}
                projectsLoading={projectsLoading}
                projectsError={projectsError}
                onProjectClick={handleProjectClick}
                batchSize={formMethods.getValues('_batchSize')}
                createPhotoMutation={createPhotoMutation}
            />
        </Box>
    );
};


const SidebarContent = () => {
    const {state, formMethods, onGenerateImage, handleModelChange, handleControlNetSelect} = useGenerateAIContext();
    const {aiImages, isSidebarOpen, createPhotoMutation, aiTool, selectedModelId, AIStyle_Controlnet} = state;

    return (
        <GenerateSidebar
            aiImages={aiImages}
            isSidebarOpen={isSidebarOpen}
            formMethods={formMethods}
            onGenerateImage={onGenerateImage}
            createPhotoMutation={createPhotoMutation}
            aiTool={aiTool}
            selectedModelId={selectedModelId}
            onModelChange={handleModelChange}
            AIStyle_Controlnet={AIStyle_Controlnet}
            onControlNetSelect={handleControlNetSelect}
        />
    );
};


const ProjectModalWrapper = () => {
    const {state, onProjectClose} = useGenerateAIContext();
    const {selectedProject, isProjectOpen} = state;

    return selectedProject ? (
        <ProjectModal
            isOpen={isProjectOpen}
            onClose={onProjectClose}
            projectData={selectedProject}
        />
    ) : null;
};


export default function GenerateAI() {
    const location = useLocation();
    const projectId = new URLSearchParams(location.search).get('Id');
    const toolId = new URLSearchParams(location.search).get('ToolId');
    const generateAIState = useGenerateAIState(projectId, toolId);

    return (
        <GenerateAIProvider value={generateAIState}>
            <Flex>
                <MainContent/>
                <SidebarContent/>
                <ProjectModalWrapper/>
            </Flex>
        </GenerateAIProvider>
    );
}