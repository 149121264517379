import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { Box, Text, Slider, SliderTrack, SliderFilledTrack, SliderThumb, Input, Flex } from '@chakra-ui/react';
import { UseFormRegister, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import { IForm } from "../../interface/IForm"
import { debounce } from 'lodash';

interface StepsSliderProps {
  register: UseFormRegister<IForm>;
  setValue: UseFormSetValue<IForm>;
  watch: UseFormWatch<IForm>;
}

const StepsSlider: React.FC<StepsSliderProps> = ({ register, setValue, watch }) => {
  const watchedSteps = watch('_steps');
  const [localSteps, setLocalSteps] = useState(watchedSteps);
  const [inputSteps, setInputSteps] = useState(watchedSteps.toString());

  const debouncedSetFormValue = useMemo(
    () => debounce((value: number) => {
      setValue('_steps', value);
    }, 200),
    [setValue]
  );

  useEffect(() => {
    setLocalSteps(watchedSteps);
    setInputSteps(watchedSteps.toString());
  }, [watchedSteps]);

  const handleSliderChange = useCallback((value: number) => {
    setLocalSteps(value);
    setInputSteps(value.toString());
    debouncedSetFormValue(value);
  }, [debouncedSetFormValue]);

  const handleInputChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    // Allow any number input, but limit to 2 digits
    if (/^\d{0,2}$/.test(value)) {
      setInputSteps(value);
      const numValue = parseInt(value, 10);
      if (!isNaN(numValue)) {
        // Use setTimeout to delay the update of localSteps and form value
        setTimeout(() => {
          setLocalSteps(numValue);
          debouncedSetFormValue(numValue);
        }, 200);
      }
    }
  }, [debouncedSetFormValue]);

  const handleInputBlur = useCallback(() => {
    let numValue = parseInt(inputSteps, 10);
    if (isNaN(numValue) || numValue < 1) {
      numValue = 1;
    } else if (numValue > 50) {
      numValue = 50;
    }
    setLocalSteps(numValue);
    setInputSteps(numValue.toString());
    setValue('_steps', numValue);  // Immediately update form value on blur
  }, [inputSteps, setValue]);

  return (
    <Box p={4} border="1px" borderColor="gray.600" borderRadius="md" mb={4} width="100%">
      <Text mb={2}>Steps</Text>
      <Slider
        min={1}
        max={50}
        step={1}
        value={localSteps}
        onChange={handleSliderChange}
        colorScheme="pink"
      >
        <SliderTrack>
          <SliderFilledTrack />
        </SliderTrack>
        <SliderThumb boxSize={6} />
      </Slider>
      <Flex justify="center" align="center" mt={2}>
        <Text mr={2}>Current Steps:</Text>
        <Input
          value={inputSteps}
          onChange={handleInputChange}
          onBlur={handleInputBlur}
          textAlign="center"
          width="60px"
        />
      </Flex>
      <input type="hidden" {...register('_steps')} />
    </Box>
  );
};

export default StepsSlider;