import React from "react";
import {
    AspectRatio,
    Box,
    Flex,
    Image,
    Menu,
    MenuButton, MenuItem,
    MenuList,
    Text,
    useColorModeValue, useDisclosure,
    useToast
} from "@chakra-ui/react";
import {useAddProjectToSequence, useAddToolToSequence} from "../../hook/HSequence";
import {Projects} from "../../interface/Iproject";
import {AITool} from "../../interface/IAITool";
import ModelModal from "../../components/ModelModal";
import useCustomToast from "../../hook/HToast";

interface ToolCardProps {
    tool: AITool;
    onSettingsClick: () => void;
    sequence_id: string;  // Changed from sequenceId to sequence_id
}

export const ToolCard: React.FC<ToolCardProps> = ({tool, onSettingsClick, sequence_id}) => {
    const overlayBg = useColorModeValue('rgba(255, 255, 255, 0.7)', 'rgba(55, 55, 55, 0.3)');
    const textColor = useColorModeValue('gray.800', 'white');
    const fallbackImageUrl = "https://via.placeholder.com/400x400?text=No+Image";
    const toast = useToast();
    const addToolToSequenceMutation = useAddToolToSequence();
    const {isOpen: isDrawerOpen, onOpen: onDrawerOpen, onClose: onDrawerClose} = useDisclosure();
    const {showToast} = useCustomToast();

    const handleAddToStorage = () => {
        addToolToSequenceMutation.mutate(
            {sequence_id, tool_id: tool.id},
            {
                onSuccess: () => {
                    showToast("Tool added to sequence storage", "success");

                },
                onError: (error) => {
                    showToast("Failed to add tool to sequence", "error");
                }
            }
        );
    };
    return (
        <Box position="relative">

            <Menu>
                <MenuButton as={Box} cursor="pointer" transition="all 0.3s"
                            _hover={{transform: 'scale(1.05)', boxShadow: 'lg'}}>
                    <Box
                        borderRadius="lg"
                        overflow="hidden"
                        boxShadow="md"

                        position="relative"
                    >
                        <AspectRatio ratio={1}>
                            <Image
                                src={tool.file?.file ?? fallbackImageUrl}
                                alt=""
                                objectFit="cover"
                            />
                        </AspectRatio>
                        <Flex
                            position="absolute"
                            bottom="0"
                            left="0"
                            right="0"
                            height="33.33%"
                            bg={overlayBg}
                            alignItems="left"
                            justifyContent="center"
                            padding="2"
                        >
                            {/*<Text*/}
                            {/*    fontWeight="light"*/}
                            {/*    fontSize="small"*/}
                            {/*    color={textColor}*/}
                            {/*    textAlign="center"*/}
                            {/*    noOfLines={1}*/}
                            {/*>*/}
                            {/*    {project.title}*/}
                            {/*</Text>*/}
                        </Flex>
                    </Box>
                </MenuButton>
                <MenuList>
                    <MenuItem onClick={onDrawerOpen}>상세 정보</MenuItem>
                    <MenuItem onClick={handleAddToStorage}>Input Storage</MenuItem>
                    <MenuItem onClick={onSettingsClick}>Generate</MenuItem>
                </MenuList>
            </Menu>
            <ModelModal isOpen={isDrawerOpen} onClose={onDrawerClose} modelData={tool}/>

        </Box>

    );
};
