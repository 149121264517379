import {useQuery, useMutation, useQueryClient} from '@tanstack/react-query';
import {
    getUserCollections,
    createCollection,
    addProjectToCollection,
    removeProjectFromCollection,
    getCollectionDetails, removeCollection, renameCollection,
} from '../api/ACollection'
import {useToast} from '@chakra-ui/react';
import {Collection} from "../interface/ICollection";
import {removeProjectFromSequence} from "../api/ASequence";

// 사용자의 모든 컬렉션을 가져오는 hook
export const useCollections = (userId: any) => {
    return useQuery({
        queryKey: ['collections', userId],
        queryFn: () => getUserCollections(userId),
        enabled: !!userId, // userId가 유효할 때만 쿼리 실행

    });
};

// 새로운 컬렉션을 생성하는 hook
interface useCreateCollectionProps {
    onSuccess?: () => void;
}

export const useCreateCollection = ({onSuccess}: useCreateCollectionProps = {}) => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (newCollection: { name: string; description: string }) => createCollection(newCollection),
        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: ['collections']});
            if (onSuccess) {
                onSuccess();
            }
        },
    });
};

export const useRemoveCollection = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: removeCollection,
        onSuccess: (data, variables) => {
            // 성공 시 관련 쿼리 무효화
            queryClient.invalidateQueries({queryKey: ['collections']});
        },
    });
};

export const useRenameCollection = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: ({id, name}: { id: number | string; name: string }) => renameCollection(id, name),
        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: ['collections']});
        },
    });
};

export const useAddProjectToCollection = () => {
    const queryClient = useQueryClient();
    const toast = useToast();

    return useMutation({
        mutationFn: ({collectionId, projectId}: { collectionId: string; projectId: string }) =>
            addProjectToCollection(collectionId, projectId),
        onSuccess: (data, variables) => {
            queryClient.invalidateQueries({queryKey: ['collections', variables.collectionId]});
            toast({
                title: "Success",
                description: "Iproject added to collection successfully",
                status: "success",
                duration: 3000,
                isClosable: true,
            });
        },
        onError: (error: any) => {
            if (error.response?.data?.error === "This project is already in the collection.") {
                toast({
                    title: "Error",
                    description: "This project is already in the collection",
                    status: "error",
                    duration: 3000,
                    isClosable: true,
                });
            } else {
                toast({
                    title: "Error",
                    description: "Failed to add project to collection",
                    status: "error",
                    duration: 3000,
                    isClosable: true,
                });
            }
        },
    });
};

// 프로젝트를 컬렉션에서 제거하는 hook
export const useRemoveProjectFromCollection = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: ({collectionId, projectId}: { collectionId: string; projectId: string | number | undefined }) =>
            removeProjectFromCollection(collectionId, projectId),
        onSuccess: (_, variables) => {
            queryClient.invalidateQueries({queryKey: ['collections', variables.collectionId]});
        },
    });
};

// 특정 컬렉션의 상세 정보를 가져오는 hook
export const useCollectionDetails = (collectionId: string) => {
    return useQuery({
        queryKey: ['collection', collectionId],
        queryFn: () => getCollectionDetails(collectionId),
    });
};