import {QueryFunctionContext} from "@tanstack/react-query";
import {instance, getCSRFHeader} from "./Abase";
import Cookie from "js-cookie";


export const getMe = () =>
    instance.get(`users/me`).then((response) => response.data);
export const getTokenBalance = () =>
    instance.get("token/token-balance").then((response) => response.data);


export const logOut = () =>
    instance
        .post(`users/log-out`, null, {
            headers: getCSRFHeader()

        })
        .then((response) => response.data);


export const githubLogIn = (code: string) =>
    instance
        .post(
            `users/github`,
            {code},
            {
                headers: getCSRFHeader()

            }
        )
        .then((response) => response.status);


export const kakaoLogin = (code: string) =>
    instance
        .post(
            `users/kakao`,
            {code},
            {
                headers: getCSRFHeader()

            }
        )
        .then((response) => response.status);


export const googleLogin = (code: string) =>
    instance
        .post(
            `users/google`,
            {code},
            {
                headers: {
                    "X-CSRFToken": Cookie.get("csrftoken") || "",
                },
            }
        )
        .then((response) => response.status);
