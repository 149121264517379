import React from 'react';
import {
    ChakraProvider,
    Box,
    VStack,
    HStack,
    Text,
    Button,
    Heading,
    Divider,
    Tabs,
    TabList,
    TabPanels,
    Tab,
    TabPanel,
    useColorModeValue,
    Badge,
    Container,
    SimpleGrid,
} from '@chakra-ui/react';
import {CookieIcon, CreditCard, Coffee} from 'lucide-react';
import {useThemeColors} from "../lib/Color";
import {containerMaxWidth, containerPadding} from "../lib/ResponsiveValue";

const CookieChargeOption = ({
                                amount,
                                price,
                                isPopular = false
                            }: { amount: number; price: string; isPopular?: boolean }) => (
    <Box
        borderWidth="1px"
        borderRadius="lg"
        p={4}
        _hover={{boxShadow: 'md', borderColor: 'blue.500'}}
        transition="all 0.2s"
        position="relative"
    >
        {isPopular && (
            <Badge colorScheme="green" position="absolute" top="-2" right="-2">
                인기
            </Badge>
        )}
        <HStack justifyContent="space-between" width="100%">
            <HStack>
                <CookieIcon size={24} color={useColorModeValue('orange.500', 'orange.300')}/>
                <Text fontWeight="bold">쿠키 {amount}개</Text>
            </HStack>
            <Button colorScheme="blue" variant="outline">
                {price}원
            </Button>
        </HStack>
    </Box>
);

const RegularChargePage = () => (
    <VStack spacing={6} align="stretch">
        <Heading size="md">쿠키 패키지</Heading>
        <VStack spacing={4} align="stretch">
            <CookieChargeOption amount={10} price="1,000"/>
            <CookieChargeOption amount={30} price="3,000"/>
            <CookieChargeOption amount={50} price="5,000" isPopular/>
            <CookieChargeOption amount={100} price="10,000"/>
            <Divider/>
            <CookieChargeOption amount={300} price="30,000"/>
            <CookieChargeOption amount={500} price="50,000"/>
            <CookieChargeOption amount={1000} price="100,000"/>
        </VStack>
    </VStack>
);


const SubscriptionPlan = ({
  name,
  cookies,
  price,
  isPopular = false
}: {
  name: string;
  cookies: number;
  price: string;
  isPopular?: boolean;
}) => (
  <Box
    borderWidth="1px"
    borderRadius="xl"
    p={6}
    _hover={{ boxShadow: 'xl', borderColor: 'green.500' }}
    transition="all 0.3s"
    position="relative"
    bg={useColorModeValue('white', 'gray.800')}
  >
    {isPopular && (
      <Badge
        colorScheme="green"
        position="absolute"
        top="-3"
        right="-3"
        fontSize="sm"
        px={3}
        py={1}
        borderRadius="full"
      >
        인기
      </Badge>
    )}
    <VStack align="stretch" spacing={6}>
      <Heading size="lg" textAlign="center" color={useColorModeValue('green.600', 'green.300')}>
        {name}
      </Heading>
      <Text fontSize="4xl" fontWeight="bold" textAlign="center">
        {price}
        <Text as="span" fontSize="lg" fontWeight="normal" color="gray.500">
          원/월
        </Text>
      </Text>
      <HStack justify="center" spacing={2}>
        <CookieIcon size={24} color={useColorModeValue('#ED8936', '#F6AD55')} />
        <Text fontSize="lg">
          월 <strong>{cookies}</strong> 쿠키
        </Text>
      </HStack>
      <Button
        colorScheme="green"
        size="lg"
        _hover={{ transform: 'translateY(-2px)', boxShadow: 'lg' }}
        transition="all 0.2s"
      >
        구독하기
      </Button>
    </VStack>
  </Box>
);
const Payment = () => {
    const {bgColor, textColor, borderColor, accentColor} = useThemeColors();

    return (
        <Box bg={bgColor} minH="100vh" py={6} mt={12}>
            <Container maxW={containerMaxWidth} px={containerPadding}>

                <Heading size="xl" mb={6}>Billing</Heading>
                <Tabs isFitted variant="soft-rounded" colorScheme="blue">
                    <TabList mb="1em">
                        <Tab _selected={{color: 'white', bg: 'blue.500'}}>일반충전</Tab>
                        <Tab _selected={{color: 'white', bg: 'green.500'}}>구독결제</Tab>
                    </TabList>
                    <TabPanels>
                        <TabPanel>
                            <RegularChargePage/>
                        </TabPanel>
                        <TabPanel>
                            <SimpleGrid columns={{base: 1, md: 2}} spacing={8}>
                                <SubscriptionPlan name="무료 플랜" cookies={100} price="9,000"/>
                                <SubscriptionPlan name="프리미엄 플랜" cookies={300} price="25,000" isPopular/>
                            </SimpleGrid>
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            </Container>

        </Box>
    );
};

export default Payment;