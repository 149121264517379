import React, {useEffect, useRef, useState} from "react";
import {
    Box,
    SimpleGrid,
    Text,
    Image,
    Heading,
    AspectRatio,
    useBreakpointValue,
    Skeleton,
    SkeletonText,
    Container,
    Button,
    VStack,
    useColorModeValue,
    Flex,
    Icon,
    keyframes,
    usePrefersReducedMotion,
} from '@chakra-ui/react';
import {ChevronDownIcon} from '@chakra-ui/icons';
import PinImage from "../components/PinImage";
import {useAllTool} from "../hook/HAITool";
import {useThemeColors} from '../lib/Color';
import {AITool} from "../interface/IAITool";
import {useNavigate} from 'react-router-dom';


const bounceAnimation = keyframes`
    0%, 20%, 50%, 80%, 100% { transform: translateY(0); }
    40% { transform: translateY(-30px); }
    60% { transform: translateY(-15px); }
`;

export default function Home() {
    const navigate = useNavigate();

    const {data: aiTools, isLoading: aiToolLoading, error: aiToolError} = useAllTool();
    const {bgColor, textColor, borderColor, cardBgColor, accentColor} = useThemeColors();

    const columns = useBreakpointValue({base: 2, sm: 3, md: 4, lg: 5});

    const [currentSection, setCurrentSection] = useState(0);
    const sections = useRef<(HTMLDivElement | null)[]>([]);
    const lastSectionRef = useRef<HTMLDivElement>(null);
    const totalSections = 3; // 총 섹션 수

    const [lastSectionScrollTop, setLastSectionScrollTop] = useState(0);
    const [isScrollingToLastSection, setIsScrollingToLastSection] = useState(false);

    useEffect(() => {
        const handleWheel = (e: WheelEvent) => {
            if (currentSection === totalSections - 1) {
                const lastSection = lastSectionRef.current;
                if (lastSection) {
                    const {scrollTop, scrollHeight, clientHeight} = lastSection;

                    // 마지막 섹션의 맨 위에서 위로 스크롤하는 경우
                    if (scrollTop === 0 && e.deltaY < 0) {
                        e.preventDefault();
                        setCurrentSection(totalSections - 2);
                        return;
                    }

                    // 마지막 섹션 내에서의 스크롤 위치 저장
                    setLastSectionScrollTop(scrollTop);
                    return;
                }
            }

            e.preventDefault();
            const direction = e.deltaY > 0 ? 1 : -1;

            setCurrentSection(prev => {
                const next = prev + direction;

                // 첫 번째 섹션에서 위로 스크롤하는 경우
                if (prev === 0 && direction < 0) {
                    return prev;
                }

                // 마지막 섹션으로 진입하는 경우
                if (next === totalSections - 1) {
                    setIsScrollingToLastSection(true);
                    return next;
                }

                // 마지막 섹션에서 벗어나는 경우
                if (prev === totalSections - 1 && direction < 0) {
                    return totalSections - 2;
                }

                setIsScrollingToLastSection(false);
                return Math.max(0, Math.min(next, totalSections - 1));
            });
        };

        window.addEventListener('wheel', handleWheel, {passive: false});
        return () => window.removeEventListener('wheel', handleWheel);
    }, [currentSection, totalSections]);

    useEffect(() => {
        if (currentSection === totalSections - 1 && !isScrollingToLastSection) {
            lastSectionRef.current?.scrollIntoView({behavior: 'smooth'});
            if (isScrollingToLastSection) {
                // 마지막 섹션으로 처음 진입할 때는 스크롤을 맨 위로
                setTimeout(() => {
                    if (lastSectionRef.current) {
                        lastSectionRef.current.scrollTop = 0;
                    }
                    setIsScrollingToLastSection(false);
                }, 100);
            } else {
                // 마지막 섹션으로 이동할 때 저장된 스크롤 위치로 이동
                setTimeout(() => {
                    if (lastSectionRef.current) {
                        lastSectionRef.current.scrollTop = lastSectionScrollTop;
                    }
                }, 100);
            }
        } else {
            sections.current[currentSection]?.scrollIntoView({behavior: 'smooth'});
        }
    }, [currentSection, totalSections, lastSectionScrollTop, isScrollingToLastSection]);


    const scrollToNextSection = () => {
        setCurrentSection(prev => Math.min(prev + 1, totalSections - 1));
    };
    const SkeletonItem = () => (
        <Box bg="gray.800" borderRadius="md" overflow="hidden">
            <AspectRatio ratio={8 / 12}>
                <Skeleton/>
            </AspectRatio>
            <Box p={4}>
                <Skeleton height="20px" width="80%" mb={2}/>
                <SkeletonText noOfLines={2} spacing="2"/>
            </Box>
        </Box>
    );
    const renderSkeletons = (count: number) => {
        return Array(count).fill(0).map((_, index) => (
            <SkeletonItem key={index}/>
        ));
    };

    const handleClick = () => {
        navigate('/generateai');
    };

    const featuredCards = [
        {
            title: "Generate Image",
            description: "Create stunning AI-generated images with ease",
            buttonText: "Generate Image →",
            imageSrc: "https://via.placeholder.com/300x120?text=AI+Image+Generation"
        },
        {
            title: "Generate Project",
            description: "Kickstart your project with AI-powered templates",
            buttonText: "Generate Project →",
            imageSrc: "https://via.placeholder.com/300x120?text=AI+Project+Generation"
        },
        {
            title: "Community",
            description: "Join our vibrant AI enthusiasts community",
            buttonText: "Explore Community →",
            imageSrc: "https://via.placeholder.com/300x120?text=AI+Community"
        }
    ];
    return (
        <Box color="white">
            <Box
                ref={el => sections.current[0] = el!}
                height="100vh"
                bg={bgColor}
                position="relative"
                overflow="hidden"
            >
                <Flex
                    height="100%"
                    alignItems="center"
                    justifyContent="center"
                    flexDirection="column"
                >
                    <VStack spacing={8} align="center">
                        <Heading
                            as="h1"
                            size="4xl"
                            textAlign="center"
                            bgGradient={`linear(to-r, ${accentColor}, purple.500)`}
                            bgClip="text"
                            fontWeight="extrabold"
                        >
                            MOTIVE AI
                        </Heading>
                        <Button
                            bg={accentColor}
                            color="white"
                            size="lg"
                            width="300px"
                            height="60px"
                            _hover={{bg: `${accentColor}80`}}
                            onClick={handleClick}
                        >
                            Explore AI-Powered Creativity
                        </Button>
                    </VStack>
                    <Icon
                        as={ChevronDownIcon}
                        w={16}
                        h={16}
                        color={accentColor}
                        cursor="pointer"
                        position="absolute"
                        bottom="60px"
                        onClick={scrollToNextSection}
                        animation={`${bounceAnimation} 2s infinite`}
                    />
                </Flex>
            </Box>

            <Flex
                ref={el => el && (sections.current[1] = el)}
                bg={bgColor}
                minHeight="100vh"
                alignItems="center"
                justifyContent="center"
                px={12} // 양쪽에 패딩 추가
            >
                <Container maxW="container.xl">
                    <VStack spacing={8} align="stretch">
                        <Heading textAlign="center" size="xl">
                            The easiest way to generate with AI
                        </Heading>
                        <SimpleGrid columns={3} spacing={6} mt={12}>
                            {aiToolLoading ? renderSkeletons(3) : (
                                featuredCards.map((card, index) => (
                                    <Box
                                        key={index}
                                        borderRadius="lg"
                                        overflow="hidden"
                                        boxShadow="md"
                                        position="relative"
                                        height="250px"
                                    >
                                        <Image
                                            src={card.imageSrc}
                                            alt={card.title}
                                            objectFit="cover"
                                            width="100%"
                                            height="100%"
                                        />
                                        <Box
                                            position="absolute"
                                            top="0"
                                            left="0"
                                            right="0"
                                            bottom="0"
                                            bg="linear-gradient(to bottom, rgba(0,0,0,0.1), rgba(0,0,0,0.7))"
                                            p={4}
                                            display="flex"
                                            flexDirection="column"
                                            justifyContent="flex-end"
                                        >
                                            <Heading size="md" mb={2}>{card.title}</Heading>
                                            <Text fontSize="sm" mb={4}>{card.description}</Text>
                                            <Button size="sm" colorScheme="whiteAlpha" alignSelf="flex-start">
                                                {card.buttonText}
                                            </Button>
                                        </Box>
                                    </Box>
                                ))
                            )}
                        </SimpleGrid>


                        <Heading size="lg">Models</Heading>
                        <SimpleGrid columns={4} spacing={4}>
                            {aiToolLoading ? renderSkeletons(4) : (
                                Array.isArray(aiTools) && aiTools.length > 0 ?
                                    aiTools.slice(0, 4).map((tool: AITool) => (
                                        <Box
                                            key={tool.id}
                                            borderRadius="md"
                                            overflow="hidden"
                                            position="relative"
                                            height="200px"
                                        >
                                            <Image
                                                src={tool.file?.file || "https://via.placeholder.com/300x200"}
                                                alt={tool.ai_model}
                                                objectFit="cover"
                                                width="100%"
                                                height="100%"
                                            />
                                            <Box
                                                position="absolute"
                                                top="0"
                                                left="0"
                                                right="0"
                                                bottom="0"
                                                bg="rgba(0,0,0,0.4)"
                                                p={4}
                                                display="flex"
                                                flexDirection="column"
                                                justifyContent="space-between"
                                            >
                                                <VStack align="start" spacing={1}>
                                                    <Text fontWeight="bold" fontSize="lg">{tool.ai_model}</Text>
                                                    <Text fontSize="sm">{tool.ai_app}</Text>
                                                </VStack>
                                                <Button size="sm" colorScheme="whiteAlpha">
                                                    Generate
                                                </Button>
                                            </Box>
                                        </Box>
                                    ))
                                    : <Text>No AI tools available</Text>
                                //     TOOD 스켈레톤
                            )}
                        </SimpleGrid>
                    </VStack>
                </Container>
            </Flex>


            <Flex
                ref={lastSectionRef}
                bg={bgColor}
                minHeight="100vh"
                flexDirection="column"
                px={12}
                py={8}
                overflowY="auto"
            >
                <Container maxW="container.xl">
                    <VStack spacing={8} align="stretch">
                        <Heading textAlign="center" size="xl">
                            Explore AI-Generated Projects
                        </Heading>
                        <PinImage/>
                    </VStack>
                </Container>
            </Flex>
        </Box>
    )
        ;
}