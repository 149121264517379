import {instance, getCSRFHeader} from "./Abase";
import {useSequenceDetail} from "../hook/HSequence";

// Types
interface CreateSequenceData {
    name: string;
    description: string;
}


// Types
interface CreateSequenceData {
    name: string;
    description: string;
}

interface ItemToSequenceData {
    sequence_id: string;
    item_id?: string | number;  // item_id를 선택적으로 변경
    item_type: 'project' | 'prompt' | 'aiinputimage' | 'aitool' | 'memo'| string;
    memo_content?: string;  // 메모 내용 추가

}

export const getAllSequence = () =>
    instance.get("sequence/allSequence").then((response) => response.data);

export const getSequenceDetail = (SequenceId: string | any) =>
    instance.get(`sequence/${SequenceId}`).then((response) => response.data);

export const postCreateSequence = (data: CreateSequenceData) =>
    instance.post(
        `sequence/allSequence`,
        data,
        {
            headers: getCSRFHeader()
        }
    ).then((response) => response.data);

export const addItemToSequence = (data: ItemToSequenceData) =>
    instance.post(
        `sequence/item-to-sequence`,
        data,
        {
            headers: getCSRFHeader()
        }
    ).then((response) => response.data);

export const removeItemFromSequence = (data: ItemToSequenceData) =>
    instance.delete(
        `sequence/item-to-sequence`,
        {
            headers: getCSRFHeader(),
            data: data
        }
    ).then((response) => response.data);

// 기존 함수들을 새로운 통합 함수를 사용하도록 수정
export const postAddPromptToSequence = (data: {sequence_id: string, prompt_id: string | number}) =>
    addItemToSequence({...data, item_id: data.prompt_id, item_type: 'prompt'});

export const removePromptFromSequence = (data: {sequence_id: string, prompt_id: string | number}) =>
    removeItemFromSequence({...data, item_id: data.prompt_id, item_type: 'prompt'});

export const postAddInputImageToSequence = (data: {sequence_id: string, inputImage_id: string | number}) =>
    addItemToSequence({...data, item_id: data.inputImage_id, item_type: 'aiinputimage'});

export const removeInputImageFromSequence = (data: {sequence_id: string, inputImage_id: string | number}) =>
    removeItemFromSequence({...data, item_id: data.inputImage_id, item_type: 'aiinputimage'});

export const postAddToolToSequence = (data: {sequence_id: string, tool_id: string | number}) =>
    addItemToSequence({...data, item_id: data.tool_id, item_type: 'aitool'});

export const removeToolFromSequence = (data: {sequence_id: string, tool_id: string | number}) =>
    removeItemFromSequence({...data, item_id: data.tool_id, item_type: 'aitool'});

export const postAddProjectToSequence = (data: {sequence_id: string, project_id: string | number}) =>
    addItemToSequence({...data, item_id: data.project_id, item_type: 'project'});

export const removeProjectFromSequence = (data: {sequence_id: string, project_id: string | number}) =>
    removeItemFromSequence({...data, item_id: data.project_id, item_type: 'project'});


// 메모 추가 함수
export const addMemoToSequence = (data: { sequence_id: string, memo_content: string }) =>
    addItemToSequence({ ...data, item_type: 'memo' });

// 메모 삭제 함수
export const removeMemoFromSequence = (data: { sequence_id: string, item_id: string | number }) =>
    removeItemFromSequence({ ...data, item_type: 'memo' });


interface ReorderItemsVariables {
    sequence_id: string;
    item_orders: { id: string; order: number }[];
}
export const reorderItems  = ({ sequence_id, item_orders }: ReorderItemsVariables) =>
    instance.post(
        `sequence/reorder-items`,
        { sequence_id, item_orders },
        {
            headers: getCSRFHeader()
        }
    ).then((response) => response.data);



export const editMemoInSequence = ({ sequence_id, memo_id, content } : any) =>
  instance.put(
    `sequence/${sequence_id}/memo/${memo_id}/`,
    { content },
    { headers: getCSRFHeader() }
  ).then(response => response.data);

interface PromptToSequenceData {
    sequence_id: string;
    prompt_id: string | number;
    prompt_content?: string[];  // string[]을 추가하고 string도 허용
}
// 프롬프트 내용 수정 함수 추가

// 프롬프트 추가/수정을 위한 통합 함수
export const promptToSequence = (data: PromptToSequenceData) =>
    instance.post(
        `sequence/item-to-sequence`,
        {
            sequence_id: data.sequence_id,
            item_id: data.prompt_id,
            item_type: 'prompt',
            ...(data.prompt_content && {
                prompt_content: Array.isArray(data.prompt_content)
                    ? data.prompt_content
                    : [data.prompt_content]  // 문자열인 경우 배열로 변환
            })
        },
        {
            headers: getCSRFHeader()
        }
    ).then((response) => response.data);