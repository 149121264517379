import {SystemStyleObject, useBreakpointValue} from '@chakra-ui/react'
import {ResponsiveValue} from '@chakra-ui/react'



export const containerMaxWidth: ResponsiveValue<string> = {
    base: "100%",
    sm: "540px",
    md: "768px",
    lg: "960px",
    xl: "1200px",
    "2xl": "1600px"  // 이 값을 조정하여 원하는 최대 너비로 설정
};
export const containerPadding: ResponsiveValue<string> = {
    base: '1rem',
    sm: '2rem',
    md: '3rem',
    lg: '4rem',
    xl: '5rem'
}
export const useIsMobile = () => {
  const isMobile = useBreakpointValue({ base: true, md: false });
  return isMobile;
};
export const gridColumns: ResponsiveValue<number> = {
    base: 1,
    sm: 1,
    md: 2,
    lg: 3,
    xl: 4
}

export const gridSpacing: ResponsiveValue<number> = {
    base: 4,
    sm: 6,
    md: 8
}

export const headingSize: ResponsiveValue<string> = {
    base: "xl", md: "2xl"
}


export const titleSize: ResponsiveValue<string> = {
    base: "2xl", md: "3xl", lg:"4xl"
}


export const SmallSidebarWidth: ResponsiveValue<string> = {
   base: "250px", md: "350px"
}
export const SidebarWidth: ResponsiveValue<string> = {
   base: "100%", md: "450px"
}

export const ProjectModalSidebarWidth: ResponsiveValue<string> = {
   base: "100%", md: "450px"
}