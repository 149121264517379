import React from "react";
import {
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    VStack,
    Text,
    Box,
    useColorModeValue,
    Heading,
    Flex,
} from "@chakra-ui/react";
import SocialLogin from "./SocialLogin";
import {motion} from "framer-motion";
import {useThemeColors} from "../lib/Color";

interface LoginModalProps {
    isOpen: boolean;
    onClose: () => void;
}

const MotionBox = motion(Box);

export default function LoginModal({isOpen, onClose}: LoginModalProps) {

    const {bgColor, textColor, accentColor} = useThemeColors();

    return (
        <Modal onClose={onClose} isOpen={isOpen} size="md" isCentered>
            <ModalOverlay
                bg="blackAlpha.300"
                backdropFilter="blur(4px)"
            />
            <ModalContent
                bg={bgColor}
                borderRadius="lg"
                boxShadow="xl"
                mx="auto"
                my="auto"
                maxWidth="35%"
            >
                <Flex direction="column" align="center" justify="center" height="100%">
                    <MotionBox
                        initial={{opacity: 0, y: -20}}
                        animate={{opacity: 1, y: 0}}
                        transition={{duration: 0.3}}
                        width="100%"
                    >
                        <ModalHeader
                            textAlign="center"
                            fontSize="2xl"
                            fontWeight="bold"
                            color={textColor}
                        >
                            <Heading size="lg">
                                Welcome to Motive AI 🚀
                            </Heading>
                        </ModalHeader>
                    </MotionBox>
                    <ModalCloseButton/>
                    <ModalBody>
                        <VStack spacing={6} align="stretch" py={4}>
                            <Text
                                textAlign="center"
                                color={textColor}
                                fontSize="md"
                            >
                                Join our AI-powered community! 🤖✨
                            </Text>
                            <MotionBox
                                initial={{opacity: 0, y: 20}}
                                animate={{opacity: 1, y: 0}}
                                transition={{duration: 0.5, delay: 0.2}}
                            >
                                <Text
                                    textAlign="center"
                                    color={accentColor}
                                    fontSize="sm"
                                    fontWeight="bold"
                                    mb={4}
                                >
                                    Get started with your favorite social account:
                                </Text>
                                <SocialLogin/>
                            </MotionBox>
                            <Text
                                textAlign="center"
                                color={textColor}
                                fontSize="xs"
                                mt={0}
                            >
                                By signing in, you agree to our Terms of Service and Privacy Policy
                            </Text>
                        </VStack>
                    </ModalBody>
                </Flex>
            </ModalContent>
        </Modal>
    );
}

// export default function LoginModal({ isOpen, onClose }: LoginModalProps) {
//   const {
//     register,
//     handleSubmit,
//     formState: { errors },
//     reset,
//   } = useForm<IForm>();
//
//   const toast = useToast();
//   const queryClient = useQueryClient();
//   const mutation = useMutation({
//     mutationFn: usernameLogIn,
//     onSuccess: () => {
//       toast({
//         title: "Welcome",
//         status: "success",
//       });
//       onClose();
//       queryClient.refetchQueries({ queryKey: ["me"] });
//       reset();
//
//     },
//   });
//
//   const onSubmit = ({ username, password }: IForm) => {
//     mutation.mutate({ username, password });
//   };
//
//   return (
//     <Modal onClose={onClose} isOpen={isOpen}>
//       <ModalOverlay />
//       <ModalContent>
//         <ModalHeader>Log in</ModalHeader>
//         <ModalCloseButton />
//         <ModalBody as="form" onSubmit={handleSubmit(onSubmit)}>
//           {/*<VStack>*/}
//           {/*  <InputGroup size={"md"}>*/}
//           {/*    <InputLeftElement*/}
//           {/*      children={*/}
//           {/*        <Box color="gray.500">*/}
//           {/*          <FaUserNinja />*/}
//           {/*        </Box>*/}
//           {/*      }*/}
//           {/*    />*/}
//           {/*    <Input*/}
//           {/*      isInvalid={Boolean(errors.username?.message)}*/}
//           {/*      {...register("username", {*/}
//           {/*        required: "Please write a username",*/}
//           {/*      })}*/}
//           {/*      variant={"filled"}*/}
//           {/*      placeholder="Username"*/}
//           {/*    />*/}
//           {/*  </InputGroup>*/}
//           {/*  <InputGroup>*/}
//           {/*    <InputLeftElement*/}
//           {/*      children={*/}
//           {/*        <Box color="gray.500">*/}
//           {/*          <FaLock />*/}
//           {/*        </Box>*/}
//           {/*      }*/}
//           {/*    />*/}
//           {/*    <Input*/}
//           {/*      isInvalid={Boolean(errors.password?.message)}*/}
//           {/*      {...register("password", {*/}
//           {/*        required: "Please write a password",*/}
//           {/*      })}*/}
//           {/*      type="password"*/}
//           {/*      variant={"filled"}*/}
//           {/*      placeholder="Password"*/}
//           {/*    />*/}
//           {/*  </InputGroup>*/}
//           {/*</VStack>*/}
//           {/*{mutation.isError ? (*/}
//           {/*  <Text color="red.500" textAlign={"center"} fontSize="sm">*/}
//           {/*    Username or Password are wrong*/}
//           {/*  </Text>*/}
//           {/*) : null}*/}
//           {/*<Button*/}
//           {/*  isLoading={mutation.isPending}*/}
//           {/*  type="submit"*/}
//           {/*  mt={4}*/}
//           {/*  colorScheme={"red"}*/}
//           {/*  w="100%"*/}
//           {/*>*/}
//           {/*  Log in*/}
//           {/*</Button>*/}
//           <SocialLogin/>
//         </ModalBody>
//       </ModalContent>
//     </Modal>
//   );
// }