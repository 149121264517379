import {Projects} from "../../interface/Iproject";
import React from "react";
import {useThemeColors} from "../../lib/Color";
import {
    Box,
    Text,
    Image,
    HStack,
    Icon,
    IconButton,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    useDisclosure,
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    Button,
    AspectRatio,
} from "@chakra-ui/react";
import {useRemoveProjectFromCollection} from "../../hook/HCollection";
import useCustomToast from "../../hook/HToast";
import {FaHeart, FaTimes, FaRegHeart} from "react-icons/fa";
import ProjectDrawer from "../../components/ProjectModal";
import {useAddProjectToSequence} from "../../hook/HSequence";

interface ProjectCardProps {
    project: Partial<Projects>;
    collectionId: string;
    onRefetch: () => void;

    onSettingsClick: any

    sequence_id: any
}

export const ProjectBookMarkProjectCard: React.FC<ProjectCardProps> = ({
                                                                           project,
                                                                           collectionId,
                                                                           onRefetch,
                                                                           onSettingsClick,
                                                                           sequence_id
                                                                       }) => {
    const {textColor, borderColor, bgColor, accentColor} = useThemeColors();
    const {isOpen: isAlertOpen, onOpen: onAlertOpen, onClose: onAlertClose} = useDisclosure();
    const {isOpen: isDrawerOpen, onOpen: onDrawerOpen, onClose: onDrawerClose} = useDisclosure();
    const cancelRef = React.useRef<HTMLButtonElement>(null);
    const removeProjectMutation = useRemoveProjectFromCollection();
    const {showToast} = useCustomToast();
    const addProjectToSequenceMutation = useAddProjectToSequence();


    const handleAddToStorage = () => {
        if (sequence_id && project.id) {
            addProjectToSequenceMutation.mutate({
                sequence_id: sequence_id,
                project_id: project.id
            }, {
                onSuccess: () => {
                    showToast("Project added to sequence storage", "success");
                },
                onError: (error) => {
                    showToast("Failed to add project to sequence", "error");
                }
            });
        }
    };

    const handleDelete = async () => {
        try {
            await removeProjectMutation.mutateAsync({collectionId, projectId: project.id});
            onRefetch();
            onAlertClose();
            showToast("프로젝트가 삭제되었습니다.", "success");
        } catch (error) {
            console.error("Failed to remove project:", error);
            showToast("프로젝트 삭제에 실패했습니다.", "error");
        }
    };

    return (
        <Box position="relative" width="100%" height="150px">
            <IconButton
                icon={<FaTimes/>}
                aria-label="Delete project"
                size="sm"
                position="absolute"
                top={2}
                right={2}
                onClick={onAlertOpen}
                _hover={{bg: 'hotpink', color: 'white'}}
                zIndex="2"
            />
            <Menu>
                <MenuButton as={Box} cursor="pointer" width="100%" height="100%">
                    <Box
                        borderWidth={1}
                        borderColor={borderColor}
                        borderRadius="lg"
                        overflow="hidden"
                        position="relative"
                        height="100%"
                        display="flex"
                        flexDirection="column"
                    >
                        <AspectRatio ratio={16 / 9} flex="1">
                            <Image src={project.response?.output_images?.[0]} alt={project.title} objectFit="cover"/>
                        </AspectRatio>
                        <Box p={2} bg={bgColor}>
                            <HStack justifyContent="space-between" alignItems="center">
                                <Text fontWeight="bold" color={textColor} isTruncated>{project.title}</Text>
                                <HStack>
                                    <Icon
                                        as={project?.like && project?.like > 0 ? FaHeart : FaRegHeart}
                                        color={project?.like && project?.like > 0 ? accentColor : "gray.300"}
                                    />
                                    <Text fontSize="sm" color={textColor}>{project.like ?? 0}</Text>
                                </HStack>
                            </HStack>
                        </Box>
                    </Box>
                </MenuButton>
                <MenuList>
                    <MenuItem onClick={onDrawerOpen}>상세 정보</MenuItem>
                    <MenuItem onClick={handleAddToStorage}>input project</MenuItem>

                </MenuList>
            </Menu>

            <AlertDialog
                isOpen={isAlertOpen}
                leastDestructiveRef={cancelRef}
                onClose={onAlertClose}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent bg={bgColor}>
                        <AlertDialogHeader fontSize="lg" fontWeight="bold">
                            프로젝트 삭제
                        </AlertDialogHeader>
                        <AlertDialogBody>
                            정말로 이 프로젝트를 삭제하시겠습니까? 이 작업은 되돌릴 수 없습니다.
                        </AlertDialogBody>
                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={onAlertClose}>
                                취소
                            </Button>
                            <Button colorScheme="red" onClick={handleDelete} ml={3}>
                                삭제
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>

            <ProjectDrawer
                isOpen={isDrawerOpen}
                onClose={onDrawerClose}
                projectData={project}
            />
        </Box>
    );
};