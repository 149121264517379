import React, { useCallback } from 'react';
import {Box, AspectRatio, Image, Text, Flex, Icon, Button, Skeleton} from "@chakra-ui/react";
import {FaHeart, FaRocket} from "react-icons/fa";
import {AITool} from "../interface/IAITool";
import {useThemeColors} from '../lib/Color';
import {useNavigate} from "react-router-dom";

interface AIModelCardProps {
    tool?: AITool;
    onClick?: (tool: AITool) => void;
}

const Model_Card: React.FC<AIModelCardProps> = React.memo(({ tool, onClick}) => {
    const { bgColor, textColor, accentColor, cardBgColor } = useThemeColors();
    const navigate = useNavigate();

    const handleRemix = useCallback((e: React.MouseEvent) => {
        e.stopPropagation();
        if (tool?.id) {
            navigate(`/generateai?ToolId=${tool.id}`);
        }
    }, [tool?.id, navigate]);

    if (!tool) return null;

    return (
        <Box
            borderRadius="lg"
            overflow="hidden"
            boxShadow="md"
            position="relative"
            onClick={() => onClick && onClick(tool)}
            cursor="pointer"
            height="100%"
        >
            <AspectRatio ratio={8 / 12}>
                <Box width="100%" height="100%" position="relative">
                    <Image
                        src={tool.file?.file || "https://via.placeholder.com/300x400"}
                        alt={tool.ai_model}
                        objectFit="cover"
                        width="100%"
                        height="100%"
                        loading="lazy"
                    />
                    <Box
                        position="absolute"
                        bottom="0"
                        left="0"
                        right="0"
                        p={2}
                        bg="rgba(0,0,0,0.5)"
                    >
                        <Text fontSize="sm" fontWeight="bold" color="white" noOfLines={1}>
                            {tool.ai_model}
                        </Text>
                        <Flex justify="space-between" align="center" mt={2}>
                            <Flex align="center">
                                <Icon as={FaHeart} color="white" boxSize={3} mr={1} />
                                <Text fontSize="xs" color="white">{tool.like}</Text>
                            </Flex>
                            <Button
                                leftIcon={<FaRocket />}
                                variant="outline"
                                size="xs"
                                color="white"
                                borderColor="white"
                                _hover={{ bg: `${accentColor}60` }}
                                onClick={handleRemix}
                            >
                                Remix
                            </Button>
                        </Flex>
                    </Box>
                    <Box position="absolute" top={2} left={2} bg="rgba(0,0,0,0.5)" px={2} py={1} borderRadius="md">
                        <Text fontSize="xs" color="white">{tool.ai_app}</Text>
                    </Box>
                </Box>
            </AspectRatio>
        </Box>
    );
});

export default Model_Card;