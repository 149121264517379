import {
    Box,
    Flex,
    VStack,
    Heading,
    Text,
    Image,
    Button,
    useTheme,
    SimpleGrid,
    Tabs,
    TabList,
    Tab,
    TabPanels,
    TabPanel, Spinner, Badge, Select,
    useColorModeValue, AspectRatio, MenuItem, MenuList, MenuButton, Menu, useToast, IconButton, HStack, Input, Textarea,
    useDisclosure, Skeleton, Icon, Grid, ListItem, List,


} from "@chakra-ui/react";
import {useQuery} from "@tanstack/react-query";
import {useParams} from "react-router-dom";
import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableContainer,
} from "@chakra-ui/react";
import {
    useAddProjectToSequence,
    useSequenceDetail,
    useRemoveProjectFromSequence,
    useRemoveMemoFromSequence,
    useAddMemoToSequence,
    useReorderItems,
    useEditMemo
} from "../hook/HSequence";
import {FaInfoCircle, FaImage, FaFileAlt, FaProjectDiagram, FaCog, FaBookmark, FaPlus, FaUsers} from "react-icons/fa";
import React, {useState, useCallback, useRef, useMemo, useEffect} from 'react';
import useUser from "../hook/useUser";
import {useUserProjects} from "../hook/HProject";
import {Projects} from "../interface/Iproject";
import {useThemeColors} from "../lib/Color";
import {Sequence, StorageItem} from "../interface/ISequence";
import {MdStorage} from "react-icons/md";
import {SequenceProject} from "../elements/ProjectDetail/SequenceProject";
import {AiFillLike} from "react-icons/ai";
import {PiGraphLight} from "react-icons/pi";
import {SequenceTool} from "../elements/ProjectDetail/SequenceTool";
import {SequencePrompt} from "../elements/ProjectDetail/SequencePrompt";
import {SequenceInputImage} from "../elements/ProjectDetail/SequenceInputImage";
import {StoragePanel} from "../elements/ProjectDetail/Panel/StoragePanel"
import {LikePanel} from "../elements/ProjectDetail/Panel/LikePanel";
import {PromptsPanel} from "../elements/ProjectDetail/Panel/PromptsPanel";
import {BookmarkPanel} from "../elements/ProjectDetail/Panel/BookmarkPanel";
import {CommunityPanel} from "../elements/ProjectDetail/Panel/CommunityPanel";
import {DragDropContext, Droppable, Draggable, DropResult} from 'react-beautiful-dnd';
import {CloseIcon, AddIcon, EditIcon, CheckIcon} from '@chakra-ui/icons';
import {motion} from "framer-motion";
import {gridColumns} from "../lib/ResponsiveValue";

const MotionBox = motion(Box);

type DisplayMode = 'horizontal' | 'grid' | 'list';

// 새로운 사이드바를 위한 컴포넌트
const RenderPanel = () => (
    <VStack align="stretch" spacing={4}>
        <Heading size="md">Settings</Heading>
        <Text>This is a placeholder for settings.</Text>
        {/* 여기에 설정 관련 컨트롤들을 추가할 수 있습니다 */}
    </VStack>
);

interface SequenceMemoProps {
    id: string;
    content: string;
    sequenceId: string;
    onRemoveSuccess: () => void;
    displayMode: 'horizontal' | 'grid' | 'list';
}

const SequenceMemo: React.FC<SequenceMemoProps> = ({id, content, sequenceId, onRemoveSuccess, displayMode}: any) => {
    const {cardBgColor, textColor, borderColor, accentColor} = useThemeColors();
    const removeMemoMutation = useRemoveMemoFromSequence();
    const addMemoMutation = useAddMemoToSequence();
    const editMemoMutation = useEditMemo();

    const toast = useToast();
    const [newMemo, setNewMemo] = useState('');
    const [isEditing, setIsEditing] = useState(false);
    const [editedContent, setEditedContent] = useState(content);
    const textareaRef = useRef<HTMLTextAreaElement>(null);

    useEffect(() => {
        if (isEditing && textareaRef.current) {
            textareaRef.current.style.height = 'auto';
            textareaRef.current.style.height = textareaRef.current.scrollHeight + 'px';
        }
    }, [isEditing, editedContent]);
    const handleRemove = () => {
        removeMemoMutation.mutate(
            {sequence_id: sequenceId, item_id: id},
            {
                onSuccess: () => {
                    onRemoveSuccess();
                    toast({title: "Memo removed", status: "success"});
                },
                onError: () => {
                    toast({title: "Failed to remove memo", status: "error"});
                }
            }
        );
    };
    const handleEditMemo = () => {
        editMemoMutation.mutate(
            {sequence_id: sequenceId, memo_id: id, content: editedContent},
            {
                onSuccess: () => {
                    setIsEditing(false);
                    toast({title: "Memo updated", status: "success"});
                },
                onError: () => {
                    toast({title: "Failed to update memo", status: "error"});
                }
            }
        );
    };


    const commonStyles = {
        borderWidth: 1,
        borderRadius: "lg",
        bg: cardBgColor,
        borderColor: borderColor,
        boxShadow: "md",
        position: "relative" as const,
        width: "100%",
        overflow: "hidden",
    };


    const renderEditControls = () => (
        <HStack spacing={2} justifyContent="flex-end" mt={2}>
            <IconButton
                aria-label="Save memo"
                icon={<CheckIcon/>}
                size="sm"
                onClick={handleEditMemo}
                variant="ghost"
            />
            <IconButton
                aria-label="Cancel edit"
                icon={<CloseIcon/>}
                size="sm"
                onClick={() => {
                    setIsEditing(false);
                    setEditedContent(content);
                }}
                variant="ghost"
            />
        </HStack>
    );

    const renderContent = () => (
        isEditing ? (
            <Textarea
                ref={textareaRef}
                value={editedContent}
                onChange={(e) => {
                    setEditedContent(e.target.value);
                    e.target.style.height = 'auto';
                    e.target.style.height = e.target.scrollHeight + 'px';
                }}
                placeholder="Edit memo..."
                resize="none"
                overflow="hidden"
                minHeight="50px"
            />
        ) : (
            <Text color={textColor}>{content}</Text>
        )
    );

    switch (displayMode) {
        case 'horizontal':
            return (
                <Box {...commonStyles} p={6}>
                    <Flex direction="column" height="100%">
                        <Text fontWeight="bold" fontSize="lg" mb={4} color={accentColor}>
                            Memo
                        </Text>
                        <Box flex={1}>
                            {renderContent()}
                            {isEditing && renderEditControls()}
                        </Box>
                    </Flex>
                    {!isEditing && (
                        <HStack spacing={2} position="absolute" top={2} right={2}>
                            <IconButton
                                aria-label="Edit memo"
                                icon={<EditIcon/>}
                                size="sm"
                                onClick={() => setIsEditing(true)}
                                variant="ghost"
                            />
                            <IconButton
                                aria-label="Remove memo"
                                icon={<CloseIcon/>}
                                size="sm"
                                onClick={handleRemove}
                                variant="ghost"
                            />
                        </HStack>
                    )}
                </Box>
            );

        case 'grid':
            return (
                <Box {...commonStyles} p={4} height="300px">
                    <Flex direction="column" height="100%">
                        <Text fontWeight="bold" mb={3} color={accentColor}>
                            Memo
                        </Text>
                        <Box flex={1} overflowY="auto">
                            {renderContent()}
                        </Box>
                        {isEditing ? renderEditControls() : (
                            <HStack spacing={2} justifyContent="flex-end" mt={2}>
                                <IconButton
                                    aria-label="Edit memo"
                                    icon={<EditIcon/>}
                                    size="sm"
                                    onClick={() => setIsEditing(true)}
                                    variant="ghost"
                                />
                                <IconButton
                                    aria-label="Remove memo"
                                    icon={<CloseIcon/>}
                                    size="sm"
                                    onClick={handleRemove}
                                    variant="ghost"
                                />
                            </HStack>
                        )}
                    </Flex>
                </Box>
            );

        case 'list':
            return (
                <Box {...commonStyles} p={4}>
                    <Flex direction="column">
                        <Flex justifyContent="space-between" alignItems="center" mb={2}>
                            <Text fontWeight="bold" color={accentColor}>Memo</Text>
                            {isEditing ? renderEditControls() : (
                                <HStack spacing={2}>
                                    <IconButton
                                        aria-label="Edit memo"
                                        icon={<EditIcon/>}
                                        size="sm"
                                        onClick={() => setIsEditing(true)}
                                        variant="ghost"
                                    />
                                    <IconButton
                                        aria-label="Remove memo"
                                        icon={<CloseIcon/>}
                                        size="sm"
                                        onClick={handleRemove}
                                        variant="ghost"
                                    />
                                </HStack>
                            )}
                        </Flex>
                        {renderContent()}
                    </Flex>
                </Box>
            );

        default:
            return null;
    }
};
export default function ProjectDetail() {
    const {SequenceId} = useParams<{ SequenceId: string }>();
    const {userLoading, user, isLoggedIn, tokenBalance} = useUser();
    const {bgColor, textColor, accentColor, borderColor, cardBgColor} = useThemeColors();

    const {data: sequenceDetail, isLoading: sequenceDetailLoading, refetch} = useSequenceDetail(SequenceId) as {
        data: Sequence;
        isLoading: boolean;
        refetch: () => void;
    };
    const {data: userProjects, isLoading: projectsLoading, error: projectsError} = useUserProjects(user);

    const [activePanel, setActivePanel] = useState('storage');
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);
    const [activeTab, setActiveTab] = useState(0);
    const theme = useTheme();
    const [memoInput, setMemoInput] = useState('');
    const addMemoMutation = useAddMemoToSequence();
    const toast = useToast();
    const reorderItemsMutation = useReorderItems();
    const [displayMode, setDisplayMode] = useState<DisplayMode>('horizontal');

    const panelInfo = [
        {type: 'storage', icon: MdStorage, component: StoragePanel},
        {type: 'collection', icon: FaBookmark, component: BookmarkPanel},
        {type: 'like', icon: AiFillLike, component: LikePanel},
        {type: 'prompts', icon: PiGraphLight, component: PromptsPanel},
        {type: 'community', icon: FaUsers, component: CommunityPanel},
    ];

    const handleTabChange = (index: number) => {
        setActiveTab(index);
    };

    const togglePanel = (type: string) => {
        if (activePanel === type) {
            setIsSidebarOpen(!isSidebarOpen);
        } else {
            setActivePanel(type);
            setIsSidebarOpen(true);
        }
    };

    const handleAddMemo = useCallback(() => {
        if (memoInput.trim()) {
            addMemoMutation.mutate(
                {sequence_id: sequenceDetail?.id, memo_content: memoInput},
                {
                    onSuccess: () => {
                        setMemoInput('');
                        refetch();
                        toast({title: "Memo added", status: "success"});
                    },
                    onError: () => {
                        toast({title: "Failed to add memo", status: "error"});
                    }
                }
            );
        }
    }, [memoInput, SequenceId, addMemoMutation, refetch, toast]);


    const renderItem = useCallback((item: StorageItem) => {
        if (!SequenceId) return null;

        const props = {
            sequenceId: SequenceId,
            onRemove: () => refetch(),
            displayMode: displayMode,
            onRemoveSuccess: () => refetch()
        };

        switch (item.item_type) {
            case 'project':
                return <SequenceProject project={item.item_data} {...props} />;
            case 'ai_tool':
                return <SequenceTool tool={item.item_data} {...props} />;
            case 'prompt':
                return <SequencePrompt prompt={item.item_data} {...props} />;
            case 'aiinputimage':
                return <SequenceInputImage image={item.item_data} {...props} />;
            case 'memo':
                return <SequenceMemo id={item.item_data.id} content={item.item_data.content} {...props} />;
            default:
                return null;
        }
    }, [SequenceId, refetch, displayMode]);


    const getGridColumns = useCallback(() => {
        if (isSidebarOpen) {
            return {base: 1, sm: 1, md: 2, lg: 2, xl: 3};
        } else {
            return {base: 1, sm: 2, md: 3, lg: 4, xl: 5};
        }
    }, [isSidebarOpen]);

    useEffect(() => {
        // 사이드바 상태가 변경될 때마다 강제로 리렌더링
    }, [isSidebarOpen]);


    const renderItems = () => {
        if (!sequenceDetail?.storage?.items) return null;

        const items = sequenceDetail.storage.items.map((item, index) => (
            <Box key={index} width="100%">
                {renderItem(item)}
            </Box>
        ));

        switch (displayMode) {
            case 'horizontal':
                return <VStack spacing={4} align="stretch" width="100%">{items}</VStack>;
            case 'grid':
                return (
                    <SimpleGrid
                        columns={getGridColumns()}
                        spacing={6}
                        px={4}
                        transition="all 0.3s ease"
                    >
                        {items}
                    </SimpleGrid>
                );
            case 'list':
                return <VStack spacing={2} align="stretch" width="100%">{items}</VStack>;
            default:
                return null;
        }
    };
    if (!sequenceDetail) {
        return (
            <Flex justify="center" align="center" minHeight="100vh" width="full" bg={bgColor}
            >
                <MotionBox
                    animate={{
                        scale: [1, 1.1, 1],
                        opacity: [1, 0.5, 1],
                    }}
                    transition={{
                        duration: 1.5,
                        repeat: Infinity,
                        repeatType: "reverse"
                    }}
                >
                    {/*<Circle size="16" bg={accentColor}/>*/}
                    <Box
                        width="64px"
                        height="64px"
                        borderRadius="50%"
                        border="3px solid"
                        borderColor={accentColor}
                    />
                </MotionBox>
            </Flex>
        )
    }
    return (
        <Flex bg={bgColor} color={textColor}>
            {/* Main Content */}
            <Box
                flex={1}
                p={10}
                ml={0}
                mr={isSidebarOpen ? "30%" : "60px"}
                transition="margin-right 0.3s ease"
                width={isSidebarOpen ? "70%" : "calc(100% - 60px)"}  // 사이드바 상태에 따라 너비 조정

                display="flex"
                flexDirection="column"
                height="100vh"
                overflow="auto"  // 이 줄을 추가합니다

            >
                <Flex justifyContent="space-between" alignItems="center" mb={5}>
                    <Heading>{sequenceDetail.name}</Heading>
                    <Flex alignItems="center">
                        <Text mr={2}>View:</Text>
                        <Select value={displayMode} onChange={(e) => setDisplayMode(e.target.value as DisplayMode)}
                                width="auto">
                            <option value="horizontal">Horizontal</option>
                            <option value="grid">Grid</option>
                            <option value="list">List</option>
                        </Select>
                    </Flex>
                </Flex>
                <Text mb={10}>{sequenceDetail.description}</Text>

                {renderItems()}
            </Box>

            <Box
                position="fixed"
                bottom={0}
                left={"64px"}
                right={isSidebarOpen ? "30%" : "60px"}
                bg={bgColor}
                borderTop="1px solid"
                borderColor={borderColor}
                transition="right 0.3s ease, padding 0.3s ease"
                p={4}
            >
                <Flex
                    maxWidth={isSidebarOpen ? "calc(90%)" : "calc(100% - 60px)"}
                    margin="0 auto"
                    width="100%"
                >
                    <Input
                        value={memoInput}
                        onChange={(e) => setMemoInput(e.target.value)}
                        placeholder="Add a memo..."
                        mr={2}
                        flex={1}
                    />
                    <Button
                        onClick={handleAddMemo}
                        isLoading={addMemoMutation.isPending}
                        minWidth="100px"
                    >
                        Add Memo
                    </Button>
                </Flex>
            </Box>


            {/* Sidebar with Tabs */}
            / <Flex
            position="fixed"
            right="0"
            top="0"
            height="100vh"
            width={isSidebarOpen ? "30%" : "60px"}
            transition="all 0.3s ease"  // 이 부분을 수정
            bg={bgColor}
            zIndex="1000"
            color="white"
            flexDirection="column"
        >
            {/* Tabs */}
            <Tabs index={activeTab} onChange={handleTabChange} variant="enclosed" colorScheme="pink" isFitted>
                <TabList>
                    <Tab>Project Info</Tab>
                    <Tab>Settings</Tab>
                </TabList>
                <TabPanels>
                    <TabPanel pl={0} pt={1}>

                        {/* Original Sidebar Content */}
                        <Flex>
                            {/* Icon column */}
                            <VStack
                                spacing={8}
                                p={4}
                                bg={bgColor}
                                borderLeft="1px solid"
                                borderRight="1px solid"
                                borderColor={borderColor}
                                alignItems="center"
                                justifyContent="flex-start"
                                width="60px"
                                height="calc(100vh - 40px)"  // Adjust for tab height
                            >
                                {panelInfo.map(({type, icon: IconComponent}) => (
                                    <IconComponent
                                        key={type}
                                        style={{
                                            cursor: 'pointer',
                                            fontSize: '24px',
                                            color: activePanel === type ? theme.colors.pink[500] : 'white'
                                        }}
                                        onClick={() => togglePanel(type)}
                                        onMouseEnter={(e) => e.currentTarget.style.color = theme.colors.pink[300]}
                                        onMouseLeave={(e) => e.currentTarget.style.color = activePanel === type ? theme.colors.pink[500] : 'white'}
                                    />
                                ))}
                            </VStack>
                            {/* Panel area */}
                            {isSidebarOpen && (
                                <Box width="calc(100% - 60px)" bg={bgColor} p={4} overflowY="auto">
                                    {panelInfo.map(({type, component: Component}) => (
                                        activePanel === type && (
                                            <Component
                                                key={type}
                                                data={sequenceDetail}
                                                onSettingsClick={() => handleTabChange(1)}  // 추가된 부분
                                                sequence_id={SequenceId}  // 추가된 부분

                                            />
                                        )
                                    ))}
                                </Box>
                            )}

                        </Flex>

                    </TabPanel>
                    <TabPanel>
                        {/* New Sidebar Content */}
                        <Box bg={bgColor} p={4} height="calc(100vh - 40px)" overflowY="auto">
                            <RenderPanel/>
                        </Box>
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </Flex>
        </Flex>
    );
}
