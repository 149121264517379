import { useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { createAIRequest, getResponseByRequest, } from "../api/ARequest"
import User from "../interface/IUser";
import {createProject} from "../api/AProject";
import { useToast,} from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import {getTool} from "../api/AITool";
import {getAIInput_images} from "../api/AMedias";

export const useGetStorageImage = (userData: any) => {
    return useQuery({
        queryKey: ['aiInputImages', userData?.username],
        queryFn: getAIInput_images,
        enabled: !!userData,
    });
};
export const useImageGeneration = () => {
  const toast = useToast();

  const queryClient = useQueryClient();
  const [responseImages, setResponseImages] = useState<string[]>([]);

  const createPhotoMutation = useMutation({
    mutationFn: createAIRequest,
    onSuccess: async (data, variables) => {
      const { id } = variables;
      try {
        const responseData = await queryClient.fetchQuery({
          queryKey: ['getResponseByRequest', id],
          queryFn: getResponseByRequest,
        });

        if (responseData && responseData.output_images) {
          setResponseImages(responseData.output_images);
          await createProjectMutation.mutateAsync({
            title: variables.prompt,
            description: "",
            tool_id: variables.tool_id,
            request_id: id,
            response_id: responseData.response_id,
            shared: false,
          });
        }
      } catch (error) {
        console.error('Error fetching response:', error);
        toast({
          title: "Error fetching response",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    },
  });

  const createProjectMutation = useMutation({
    mutationFn: createProject,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['userProjects'] });
      toast({
        title: "Project created successfully!",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    },
    onError: (error) => {
      toast({
        title: "Failed to create project",
        description: error instanceof Error ? error.message : "Unknown error",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    },
  });

  return { createPhotoMutation, responseImages };
};
