
import {Collection} from "../interface/ICollection";
import {instance, getCSRFHeader} from "./Abase";



// 사용자의 모든 컬렉션을 가져오는 API 함수
export const getUserCollections = (userId: any) =>
  instance.get<Collection[]>(`project/collections/?user=${userId}`, { headers: getCSRFHeader() })
    .then((response) => response.data);

// 새로운 컬렉션을 생성하는 API 함수
export const createCollection = (newCollection: { name: string; description: string }) => {
  console.log('Creating collection with data:', newCollection);
  return instance.post<Collection>('project/collections/', newCollection, { headers: getCSRFHeader() })
    .then((response) => response.data)
    .catch((error) => {
      console.error('Error creating collection:', error.response?.data);
      throw error;
    });
};


export const removeCollection = (collectionId: any) =>
    instance.delete(
        `project/collections/${collectionId}`,
        {
            headers: getCSRFHeader()
        }
    ).then((response) => response.data);

export const renameCollection = (collectionId: number | string, newName: string) =>
    instance.patch(
        `project/collections/${collectionId}`,
        { name: newName },
        {
            headers: getCSRFHeader()
        }
    ).then((response) => response.data);

// 프로젝트를 컬렉션에 추가하는 API 함수
export const addProjectToCollection = (collectionId: string, projectId: string) =>
  instance.post<void>(
    `project/collections/${collectionId}/project`,
    { project_id: projectId },
    { headers: getCSRFHeader() }
  ).then((response) => response.data);

// 프로젝트를 컬렉션에서 제거하는 API 함수
export const removeProjectFromCollection = (collectionId: string, projectId: string | number | undefined) =>
  instance.delete<void>(`project/collections/${collectionId}/project`, {
    headers: getCSRFHeader(),
    data: { project_id: projectId }
  }).then((response) => response.data);


// 특정 컬렉션의 상세 정보를 가져오는 API 함수
export const getCollectionDetails = (collectionId: string) =>
  instance.get<Collection>(`project/collections/${collectionId}/`, { headers: getCSRFHeader() })
    .then((response) => response.data);