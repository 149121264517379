import React, {useState, useCallback, useEffect, useMemo} from 'react';
import {
    Box,
    Text,
    Slider,
    SliderTrack,
    SliderFilledTrack,
    SliderThumb,
    Grid,
    GridItem,
    Select,
    Input
} from '@chakra-ui/react';
import {UseFormRegister, UseFormSetValue, UseFormWatch} from 'react-hook-form';
import {IForm} from '../../interface/IForm'; // Assume you have defined IForm type
import {debounce} from 'lodash';

interface ImageSizeSettingsProps {
    register: UseFormRegister<IForm>;
    setValue: UseFormSetValue<IForm>;
    watch: UseFormWatch<IForm>;
}

const ImageSizeSettings: React.FC<ImageSizeSettingsProps> = React.memo(({register, setValue, watch}) => {
    const watchedWidth = watch('_width');
    const watchedHeight = watch('_height');

    const [localWidth, setLocalWidth] = useState(watchedWidth);
    const [localHeight, setLocalHeight] = useState(watchedHeight);
    const [inputWidth, setInputWidth] = useState(watchedWidth.toString());
    const [inputHeight, setInputHeight] = useState(watchedHeight.toString());

    const debouncedSetValue = useMemo(
        () => debounce((name: '_width' | '_height', value: number) => {
            setValue(name, value);
        }, 200),
        [setValue]
    );

    useEffect(() => {
        setLocalWidth(watchedWidth);
        setLocalHeight(watchedHeight);
        setInputWidth(watchedWidth.toString());
        setInputHeight(watchedHeight.toString());
    }, [watchedWidth, watchedHeight]);

    const handleSliderChange = useCallback((value: number, dimension: '_width' | '_height') => {
        if (dimension === '_width') {
            setLocalWidth(value);
            setInputWidth(value.toString());
        } else {
            setLocalHeight(value);
            setInputHeight(value.toString());
        }
        debouncedSetValue(dimension, value);
    }, [debouncedSetValue]);

    const handleInputChange = useCallback((event: React.ChangeEvent<HTMLInputElement>, dimension: '_width' | '_height') => {
        const value = event.target.value;
        if (dimension === '_width') {
            setInputWidth(value);
        } else {
            setInputHeight(value);
        }

        const numValue = parseInt(value, 10);
        if (!isNaN(numValue) && numValue >= 512 && numValue <= 1024) {
            if (dimension === '_width') {
                setLocalWidth(numValue);
            } else {
                setLocalHeight(numValue);
            }
            debouncedSetValue(dimension, numValue);
        }
    }, [debouncedSetValue]);

    const handleInputBlur = useCallback((dimension: '_width' | '_height') => {
        const value = dimension === '_width' ? inputWidth : inputHeight;
        let numValue = parseInt(value, 10);
        if (isNaN(numValue) || numValue < 512) {
            numValue = 512;
        } else if (numValue > 1024) {
            numValue = 1024;
        }
        if (dimension === '_width') {
            setLocalWidth(numValue);
            setInputWidth(numValue.toString());
        } else {
            setLocalHeight(numValue);
            setInputHeight(numValue.toString());
        }
        debouncedSetValue(dimension, numValue);
    }, [inputWidth, inputHeight, debouncedSetValue]);

    return (
        <Box p={4} border="1px" borderColor="gray.600" borderRadius="md" mb={4} width="100%">
            <Text mb={2} fontWeight="bold">Output Size</Text>
            <Grid templateColumns="repeat(2, 1fr)" gap={4} mt={4}>
                <GridItem>
                    <Text mb={2}>Width</Text>
                    <Slider
                        min={512}
                        max={2048}
                        step={1}
                        value={localWidth}
                        onChange={(val) => handleSliderChange(val, '_width')}
                        colorScheme="pink"

                    >
                        <SliderTrack>
                            <SliderFilledTrack/>
                        </SliderTrack>
                        <SliderThumb boxSize={6}/>
                    </Slider>
                    <Input
                        mt={2}
                        value={inputWidth}
                        onChange={(e) => handleInputChange(e, '_width')}
                        onBlur={() => handleInputBlur('_width')}
                        textAlign="center"
                    />
                </GridItem>
                <GridItem>
                    <Text mb={2}>Height</Text>
                    <Slider
                        min={512}
                        max={2048}
                        step={1}
                        value={localHeight}
                        onChange={(val) => handleSliderChange(val, '_height')}
                        colorScheme="pink"

                    >
                        <SliderTrack>
                            <SliderFilledTrack/>
                        </SliderTrack>
                        <SliderThumb boxSize={6}/>
                    </Slider>
                    <Input
                        mt={2}
                        value={inputHeight}
                        onChange={(e) => handleInputChange(e, '_height')}
                        onBlur={() => handleInputBlur('_height')}
                        textAlign="center"
                    />
                </GridItem>
            </Grid>
            <input type="hidden" {...register('_width')} />
            <input type="hidden" {...register('_height')} />
        </Box>
    );
});

export default ImageSizeSettings;