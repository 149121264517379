import {AIInputImage} from "../../interface/IMedias";
import React, {useState} from "react";
import {AspectRatio, Box, Flex, IconButton, Image, Text, useColorModeValue, VStack} from "@chakra-ui/react";
import {useDeleteAIInputImage} from "../../hook/HMedias";
import {CloseIcon} from "@chakra-ui/icons";
import CustomAlertDialog from "../../Utils/CustomAlertDialog";

interface AIInputImageCardProps {
    image: AIInputImage;
}
export const AIInputImageCard: React.FC<AIInputImageCardProps> = ({ image }) => {
    const overlayBg = useColorModeValue('rgba(255, 255, 255, 0.7)', 'rgba(55, 55, 55, 0.3)');
    const textColor = useColorModeValue('gray.800', 'white');
    const [isAlertOpen, setIsAlertOpen] = useState(false);

    const deleteAIInputImageMutation = useDeleteAIInputImage();

    const handleDelete = (e: React.MouseEvent) => {
        e.stopPropagation(); // Prevent the click event from bubbling up
        setIsAlertOpen(true);
    };

    const confirmDelete = () => {
        deleteAIInputImageMutation.mutate(image.id, {
            onSettled: () => setIsAlertOpen(false),
        });
    };

    return (
        <Box
            borderRadius="lg"
            overflow="hidden"
            boxShadow="md"
            position="relative"
        >
            <AspectRatio ratio={1}>
                <Image
                    src={image.file}
                    alt={`Image ${image.id}`}
                    objectFit="cover"
                />
            </AspectRatio>
            <IconButton
                aria-label="Delete image"
                icon={<CloseIcon />}
                size="sm"
                position="absolute"
                top={2}
                right={2}
                onClick={handleDelete}
            />
            {/*<Flex*/}
            {/*    position="absolute"*/}
            {/*    bottom="0"*/}
            {/*    left="0"*/}
            {/*    right="0"*/}
            {/*    height="33.33%"*/}
            {/*    bg={overlayBg}*/}
            {/*    alignItems="left"*/}
            {/*    justifyContent="center"*/}
            {/*    padding="2"*/}
            {/*>*/}
            {/*    <VStack spacing={1} align="left">*/}
            {/*        <Text*/}
            {/*            fontWeight="medium"*/}
            {/*            fontSize="xs"*/}
            {/*            color={textColor}*/}
            {/*        >*/}
            {/*            Type: {image.image_type}*/}
            {/*        </Text>*/}
            {/*        <Text*/}
            {/*            fontWeight="light"*/}
            {/*            fontSize="xs"*/}
            {/*            color={textColor}*/}
            {/*        >*/}
            {/*            Created: {new Date(image.created_at).toLocaleDateString()}*/}
            {/*        </Text>*/}
            {/*    </VStack>*/}
            {/*</Flex>*/}

            <CustomAlertDialog
                isOpen={isAlertOpen}
                onClose={() => setIsAlertOpen(false)}
                onConfirm={confirmDelete}
                title="Delete Image"
            >
                Are you sure you want to delete this image? This action cannot be undone.
            </CustomAlertDialog>
        </Box>
    );
};