import React, {useState} from 'react';
import {
    VStack,
    Icon,
    Text,
    Link,
    useColorModeValue,
    IconButton,
    useColorMode,
    Flex,
    Box,
    Collapse,
    Button,
    Divider,
    useBreakpointValue

} from "@chakra-ui/react";
import {MdEdit} from "react-icons/md";
import {FaHome, FaImage, FaCube, FaUsers, FaBookmark, FaChevronDown, FaChevronUp, FaSignInAlt} from "react-icons/fa";

import {NavLink} from "react-router-dom";
import {AiFillLike} from "react-icons/ai";
import {FaUser} from "react-icons/fa6";
import {PiGraphLight} from "react-icons/pi";
import {IoMdCreate} from "react-icons/io";
import {MdStorage} from "react-icons/md";
import {GrProjects} from "react-icons/gr";
import {useProtectedNavigation} from "./LoginOnlyPage";
import LoginModal from './LoginModal';
import {useNavigate} from 'react-router-dom';
import {useLanguage} from "../hook/HLanguage";
import {useIsMobile} from '../lib/ResponsiveValue'; // 새로 만든 커스텀 훅 import

const MenuItem = ({icon, label, to, isExpanded, requiresLogin = false, onClick}: any) => {
    const iconColor = useColorModeValue("black.500", "white.200");
    const hoverBg = useColorModeValue("rgba(255, 255, 255, 0.45)", "rgba(255, 255, 255, 0.16)");
    const {goTo, isOpen, onClose} = useProtectedNavigation();
    const navigate = useNavigate();
    const isMobile = useIsMobile();


    const handleClick = (e: any) => {
        e.preventDefault();
        if (requiresLogin) {
            goTo(to);
        } else if (onClick) {
            onClick();
        } else {
            navigate(to);
        }
    };
    return (
        <>
            <Link
                onClick={handleClick}
                w={isMobile ? "auto" : "100%"}
                display="flex"
                flexDirection={isMobile ? "column" : "row"}
                alignItems="center"
                justifyContent={isMobile ? "center" : "flex-start"}
                p={isMobile ? 2 : 3}
                _hover={{bg: hoverBg}}
                transition="all 0.3s"
            >
                <Box
                    ml={isMobile ? 0 : 4}
                    width={isMobile ? "auto" : "24px"}
                    display="flex"
                    justifyContent="center"
                    flexShrink={0}
                >
                    <Icon as={icon} boxSize={isMobile ? 5 : 6} color={iconColor} mb={isMobile ? 1 : 0}/>
                </Box>
                {(isExpanded || isMobile) && (
                    <Box
                        ml={isMobile ? 0 : 4}
                        overflow="hidden"
                        whiteSpace="nowrap"
                        transition="max-width 0.3s, opacity 0.3s"
                        maxWidth={isExpanded || isMobile ? "200px" : "0"}
                        opacity={isExpanded || isMobile ? 1 : 0}
                    >
                        <Text fontSize={isMobile ? "xs" : "sm"}>{label}</Text>
                    </Box>
                )}
            </Link>
            {requiresLogin && (
                <LoginModal
                    isOpen={isOpen}
                    onClose={onClose}
                />
            )}
        </>
    );
};

const CollapsibleSection = ({title, children, isExpanded}: any) => {
    const [isSectionOpen, setIsSectionOpen] = useState(true);
    return (
        <Box>
            <Divider my={0}/>
            {isExpanded ? (
                <Box height="10px"/> // Placeholder to maintain consistent spacing
            ) : (
                <Box height="10px"/> // Placeholder to maintain consistent spacing
            )}
            <Collapse in={isSectionOpen}>
                <VStack align="stretch">
                    {children}
                </VStack>
            </Collapse>
        </Box>
    );
};


export default function IntegratedSidebar({isExpanded, isLoggedIn, onLoginOpen}: any) {
    const {t} = useLanguage();
    const isMobile = useIsMobile(); // 커스텀 훅 사용

    const menuItems = [
        {icon: FaHome, label: t('Home'), to: "/"},
        {icon: IoMdCreate, label: "Create Image", to: "/generateai"},
        {icon: GrProjects, label: "Project", to: "/Projects"},
        {icon: FaCube, label: "Models", to: "/models"},
        {icon: FaUsers, label: "Community Hub", to: "/community-feed"},
    ];

    const myStuffItems = [
        {icon: PiGraphLight, label: "Prompt", to: "/Prompt", requiresLogin: true},
        {icon: MdStorage, label: "My Storage", to: "/Storage", requiresLogin: true},
        {icon: AiFillLike, label: "Like", to: "/Liked", requiresLogin: true},
        {icon: FaBookmark, label: "BookMark", to: "/Collections", requiresLogin: true},
    ];

    if (isMobile) {
        return (
            <Flex
                position="fixed"
                bottom={0}
                left={0}
                right={0}
                borderTopWidth={1}
                justifyContent="space-around"
                py={2}
                zIndex="sticky"
            >
                {menuItems.slice(0, 4).map((item, index) => (
                    <MenuItem
                        key={index}
                        icon={item.icon}
                        label={item.label}
                        to={item.to}
                    />
                ))}
                {isLoggedIn ? (
                    <MenuItem
                        icon={FaUser}
                        label={t('Profile')}
                        to="/profile"
                    />
                ) : (
                    <MenuItem
                        icon={FaSignInAlt}
                        label={t('Login')}
                        onClick={onLoginOpen}
                    />
                )}
            </Flex>
        );
    }


    return (
        <VStack spacing={1} align="stretch" mt={4} width={isExpanded ? "100%" : "100%"} transition="width 0.3s">
            {menuItems.map((item, index) => (
                <MenuItem key={index} icon={item.icon} label={item.label} to={item.to} isExpanded={isExpanded}/>
            ))}

            <CollapsibleSection title="My Stuff" isExpanded={isExpanded}>
                {myStuffItems.map((item, index) => (
                    <MenuItem
                        key={index}
                        icon={item.icon}
                        label={item.label}
                        to={item.to}
                        isExpanded={isExpanded}
                        requiresLogin={item.requiresLogin}
                    />
                ))}
            </CollapsibleSection>
        </VStack>
    );
}

