import React from "react";
import {Sequence} from "../../interface/ISequence";
import {useThemeColors} from "../../lib/Color";
import {useNavigate} from "react-router-dom";
import {Box, Heading, HStack, Text, VStack} from "@chakra-ui/react";
import {FaFile, FaFolder, FaImage} from "react-icons/fa";

export const ProjectCard: React.FC<{ project: Sequence }> = ({project}) => {
    const {cardBgColor, textColor, borderColor, accentColor} = useThemeColors();
    const navigate = useNavigate();

    const handleClick = () => {
        navigate(`/projects/${project.id}`);
    };

    return (
        <Box
            // bg={cardBgColor}
            p={5}
            shadow="md"
            borderWidth="1px"
            borderColor={`${borderColor}40`}  // Added 25% opacity to make the border appear thinner

            // borderColor="white"
            borderRadius="md"
            cursor="pointer"
            transition="all 0.2s"
            _hover={{
                bg: `${cardBgColor}80`,  // 80 adds 50% opacity
                borderColor: accentColor,
                transform: 'scale(1.02)'
            }}
            onClick={handleClick}
        >
            <Heading fontSize="xl" mb={2} color={textColor}>{project.name}</Heading>
            <Text color={textColor} mb={4}>{project.description}</Text>
            <VStack align="start" spacing={2}>
                <HStack>
                    <FaFolder color={textColor}/>
                    <Text fontSize="sm" color={textColor}>{project.storage?.memo || 'No storage'}</Text>
                </HStack>
                <HStack>
                    <FaFile color={textColor}/>
                    {/*<Text fontSize="sm" color={textColor}>{project.storage?.prompt?.length || 0} prompts</Text>*/}
                </HStack>
                <HStack>
                    <FaImage color={textColor}/>
                    {/*<Text fontSize="sm" color={textColor}>{project.storage?.img?.length || 0} images</Text>*/}
                </HStack>
            </VStack>
            {/*<Text fontSize="xs" color={textColor} mt={2}>Updated {project.updated_at}</Text>*/}
        </Box>
    );
};
