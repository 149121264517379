import React, {useState, useEffect} from "react";
import {
    VStack,
    HStack,
    Text,
    Input,
    Button,
    Checkbox,
    useColorModeValue,
} from "@chakra-ui/react";
import useUser from "../hook/useUser";
import {useCollections, useCreateCollection, useAddProjectToCollection} from "../hook/HCollection";
import {useThemeColors} from "../lib/Color";

interface CollectionComponentProps {
    projectId: string;
    onClose: () => void;
}

export default function CollectionComponent({projectId, onClose}: CollectionComponentProps) {
    const [isCreating, setIsCreating] = useState(false);
    const [newCollectionName, setNewCollectionName] = useState("");
    const [selectedCollections, setSelectedCollections] = useState<Set<string>>(new Set());
    const {user} = useUser();
    const {data: collections} = useCollections(user?.id);
    const createCollectionMutation = useCreateCollection();
    const addProjectToCollectionMutation = useAddProjectToCollection();
    const {bgColor, textColor, borderColor, cardBgColor, accentColor} = useThemeColors();


    const handleCreateNew = () => {
        setIsCreating(true);
    };

    const handleCreate = async () => {
        if (newCollectionName.trim()) {
            try {
                const newCollection = await createCollectionMutation.mutateAsync({
                    name: newCollectionName,
                    description: ""
                });
                setSelectedCollections(prev => new Set(prev).add(newCollection.id));
                setNewCollectionName("");
                setIsCreating(false);
            } catch (error) {
                console.error("Failed to create collection:", error);
            }
        }
    };

    const handleToggleCollection = (collectionId: string) => {
        setSelectedCollections(prev => {
            const newSet = new Set(prev);
            if (newSet.has(collectionId)) {
                newSet.delete(collectionId);
            } else {
                newSet.add(collectionId);
            }
            return newSet;
        });
    };

    const handleDone = async () => {
        try {
            await Promise.all(
                Array.from(selectedCollections).map(collectionId =>
                    addProjectToCollectionMutation.mutateAsync({collectionId, projectId})
                )
            );
            onClose();
        } catch (error) {
            console.error("Failed to add project to some collections:", error);
        }
    };

    return (
        <VStack align="stretch" spacing={4} bg={bgColor} p={4} borderRadius="md">
            <Text fontSize="xl" fontWeight="bold" color={textColor}>Save to...</Text>
            {collections?.map((collection) => (
                <HStack key={collection.id} justify="space-between" width="100%">
                    <Checkbox
                        isChecked={selectedCollections.has(collection.id)}
                        onChange={() => handleToggleCollection(collection.id)}
                        colorScheme="pink"
                        size="md"
                    >
                        <Text fontSize="sm" fontWeight="medium">
                            {collection.name}
                        </Text>
                    </Checkbox>
                </HStack>
            ))}
            {isCreating ? (
                <VStack align="stretch">
                    <Input
                        placeholder="Enter collection name"
                        value={newCollectionName}
                        onChange={(e) => setNewCollectionName(e.target.value)}
                    />
                    <HStack>
                        <Button onClick={handleCreate} isLoading={createCollectionMutation.isPending}>Create</Button>
                        <Button onClick={() => setIsCreating(false)}>Cancel</Button>
                    </HStack>
                </VStack>
            ) : (
                <Button onClick={handleCreateNew} variant="outline">+ Create new collection</Button>
            )}
            <Button onClick={handleDone} mt={4}>Done</Button>
        </VStack>
    );
}