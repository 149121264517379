import Cookie from "js-cookie";
import {instance, getCSRFHeader} from "./Abase";
import {QueryFunctionContext} from "@tanstack/react-query/build/modern/index";


export const getResponseByRequest = ({queryKey}: QueryFunctionContext) => {

    const [_, requestID] = queryKey;
    console.log(requestID)
    return instance
        .get(`airequests/response-request/${requestID}`)
        .then((response) => response.data);
};


export const GenerateAIImage = ({
                                    prompt,
                                    file,
                                }: IGenerateAIImage) =>
    instance
        .post(
            `airequests/generate-AIImages`,
            {prompt, file},
            {
                headers: {
                    "X-CSRFToken": Cookie.get("csrftoken") || "",
                    "Content-Type": "application/json",
                },
            }
        )
        .then((response) => response.data)
        .catch((error) => {
            console.error("There was an error generating the AI image!", error);
            throw error;
        });


export const createAIRequest = ({
                                    prompt,
                                    fileID,
                                    styleReffile,
                                    id,
                                    negativePrompt,
                                    cfg,
                                    height,
                                    width,
                                    steps,
                                    batch_size,
                                    tool_id,
                                    img2img_bool,
                                    controlnet
                                }: ICreateAIRequestVariables) =>
    instance
        .post(
            `airequests/${id}/generate`,
            {
                prompt, fileID, styleReffile, id,
                negativePrompt,
                cfg,
                height,
                width,
                steps,
                batch_size,
                tool_id,
                img2img_bool,
                controlnet
            },
            {
                headers: {
                    "X-CSRFToken": Cookie.get("csrftoken") || "",
                },
            }
        )
        .then((response) => response.data)
        .catch((error) => {
            console.error('Error generating AI image by ID:', error);
            throw error;
        });


export interface IGenerateAIImage {
    prompt: string;
    file: string;

}

export interface ICreateAIRequestVariables {
    prompt: string;
    fileID: string;
    styleReffile: string;
    id: string;
    negativePrompt: string;
    cfg: number;
    height: number;
    width: number;
    steps: number;
    batch_size: number;
    tool_id: number;
    img2img_bool: boolean;

    controlnet: number[];
}
