import React, {useState, useCallback, useEffect, useMemo} from "react";
import {
    Box,
    Button,
    VStack,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Text,
    Flex,
    Spinner,
    Select,
    Image,
    FormControl,
    Input,
    Slider,
    SliderTrack,
    SliderFilledTrack,
    SliderThumb,
    useTheme, SimpleGrid, FormLabel, IconButton, Switch,
} from "@chakra-ui/react";
import {UseFormWatch, UseFormRegister, UseFormSetValue} from "react-hook-form";
import {FaCamera} from "react-icons/fa";
import {FaImage, FaPaintBrush, FaCog, FaExpandArrowsAlt, FaMagic} from "react-icons/fa";

import ImageSizeSettings from '../elements/GenerateSidebar/ImageSizeSetting';
import StepsSlider from "../elements/GenerateSidebar/StepSlider";
import {AITool} from "../interface/IAITool";
import {IForm} from "../interface/IForm";
import {UseFormReturn} from 'react-hook-form';
import {IconType} from "react-icons";
import {useThemeColors} from "../lib/Color";
import {PromptInput} from "../elements/GenerateSidebar/PromptInput";
import {CfgScaleSlider} from "../elements/GenerateSidebar/CfgScaleSlider";
import {ModelSelector} from "../elements/GenerateSidebar/ModelSelector";
import {ImageReferenceSection} from "../elements/GenerateSidebar/ImageReferenceSection";
import BatchSizeSlider from '../elements/GenerateSidebar/BatchSizeSlider';
import {CheckCircleIcon} from "@chakra-ui/icons";
import {useImageContext} from "../hook/useImageContext";
import {useImageUpload} from "../hook/HMedias";
import {FaAirbnb} from "react-icons/fa";
import {ControlNet} from "../interface/IAIStyle"

interface GenerateSidebarProps {
    aiImages: any[];

    isSidebarOpen: boolean;
    formMethods: UseFormReturn<IForm>;
    onGenerateImage: (data: IForm) => Promise<void>;
    createPhotoMutation: { isPending: boolean };
    // selectedImages: { [key: string]: { id: string; file: string } };
    aiTool: AITool[] | undefined;
    selectedModelId: number;
    onModelChange: (modelId: number) => void;
    AIStyle_Controlnet: ControlNet[]

    onControlNetSelect: any
}

enum PanelType {
    Model = 'model',
    Prompt = 'prompt',
    imgtoimg = 'imgtoimg',
    Reference = 'reference',
    Settings = 'settings',

    ControlNet = 'controlnet' // 새로운 패널 타입 추가

}

interface PanelInfo {
    type: PanelType;
    icon: IconType;
    component: React.FC<any>;
}


const GenerateSidebar: React.FC<GenerateSidebarProps> = ({
                                                             aiImages,
                                                             isSidebarOpen,
                                                             formMethods,
                                                             onGenerateImage,
                                                             createPhotoMutation,
                                                             aiTool,
                                                             selectedModelId,
                                                             onModelChange,
                                                             AIStyle_Controlnet,
                                                             onControlNetSelect,

                                                         }) => {
    const {setValue, getValues, watch, handleSubmit, register} = formMethods;
    const [activePanel, setActivePanel] = useState<PanelType>(PanelType.Model);
    const {selectedImages, selectedMode, setSelectedMode, handleImageClick} = useImageContext();
    const isImg2imgEnabled = useMemo(() => !!selectedImages['imgToImg'], [selectedImages]);


    const iconSize = "28px"; // 원하는 아이콘 크기 설정
    const {bgColor, textColor, accentColor, borderColor, cardBgColor} = useThemeColors();
    const theme = useTheme();
    const selectedControlNetIds = watch('_controlNetIds') || [];


    const {onSaveImage, isUploading} = useImageUpload();
    const [isAccordionOpen, setIsAccordionOpen] = useState(true);

    const onFileChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            onSaveImage(file);
        }
    }, [onSaveImage]);


    const getBorderColor = useCallback((mode: string, imageId: string) => {
        if (selectedImages[mode]?.id === imageId) {
            switch (mode) {
                case 'imgToImg':
                    return 'cyan';
                case 'styleReference':
                    return 'green';
                case 'other':
                    return 'red';
                default:
                    return 'gray.600';
            }
        }
        return 'gray.600';
    }, [selectedImages]);

    const getIconColor = useCallback((mode: string) => {
        switch (mode) {
            case 'imgToImg':
                return 'cyan';
            case 'styleReference':
                return 'green';
            case 'other':
                return 'red';
            default:
                return 'gray.600';
        }
    }, []);


    useEffect(() => {
        if (selectedImages['imgToImg'] && AIStyle_Controlnet && AIStyle_Controlnet.length > 0) {
            onControlNetSelect(AIStyle_Controlnet[1]);
        } else {
            onControlNetSelect(null);
        }
    }, [selectedImages['imgToImg'], AIStyle_Controlnet, onControlNetSelect])





    const handleControlNetClick = useCallback((controlNet: ControlNet) => {
        onControlNetSelect(controlNet);
    }, [onControlNetSelect]);



    const renderControlNetImages = useCallback(() => (
        <SimpleGrid columns={2} spacing={3}>
            {AIStyle_Controlnet && AIStyle_Controlnet.map((item) => (
                <Box
                    key={item.id}  // 여기에 key prop을 추가했습니다.
                    borderWidth="2px"
                    borderRadius="lg"
                    overflow="hidden"
                    cursor="pointer"
                    onClick={() => handleControlNetClick(item)}
                    borderColor={selectedControlNetIds && selectedControlNetIds.includes(item.id) ? "blue.500" : "gray.200"}
                >
                    <Image src={`${item.image?.file}`} alt={item.type}/>
                    <Text fontSize="sm" textAlign="center">{item.type}</Text>
                </Box>
            ))}
        </SimpleGrid>
    ), [AIStyle_Controlnet, selectedControlNetIds, handleControlNetClick]);


    const renderAiImages = useCallback((mode: string) => (
        <Accordion allowToggle defaultIndex={[0]} index={isAccordionOpen ? 0 : -1}
                   onChange={() => setIsAccordionOpen(!isAccordionOpen)}>
            <AccordionItem>
                <h2>
                    <AccordionButton>
                        <Box flex="1" textAlign="left">
                            Storage
                        </Box>
                        <AccordionIcon/>
                    </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                    <FormControl mb={4}>
                        <FormLabel>Upload Image</FormLabel>
                        <Input
                            type="file"
                            accept="image/*"
                            display="none"
                            id={`file-input-${mode}`}
                            onChange={onFileChange}
                        />
                        <Button
                            isLoading={isUploading}
                            type="submit"
                            w="full"
                            onClick={() => document.getElementById(`file-input-${mode}`)?.click()}
                            color={accentColor}
                            sx={{
                                bgColor: "transparent",
                                borderWidth: "1px",
                                borderStyle: "solid",
                                borderColor: accentColor,
                                transition: "all 0.2s",
                                _hover: {
                                    bg: accentColor,
                                    color: "white",
                                },
                                _focus: {
                                    boxShadow: "outline",
                                },
                            }}
                        >
                            Upload Image
                        </Button>
                    </FormControl>
                    <SimpleGrid columns={{base: 2, sm: 2, md: 3, lg: 3}} spacing={3}>
                        {aiImages && aiImages.slice().reverse().map((image: any) => (
                            <Box
                                key={image.id}
                                position="relative"
                                borderWidth="2px"
                                borderRadius="lg"
                                overflow="hidden"
                                onClick={() => {
                                    handleImageClick(image, mode);
                                    setIsAccordionOpen(true);
                                }}
                                cursor="pointer"
                                width="100px"
                                height="100px"
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                bg={selectedImages[mode]?.id === image.id ? "black" : "transparent"}
                                borderColor={getBorderColor(mode, image.id)}
                                transition="all 0.3s ease"
                            >
                                {image.file && (
                                    <Image src={image.file} alt={image.id} objectFit="cover" width="100px"
                                           height="100px"/>
                                )}
                                {selectedImages[mode]?.id === image.id && (
                                    <Box
                                        position="absolute"
                                        top="50%"
                                        left="50%"
                                        transform="translate(-50%, -50%)"
                                        borderRadius="full"
                                        bg="white"
                                        width="24px"
                                        height="24px"
                                        display="flex"
                                        alignItems="center"
                                        justifyContent="center"
                                    >
                                        <CheckCircleIcon color={getIconColor(mode)}/>
                                    </Box>
                                )}
                            </Box>
                        ))}
                    </SimpleGrid>
                </AccordionPanel>
            </AccordionItem>
        </Accordion>
    ), [aiImages, selectedImages, handleImageClick, getBorderColor, getIconColor, isAccordionOpen, onFileChange, isUploading, accentColor]);


    const panelInfo: PanelInfo[] = [
        {
            type: PanelType.Model,
            icon: FaImage,
            component: ModelSelector
        },
        {
            type: PanelType.Prompt,
            icon: FaPaintBrush,
            component: PromptInput
        },
        {
            type: PanelType.imgtoimg,
            icon: FaExpandArrowsAlt,
            component: () => (
                <>
                    <ImageReferenceSection
                        title="Composition reference"
                        imageSrc={selectedImages['imgToImg']?.file}
                        onImageClick={() => {
                            const currentImage = selectedImages['imgToImg'];
                            if (currentImage) {
                                // 이미지가 이미 선택되어 있을 때 선택 해제
                                handleImageClick(null, 'imgToImg');
                                onControlNetSelect(null);
                            } else {
                                // 이미지가 선택되지 않았을 때 선택
                                // 여기서 새 이미지를 선택하는 로직을 구현해야 합니다.
                                // 예를 들어:
                                // const newImage = { id: 'newImageId', file: 'newImageFile' };
                                // handleImageClick(newImage, 'imgToImg');
                                if (AIStyle_Controlnet && AIStyle_Controlnet.length > 1) {
                                    onControlNetSelect(AIStyle_Controlnet[1]);
                                }
                            }
                        }}
                        isImg2imgEnabled={isImg2imgEnabled}
                        onFileChange={onFileChange}
                        isUploading={isUploading}
                        mode="imgToImg"
                    />
                    {renderAiImages('imgToImg')}
                </>
            )
        },
        // {
        //     type: PanelType.Reference,
        //     icon: FaExpandArrowsAlt,
        //     component: () => (
        //         <>
        //
        //             <ImageReferenceSection
        //                 title="Style reference"
        //                 imageSrc={selectedImages['styleReference']?.file}
        //
        //             />
        //             {renderAiImages('styleReference')}
        //
        //         </>
        //     )
        // },
        {
            type: PanelType.Settings,
            icon: FaCog,
            component: () => (
                <>
                    <CfgScaleSlider
                        register={register}
                        watchedCfgScale={watch('_cfgScale')}
                        setValue={setValue}
                    />
                    <ImageSizeSettings
                        register={register}
                        setValue={setValue}
                        watch={watch}
                    />
                    <StepsSlider
                        register={register}
                        setValue={setValue}
                        watch={watch}
                    />
                    <BatchSizeSlider
                        register={register}
                        setValue={setValue}
                        watch={watch}
                    />
                </>
            )
        },
        {
            type: PanelType.ControlNet,
            icon: FaMagic,
            component: () => (
                <>
                    <Text fontSize="xl" mb={4}>ControlNet Options</Text>
                    {renderControlNetImages()}
                    {selectedControlNetIds.length > 0 && (
                        <Box mt={4} p={3} bg="gray.700" borderRadius="md">
                            <Text fontWeight="bold">Selected ControlNet:</Text>
                            {AIStyle_Controlnet.filter(cn => selectedControlNetIds.includes(cn.id)).map(cn => (
                                <Text key={cn.id}>{cn.type}</Text>
                            ))}
                        </Box>
                    )}
                </>
            )
        }
    ];
    const iconStyle = {
        fontSize: `${iconSize}px`,
        color: 'white',
        cursor: 'pointer',
        transition: 'color 0.2s'
    };
    const activeIconStyle = {
        ...iconStyle,
        color: 'blue.500'
    };
    const togglePanel = (panel: PanelType) => {
        setActivePanel(prev => prev === panel ? prev : panel);
    };

    return (
        <Flex
            position="fixed"
            right="0"
            top="0"
            height="100vh"
            width={isSidebarOpen ? "30%" : "60px"}
            transition="width 0.3s ease"
            bg="gray.900"
            zIndex="1000"
            color="white"
        >
            {/* Icon column */}
            <VStack
                spacing={8}
                p={4}
                bg={bgColor}
                borderLeft="1px solid"
                borderRight="1px solid"
                borderColor={borderColor}
                alignItems="center"
                justifyContent="flex-start"
                width="60px"
                height="100%"
            >
                {panelInfo.map(({type, icon: IconComponent}) => (
                    <IconComponent
                        key={type}
                        style={activePanel === type ? activeIconStyle : iconStyle}
                        onClick={() => togglePanel(type)}
                        onMouseEnter={(e) => e.currentTarget.style.color = theme.colors.pink[300]}
                        onMouseLeave={(e) => e.currentTarget.style.color = activePanel === type ? theme.colors.pink[500] : 'white'}
                    />
                ))}
            </VStack>
            {/* Panel area */}
            {isSidebarOpen && (
                <Box width="100%" bg={bgColor} p={4} overflowY="auto">
                    {panelInfo.map(({type, component: Component}) => (
                        activePanel === type && (
                            <Component
                                key={type}
                                selectedModelId={selectedModelId}
                                onModelChange={onModelChange}
                                modelData={aiTool}
                                register={register}
                                watchedPrompt={watch("_prompt")}
                                setValue={setValue}
                                selectedImages={selectedImages}

                            />
                        )
                    ))}
                    <Button
                        mt={4}
                        p={4}
                        isLoading={createPhotoMutation.isPending}
                        type="submit"
                        w="full"
                        onClick={handleSubmit(onGenerateImage)}
                        color={accentColor}

                        sx={{
                            bgColor: "transparent",
                            borderWidth: "1px",
                            borderStyle: "solid",
                            borderColor: accentColor,
                            transition: "all 0.2s",
                            _hover: {
                                bg: accentColor,
                                color: "white",
                            },
                            _focus: {
                                boxShadow: "outline",
                            },
                        }}
                    >
                        Create
                    </Button>
                </Box>
            )}
        </Flex>
    );
}

export default React.memo(GenerateSidebar);