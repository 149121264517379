import React, {useCallback, useMemo} from "react";
import {
    Tabs, TabList, TabPanels, Tab, TabPanel, Box, RadioGroup, Stack, Radio, Text,
    SimpleGrid, Image, VStack, FormControl, FormLabel, Input, IconButton, Button, Spinner, Heading
} from "@chakra-ui/react";
import {FaAirbnb} from "react-icons/fa";
import {CheckCircleIcon} from '@chakra-ui/icons';
import {useForm, SubmitHandler} from "react-hook-form";
import {useImageContext} from "../hook/useImageContext";
import {useImageUpload} from "../hook/HMedias";
import {titleSize} from "../lib/ResponsiveValue";
import {useThemeColors} from "../lib/Color";

interface ImageTabsProps {
    responseImages: string[];
    userProjects: any[];
    projectsLoading: boolean;
    projectsError: any;
    onProjectClick: (project: any) => void;
    batchSize: number;
    createPhotoMutation: { isPending: boolean };

}

interface FormValues {
    file: FileList;
}

const ImageTabs: React.FC<ImageTabsProps> = React.memo(({
                                                            responseImages,
                                                            userProjects,
                                                            projectsLoading,
                                                            projectsError,
                                                            onProjectClick,
                                                            batchSize,
                                                            createPhotoMutation,


                                                        }) => {

    const {bgColor, textColor, accentColor} = useThemeColors();

    // const renderProjects = useMemo(() => (
    //     <SimpleGrid columns={{base: 2, md: 3, lg: 4}} spacing={5}>
    //         {userProjects?.map((project: any) => (
    //             <Box
    //                 key={project.id}
    //                 borderWidth="1px"
    //                 borderRadius="lg"
    //                 overflow="hidden"
    //                 cursor="pointer"
    //                 onClick={() => onProjectClick(project)}
    //                 _hover={{boxShadow: "md"}}
    //             >
    //                 <Image src={project.response.output_images[0]} alt={project.title}/>
    //                 {/*<Box p="2">*/}
    //                 {/*    <Text fontWeight="bold">{project.title}</Text>*/}
    //                 {/*    <Text fontSize="sm">{project.description}</Text>*/}
    //                 {/*</Box>*/}
    //             </Box>
    //         ))}
    //     </SimpleGrid>
    // ), [userProjects, onProjectClick]);

    // const renderLoadingBoxes = useCallback(() => (
    //     <SimpleGrid columns={2} spacing={4}>
    //         {[...Array(batchSize)].map((_, index) => (
    //             <Box
    //                 key={index}
    //                 width="200px"
    //                 height="200px"
    //                 border="1px"
    //                 borderColor="gray"
    //                 borderRadius="md"
    //                 display="flex"
    //                 alignItems="center"
    //                 justifyContent="center"
    //             >
    //                 <Spinner size="xl"/>
    //             </Box>
    //         ))}
    //     </SimpleGrid>
    // ), [batchSize]);


    const gridConfig = useMemo(() => ({
        columns: {base: 2, md: 3, lg: 4},
        spacing: 5,
        minChildWidth: "200px",
    }), []);

    const renderProjectItem = useCallback((project: any) => (
        <Box
            key={project.id}
            borderWidth="1px"
            borderRadius="lg"
            overflow="hidden"
            cursor="pointer"
            onClick={() => onProjectClick(project)}
            _hover={{boxShadow: "md"}}
        >
            <Image src={project.response.output_images[0]} alt={project.title} objectFit="cover" w="100%" h="200px"/>
        </Box>
    ), [onProjectClick]);


   const renderLoadingBox = useCallback(() => (
        <Box
            borderWidth="1px"
            borderColor="gray"
            borderRadius="lg"
            display="flex"
            alignItems="center"
            justifyContent="center"
            h="200px"
        >
            <Spinner size="xl" color={accentColor}/>
        </Box>
    ), [accentColor]);




    const renderContent = useMemo(() => {
        if (projectsLoading) {
            return <Spinner/>;
        }
        if (projectsError) {
            return <Text color="red.500">Error loading projects: {projectsError.message}</Text>;
        }

        const loadingBoxes = createPhotoMutation.isPending
            ? [...Array(batchSize)].map((_, index) => <React.Fragment
                key={`loading-${index}`}>{renderLoadingBox()}</React.Fragment>)
            : [];

        const projectItems = userProjects ? userProjects.map(renderProjectItem) : [];

        return (
            <SimpleGrid {...gridConfig}>
                {loadingBoxes}
                {projectItems}
            </SimpleGrid>
        );
    }, [projectsLoading, projectsError, createPhotoMutation.isPending, batchSize, userProjects, renderLoadingBox, renderProjectItem, gridConfig]);

    return (
        <Tabs variant="enclosed" mt={6} colorScheme="pink" isFitted>
            <TabList>
                <Tab>Generate Image</Tab>
                <Tab>RealTime</Tab>
                <Tab>A/B Image</Tab>
            </TabList>
            <TabPanels>
                <TabPanel>
                    <VStack mt={2} align="stretch" width="100%">
                        {renderContent}
                    </VStack>
                </TabPanel>
                <TabPanel>
                    {/*<Box mb={4}>*/}
                    {/*    <RadioGroup onChange={setSelectedMode} value={selectedMode}>*/}
                    {/*        <Stack direction="row" spacing={5}>*/}
                    {/*            <Radio value="imgToImg">Img to Img</Radio>*/}
                    {/*            <Radio value="styleReference">Style Reference</Radio>*/}
                    {/*            <Radio value="other">Other</Radio>*/}
                    {/*        </Stack>*/}
                    {/*    </RadioGroup>*/}
                    {/*</Box>*/}
                    {/*<VStack as="form" spacing={5}  mt={10} id="file-form">*/}
                    {/*    <FormControl>*/}
                    {/*        <FormLabel>Upload Image</FormLabel>*/}
                    {/*        <Input*/}
                    {/*            type="file"*/}
                    {/*            accept="image/*"*/}
                    {/*            display="none"*/}
                    {/*            id="file-input"*/}
                    {/*            onChange={onFileChange}*/}
                    {/*        />*/}
                    {/*        <IconButton*/}
                    {/*            aria-label="Upload Image"*/}
                    {/*            icon={<FaAirbnb/>}*/}
                    {/*            onClick={() => document.getElementById('file-input')?.click()}*/}
                    {/*            size="lg"*/}
                    {/*            colorScheme="blue"*/}
                    {/*            borderRadius="full"*/}
                    {/*            isLoading={isUploading}*/}
                    {/*        />*/}
                    {/*    </FormControl>*/}
                    {/*</VStack>*/}
                    {/*{renderAiImages}*/}
                </TabPanel>
                <TabPanel>

                </TabPanel>
            </TabPanels>
        </Tabs>
    );
});

export default ImageTabs;