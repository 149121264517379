import React, {useEffect} from "react";
import {
    Box,
    Heading,
    Text,
    useToast,
    VStack,
    useColorModeValue,
    Flex,
    Circle,
    Container
} from "@chakra-ui/react";
import {motion} from "framer-motion";
import {useQueryClient} from "@tanstack/react-query";
import {useLocation, useNavigate} from "react-router-dom";
import {googleLogin} from "../api/AUser";
import {useThemeColors} from '../lib/Color';

const MotionBox = motion(Box);

export default function GoogleConfirm() {
    const {search} = useLocation();
    const toast = useToast();
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const {bgColor, textColor, accentColor} = useThemeColors();


    const confirmLogin = async () => {
        const params = new URLSearchParams(search);
        const code = params.get("code");
        if (code) {
            try {
                const status = await googleLogin(code);
                if (status === 200) {
                    toast({
                        status: "success",
                        title: "Login Successful",
                        description: "Welcome to Motive AI",
                        position: "bottom-right",
                        duration: 5000,
                        isClosable: true,
                    });
                    queryClient.refetchQueries({queryKey: ["me"]});
                    navigate("/");
                }
            } catch (error) {
                toast({
                    status: "error",
                    title: "Login Failed",
                    description: "Please try again",
                    position: "bottom-right",
                    isClosable: true,
                });
                navigate("/login");
            }
        }
    };

    useEffect(() => {
        confirmLogin();
    }, []);

    return (
        <Flex
            minHeight="100vh"
            width="full"
            align="center"
            justifyContent="center"
            bg={bgColor}
        >
            <Container maxW="lg" py={12} px={6}>
                <VStack spacing={8} align="center">
                    <MotionBox
                        initial={{opacity: 0, y: -20}}
                        animate={{opacity: 1, y: 0}}
                        transition={{duration: 0.5}}
                    >
                        <Heading color={textColor} fontSize="3xl" fontWeight="bold" textAlign="center">
                            Signing you in
                        </Heading>
                    </MotionBox>

                    <Text color={textColor} fontSize="lg" textAlign="center" fontWeight="medium">
                        We're securely logging you into Motive AI
                    </Text>

                    <Flex justify="center" align="center" height="100px">
                        <MotionBox
                            animate={{
                                scale: [1, 1.1, 1],
                                opacity: [1, 0.5, 1],
                            }}
                            transition={{
                                duration: 1.5,
                                repeat: Infinity,
                                repeatType: "reverse"
                            }}
                        >
                            {/*<Circle size="16" bg={accentColor}/>*/}
                            <Box
                                width="64px"
                                height="64px"
                                borderRadius="50%"
                                border="3px solid"
                                borderColor={accentColor}
                            />
                        </MotionBox>
                    </Flex>

                    <Text color={textColor} fontSize="sm" textAlign="center" opacity={0.8}>
                        This may take a few moments. Please don't close this window.
                    </Text>
                </VStack>
            </Container>
        </Flex>
    );
}