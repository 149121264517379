import React, {useCallback, useRef, useState} from "react";
import {useThemeColors} from "../../lib/Color";
import {Badge, Box, Flex, HStack, IconButton, Image, Text, useToast, VStack} from "@chakra-ui/react";
import {useRemoveProjectFromSequence} from "../../hook/HSequence";
import {CloseIcon} from "@chakra-ui/icons";
import {Projects} from "../../interface/Iproject";

interface SequenceProjectProps {
    project: Projects;
    sequenceId: string | undefined;
    onRemove: () => void;
    displayMode: 'horizontal' | 'grid' | 'list';

}

export const SequenceProject: React.FC<SequenceProjectProps> = ({project, sequenceId, onRemove, displayMode}) => {
    const {cardBgColor, textColor, accentColor} = useThemeColors();
    const toast = useToast();
    const removeProjectMutation = useRemoveProjectFromSequence();
    const [width, setWidth] = useState(300);
    const [isResizing, setIsResizing] = useState(false);
    const resizeRef = useRef<HTMLDivElement>(null);

    const handleRemove = async () => {
        if (!sequenceId) {
            toast({
                title: "Error",
                description: "Sequence ID is missing",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
            return;
        }

        try {
            await removeProjectMutation.mutateAsync({sequence_id: sequenceId, project_id: project.id});
            onRemove();
            toast({
                title: "Project removed",
                status: "success",
                duration: 3000,
                isClosable: true,
            });
        } catch (error) {
            toast({
                title: "Failed to remove project",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        }
    };

    const startResize = useCallback((e: React.MouseEvent) => {
        setIsResizing(true);
        e.preventDefault();
    }, []);

    const stopResize = useCallback(() => {
        setIsResizing(false);
    }, []);

    const resize = useCallback((e: MouseEvent) => {
        if (isResizing && resizeRef.current) {
            const newWidth = e.clientX - resizeRef.current.getBoundingClientRect().left;
            setWidth(Math.max(200, Math.min(800, newWidth)));
        }
    }, [isResizing]);

    React.useEffect(() => {
        document.addEventListener('mousemove', resize);
        document.addEventListener('mouseup', stopResize);
        return () => {
            document.removeEventListener('mousemove', resize);
            document.removeEventListener('mouseup', stopResize);
        };
    }, [resize, stopResize]);

    const formatDate = (dateString: string) => {
        const date = new Date(dateString);
        return date.toISOString().split('T')[0]; // YYYY-MM-DD 형식으로 반환
    };
    const renderHorizontal = () => (
        <Box
            bg={cardBgColor}
            borderRadius="lg"
            overflow="hidden"
            boxShadow="md"
            position="relative"
            width="100%"
            height="300px"
            mb={4}
        >
            <Flex>
                <Box width="60%" position="relative">
                    <Image
                        src={project.response?.output_images?.[0] || "https://via.placeholder.com/400x225"}
                        alt={project.title}
                        objectFit="cover"
                        width="100%"
                        height="300px"
                    />
                </Box>
                <VStack
                    width="40%"
                    p={4}
                    alignItems="flex-start"
                    spacing={3}
                    height="300px"
                    overflowY="auto"
                >
                    <IconButton
                        aria-label="Remove project"
                        icon={<CloseIcon/>}
                        size="sm"
                        position="absolute"
                        top={2}
                        right={2}
                        onClick={handleRemove}
                    />
                    <Box>
                        <Text fontWeight="bold" color={textColor}>Prompt:</Text>
                        <Text color={textColor} fontSize="sm" noOfLines={3}>{project.request.prompt}</Text>
                    </Box>
                    <HStack>
                        <Badge colorScheme="blue">
                            {project.request.width}x{project.request.height}
                        </Badge>
                        <Badge colorScheme="green">
                            Steps: {project.request.steps}
                        </Badge>
                        <Badge colorScheme="grey">
                            Created: {formatDate(project.request.created_at)}
                        </Badge>
                    </HStack>
                </VStack>
            </Flex>
        </Box>
    );

    const renderGrid = () => (
        <Box
            bg={cardBgColor}
            borderRadius="lg"
            overflow="hidden"
            boxShadow="md"
            position="relative"
            width="100%"
            height="300px"
            mb={4}
        >
            <Flex flexDirection="column" height="100%">
                <Box width="100%" height="50%" position="relative">
                    <Image
                        src={project.response?.output_images?.[0] || "https://via.placeholder.com/400x225"}
                        alt={project.title}
                        objectFit="cover"
                        width="100%"
                        height="100%"
                    />
                </Box>
                <VStack
                    width="100%"
                    p={4}
                    alignItems="flex-start"
                    spacing={3}
                    height="50%"
                    overflowY="auto"
                >
                    <IconButton
                        aria-label="Remove project"
                        icon={<CloseIcon/>}
                        size="sm"
                        position="absolute"
                        top={2}
                        right={2}
                        onClick={handleRemove}
                    />
                    <Box>
                        <Text fontWeight="bold" color={textColor}>Prompt:</Text>
                        <Text color={textColor} fontSize="sm" noOfLines={3}>{project.request.prompt}</Text>
                    </Box>
                    <HStack>
                        <Badge colorScheme="blue">
                            {project.request.width}x{project.request.height}
                        </Badge>
                        <Badge colorScheme="green">
                            Steps: {project.request.steps}
                        </Badge>
                    </HStack>
                </VStack>
            </Flex>
        </Box>
    );

    const renderList = () => (
        <Flex alignItems="center" bg={cardBgColor} p={4} borderRadius="lg" mb={2}>
            <Image
                src={project.response?.output_images?.[0] || "https://via.placeholder.com/50"}
                alt={project.title}
                boxSize="50px"
                objectFit="cover"
                mr={4}
            />
            <Box flex={1}>
                <Text fontWeight="bold" color={textColor}>{project.title}</Text>
                <Text color={textColor} fontSize="sm" noOfLines={1}>{project.request.prompt}</Text>
            </Box>
            <HStack>
                <Badge colorScheme="blue">
                    {project.request.width}x{project.request.height}
                </Badge>
                <Badge colorScheme="green">
                    Steps: {project.request.steps}
                </Badge>
                <IconButton
                    aria-label="Remove project"
                    icon={<CloseIcon/>}
                    size="sm"
                    onClick={handleRemove}
                />
            </HStack>
        </Flex>
    );

    switch (displayMode) {
        case 'horizontal':
            return renderHorizontal();
        case 'grid':
            return renderGrid();
        case 'list':
            return renderList();
        default:
            return null;
    }
};
